/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import {
  configure,
  action,
  computed,
  makeAutoObservable,
  makeObservable,
  observable,
} from 'mobx'
import { matchPath } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Message } from 'react-chat-ui'
import Cookies from 'js-cookie'

import { useTranslation } from 'react-i18next'
import { GloBalErrorService } from '../types-project/models/services/Errors'
import { ErrorRequest, Request } from '../types-project/models/services/Request'
import {
  Applicant,
  ApplicantBa,
  BaCustom,
  Brief as BriefI,
  CustomFav,
  DetailBa,
  IUpdateBasquetReq,
  Media,
  MediaBA,
  StatusMedia,
  MediaCastings,
  MyCasting,
  TypeBrief,
  ValidateExclusiveBriefReq,
  CBrief,
  BriefInstructions,
  MediaBrief,
  ErrorMaster,
  BriefParams,
  ErrorSendTips,
  Publication,
  ErrorPublication,
  ErrorUpdateBasket,
  Country,
  Language,
} from '../types-project/Brief'
import type * as message from '../types-project/models/Message/message'
import { GenericAxiosRequestVOGZtype } from '../types-project/Request'
import { startTimer } from '../utils/helpers'
import modal from './modal'
import api, { Endpoint, EndT } from '../utils/apiUtils'
import { ToastMsg } from '../utils/components/Ui/toast'
import i18n from '../utils/i18n'
import {
  CustomBriefI,
  DeepPartial,
} from '../pages/Castings/NewCasting/subscreens/EditCasting/components/Forms/FormModel/castingFormModel'
import { generateBrief, generateBriefs } from '../utils/mockapi'
import { BrandI, ClientI, ProductsBrand, TypeClient, Vogzter } from '../types-project/Client'
import userMobx from './user'
import { DateHandler } from '../pages/Castings/ExtendCasting/components/DateHandler'

let instance: Brief
const endpoints = new Endpoint()

async function getMediaWinners(
  setBas: boolean,
  data?: MyCasting[]
): Promise<MediaBA[] | string> {
  const medias: MediaBA[] = []
  try {
    for (let i = 0; i < data!?.length; i++) {
      try {
        const req = await api.get<MediaBA[] | ErrorRequest>(
          endpoints.getEndpoint(EndT.allMedias, data![i]?._id)
        )
        if (req.status === 200) {
          if (setBas) data![i].media = req.data as MediaBA[]
          for (
            let l = 0;
            l <
            (req.data as MediaBA[]).filter((v) => v.status === 'chosen')
              ?.length;
            l++
          ) {
            if ((req.data as MediaBA[])?.length > 0) {
              medias.push({
                ...(req.data as MediaBA[])[l],
                applicant: ((data![i] as MyCasting).applicant as Applicant),
              })
            }
          }
        }
      } catch (error) {
        // console.log({ error })
      }
    }
    return medias
  } catch (e) {
    const error = new GloBalErrorService(e)
    const errorMessage = error.message
    return errorMessage
  }
}

configure({
  enforceActions: 'never',
  disableErrorBoundaries: true,
})
export class Brief {
  constructor() {
    if (instance) {
      makeAutoObservable(this)
      return instance
    }
    instance = this
    makeAutoObservable(this)
  }

  @observable briefCreationReq: Request<BriefI> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable aiBriefReq: Request<string> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable briefTypesReq: Request<
    Array<{ _id: string; name: TypeBrief }>
  > = {
      loading: false,
      data: null,
      error: null,
    }

  @observable brief: Request<BriefI> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable briefs: Request<BriefI[]> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable allBriefsBrand: Request<Array<CBrief>> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable briefApplication: Request<MyCasting[]> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable boughtMedia: Request<MediaBA[]> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable baMessagesCount: Request<message.Message[]> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable detailBA: Request<DetailBa> = {
    loading: false,
    data: {} as DetailBa,
    error: null,
  }

  @observable detailFav: Request<CustomFav> = {
    loading: false,
    data: {} as CustomFav,
    error: null,
  }

  @observable medias: MediaBA[] = []

  @observable master: Request<Media> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable messages: Request<message.Message[]> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable reqConfirmStep2: Request<any> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable validateSelection: Request<BaCustom> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable actionBa: Request<BaCustom> = {
    loading: false,
    data: null,
    error: null,
  }

  // exclu ba
  @observable validateBaExclu: Request<MyCasting[]> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable message: Request<Media> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable tipsSent: Request<any> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable countries: Request<Country[]> = {
    loading: false,
    data: [] as Country[],
    error: null,
  }

  @observable languages: Request<Language[]> = {
    loading: false,
    data: [],
    error: null,
  }

  // @observable updateBasket: request<MyCasting["media"]> = { loading: false, data: null, error: null };
  @observable vogzters = [] as Array<any>

  @action.bound
  async getTypesBrief() {
    try {
      this.briefTypesReq = {
        ...this.briefTypesReq,
        loading: true,
        error: null,
        data: null,
      }
      const { data, status } = await api.get<
        Array<{ _id: string; name: TypeBrief }> | string
      >(endpoints.getEndpoint(EndT.typesBrief))
      if (status === 200)
        this.briefTypesReq = {
          ...this.briefTypesReq,
          loading: false,
          error: null,
          data: data as Array<{ _id: string; name: TypeBrief }>,
        }
      else throw new Error(data as string)
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.briefs = {
        ...this.briefs,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @computed get getBriefMob() {
    return this.brief
  }

  @action.bound
  setBriefDeadline(deadline: string) {
    this.brief = {
      ...this.brief,
      // @ts-ignore
      data: { ...this.brief.data!, deadline_: deadline },
    }
  }

  @action.bound
  getBriefs() {
    // generateBrief()
    // generateBriefs()
    try {
      this.briefs = { ...this.briefs, loading: true, error: null, data: null }
      api
        .get<BriefI[] | string>(endpoints.getEndpoint(EndT.allbriefs))
        .then(({ data, status }) => {
          if (status === 200)
            this.briefs = {
              ...this.briefs,
              loading: false,
              error: null,
              data: (data as BriefI[]).filter(
                (v) => v.status !== 'to-validate'
              ),
            }
          else throw new Error(data as string)
        })
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      // if (!this.me.error)
      //   toast.error(i18n.t(`common:errors.${errorMessage}`), {})
      this.briefs = {
        ...this.briefs,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  getAllBoughtMedia(callBack?: (data: MediaBA[]) => void) {
    try {
      this.boughtMedia = {
        ...this.boughtMedia,
        loading: true,
        error: null,
      }
      api
        .get<MediaBA[] | string>(endpoints.getEndpoint(EndT.allBoughtMedia))
        .then(({ data, status }) => {
          if (status === 200) {
            if (callBack) callBack(data as MediaBA[])!
            this.boughtMedia = {
              ...this.boughtMedia,
              loading: false,
              error: null,
              data: data as MediaBA[],
            }
          } else throw new Error(data as string)
        })
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      // if (!this.me.error)
      //   toast.error(i18n.t(`common:errors.${errorMessage}`), {})
      this.boughtMedia = {
        ...this.boughtMedia,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @computed sortedData(
    type: 'date' | '' | 'draft' | 'published'
  ): Array<BriefI> {
    if (type === 'date') {
      return (
        this.briefs.data
          ?.slice()
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          ) ?? []
      )
    } else if (type === 'draft' || type === 'published') {
      return this.briefs
        .data!.slice()
        .filter((a) => a.status === (type === 'draft' ? 'created' : 'active'))
    } else {
      return this.briefs.data ?? []
    }
  }

  @action.bound
  async getAllBriefsFromBrand(idBrand?: string) {
    try {
      this.allBriefsBrand = {
        ...this.allBriefsBrand,
        loading: true,
        error: null,
        data: null,
      }

      const userInfos = userMobx.getUserInfos() as ClientI

      const accountBrand = userInfos._company.type === TypeClient.BRAND

      const brandId = accountBrand ? userInfos?.brand : (idBrand ?? userMobx.getBrandSession()?._id)

      if (!brandId && userInfos?._company?.type === TypeClient.AGENCY) {
        window.location.href = '/dashboard/brands'
      }
      const { data, status } = await api.get<BriefI[] | ErrorRequest>(
        endpoints.getEndpoint(
          accountBrand ? EndT.allbriefs : EndT.allBriefsBrand,
          brandId as unknown as string
        )
      )
      if (status === 200) {
        // @ts-ignore
        this.allBriefsBrand = {
          ...this.allBriefsBrand,
          loading: false,
          error: null,
          data: data as BriefI[],
        }
      } else {
        this.allBriefsBrand = {
          ...this.allBriefsBrand,
          loading: false,
          error: null,
          data: [],
        }
        throw new Error((data as ErrorRequest).message)
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.allBriefsBrand = {
        ...this.allBriefsBrand,
        loading: false,
        error: errorMessage,
        data: [],
      }
    }
  }

  @action.bound
  async getBrief(idBrief: string, callback?: (val: BriefI) => void) {
    // generateBrief()
    // generateBriefs()
    try {
      this.brief = { ...this.brief, loading: true, error: null, data: null }
      const { data, status } = await api.get<BriefI>(
        endpoints.getBrief(idBrief)
      )
      if (status === 200) {
        if (callback) callback(data)
        this.brief = {
          ...this.brief,
          loading: false,
          error: null,
          data: data,
        }
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.brief = {
        ...this.brief,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async duplicateBrief(
    idBrief: string,
    callBack: (id: string) => void,
    reset: boolean
  ) {
    const { data, status } = await api.post<BriefI>(
      endpoints.getEndpoint(EndT.duplicateBrief, idBrief),
      { reset }
    )
    if (status === 200) {
      this.allBriefsBrand.data?.push(data)
      callBack(data._id)
    }
  }

  @action.bound
  async deleteBrief(idBriefs: Array<string>) {
    const { data, status } = await api.delete<Array<BriefI>>(
      endpoints.getEndpoint(EndT.createBrief),
      { data: { idBriefs } }
    )
    if (status === 200) {
      this.allBriefsBrand.data = this.allBriefsBrand.data?.filter(
        (brief) => idBriefs.indexOf(brief._id) === -1
      )!
    } else {
      // throw new Error('An error occured.')
      throw new Error('deleteBrief : erreur')
    }
  }

  @action.bound
  async closeBrief(idBrief: string, callBack?: () => void) {
    try {
      this.allBriefsBrand = {
        ...this.allBriefsBrand,
        loading: true,
        error: null,
      }
      const { data, status } = await api.post<BriefI | ErrorRequest>(
        endpoints.getEndpoint(EndT.closeBrief, idBrief)
      )
      if (status === 200) {
        toast.success(i18n.t('mycastings:confirm-close-casting'), {
          theme: 'colored',
        })
        if (callBack) callBack()
        const newAllBriefs = this.allBriefsBrand.data?.map((brief) => {
          if (brief._id === (data as BriefI)._id) {
            brief = data as BriefI
            return brief
          }
          return brief
        })
        this.allBriefsBrand = {
          data: newAllBriefs as BriefI[],
          loading: false,
          error: null,
        }
      } else {
        throw new Error('CloseBrief : erreur')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.allBriefsBrand = {
        ...this.allBriefsBrand,
        loading: false,
        error: errorMessage,
        // data: null,
      }
    }
  }

  @action.bound
  async cancelBrief(idBa: string) {
    try {
      const { data, status } = await api.patch<BriefI | ErrorRequest>(
        endpoints.getEndpoint(EndT.cancelBrief, idBa)
      )
      if (status === 200) {
        toast.success(i18n.t('mycastings:confirm-close-casting'), {
          theme: 'colored',
        })
        window.location.reload()
      } else {
        throw new Error('CloseBrief : erreur')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: i18n.t(`common:error.${errorMessage}`),
        },
      })
      this.allBriefsBrand = {
        ...this.allBriefsBrand,
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async createBrief(type_casting: 'creator' | 'exclusive' | 'private', callBack?: (data: BriefI) => void) {
    try {
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: true,
        error: null,
        data: null,
      }
      try {
        /**
         * @param paramsBrief is propriety brief of content_usage and property_rights
        */
        const paramsBrief: BriefParams = JSON.parse(sessionStorage.getItem('paramsBrief') ?? "{}")
        const brand: BrandI = userMobx.getBrandSession() ?? {}
        const userInfos = userMobx.getUserInfos() as ClientI

        const { data, status } = await api.post<BriefI>(
          endpoints.getEndpoint(EndT.createBrief),
          {
            type: type_casting,
            brand: userInfos._company.type === TypeClient.AGENCY ? brand?._id : userMobx.me.data?.brand?._id ?? userInfos?.brand?._id,
          }
        )

        if (status === 200) {
          if (Object.keys(paramsBrief)) {
            await this.patchBrief<BriefParams>({ ...paramsBrief }, data._id)
            /**
             * @param paramsBrief is propriety brief of content_usage and property_rights
             */
            sessionStorage.removeItem('paramsBrief')
          }
          if (callBack) callBack(data as BriefI)
        } else {
          throw new Error('An error occured.')
        }
      } catch (error) {
        throw new Error((error as any)?.message)
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async aiBrief(
    context?: string, // context allows to send the previous ai response to retain context
    refine?: string, // refine is a query sent in order to refine the previous ai response
    // temperature: number, // temperature is a number between 0 and 1 that allows to control the diversity of the response for now it's hard coded 
    // instructions: BriefInstructions // instructions is a json object that allows to send instructions to the ai
  ) {
    try {
      const selectedProduct: ProductsBrand | undefined = JSON.parse(sessionStorage.getItem('product') ?? "{}")
      const brand: BrandI = userMobx.getBrandSession() ?? {}
      const userInfos = userMobx.getUserInfos() as ClientI

      this.aiBriefReq = {
        ...this.aiBriefReq,
        loading: true,
        error: null,
        data: null,
      }
      let instructions: {
        product?: string,
        product_description?: string,
        product_price?: number,
        briefId: string,
      } = {
        briefId: briefR.briefCreationReq.data?._id!
      }
      if (selectedProduct) {
        instructions = {
          ...instructions,
          product: selectedProduct?.name,
          product_description: selectedProduct?.description,
          product_price: selectedProduct?.price,
        }
      }
      const { data, status } = await api.post<string>(
        endpoints.getEndpoint(EndT.aiBrief),
        {
          context: context,
          refine: refine,
          temperature: 0.9,
          instructions: instructions
        }
      )
      if (status === 200) {
        this.aiBriefReq = {
          ...this.aiBriefReq,
          loading: false,
          error: null,
        }
        toast.success(ToastMsg, {
          autoClose: 3000,
          type: 'success',
          icon: false,
          style: {
            width: 600,
            background: '#46FFB1',
          },
          data: {
            title: i18n.t('form-casting:casting.cards-toast-success.description'),
          },
          position: toast.POSITION.TOP_CENTER,
          className: 'toast-success',
        })
        return data
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(ToastMsg, {
        autoClose: 3000,
        type: 'error',
        icon: false,
        style: {
          width: 600,
          background: '#F13131',
        },
        data: {
          title: i18n.t('form-casting:casting.cards-toast-error.description'),
        },
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-error',
      })
      this.aiBriefReq = {
        ...this.aiBriefReq,
        error: errorMessage,
        loading: true,
      }
      setTimeout(() => {
        console.log(this.aiBriefReq)
        this.aiBriefReq = {
          ...this.aiBriefReq,
          loading: false,
        }
      }, 3000)
    }
  }

  @action.bound
  async validateBrief(briefId: string, callBack?: () => void) {
    try {
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: true,
        error: null,
        data: null,
      }
      const { data, status } = await api.post<BriefI>(
        endpoints.getEndpoint(EndT.validateBrief, briefId)
      )
      if (status === 200) {
        this.briefCreationReq = {
          ...this.briefCreationReq,
          loading: false,
          error: null,
          data: data as BriefI,
        }
        if (callBack) callBack()
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: this.brief.data?.private?.is_private && errorMessage === 'no-credits' ? i18n.t(`common:error.${errorMessage}`) : errorMessage,
        },
      })
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async patchBrief<T = null>(
    brief: CustomBriefI & T,
    idBrief: string,
    callBack?: () => void
  ) {
    try {
      if (brief.to_checkout?.links) {
        brief.to_checkout.links = brief.to_checkout.links.filter(l => l.url !== '')
      }

      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: true,
        error: null,
        data: null,
      }
      const { data, status } = await api.patch<BriefI>(
        endpoints.getEndpoint(EndT.updateBrief, idBrief),
        { ...brief }
      )
      if (status === 200) {
        this.briefCreationReq = {
          ...this.briefCreationReq,
          loading: false,
          error: null,
          data: data,
        }
        if (callBack) callBack()
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async patchNotifications(
    notifications: CustomBriefI['notifications'],
    idBrief: string,
    callBack?: () => void
  ) {
    try {
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: true,
        error: null,
        data: null,
      }
      const { data, status } = await api.patch<BriefI>(
        endpoints.getEndpoint(EndT.notificationsBrief, idBrief),
        { notifications }
      )
      if (status === 200) {
        this.briefCreationReq = {
          ...this.briefCreationReq,
          loading: false,
          error: null,
          data: data,
        }
        if (callBack) callBack()
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async extendBriefDeadline<T = null>(idBrief: string, charge: number) {
    try {
      this.brief = {
        ...this.brief,
        error: null,
        // data: null,
      }
      const { data, status } = await api.patch<BriefI>(
        endpoints.getEndpoint(EndT.extendBriefDeadline, idBrief),
        {
          deadline: DateHandler.getFormatedUSDate(
            new Date(
              (this.brief.data as BriefI & { deadline_: string })?.deadline_!
            )
          ),
        }
      )
      if (status === 200) {
        this.brief = {
          ...this.brief,
          loading: false,
          error: null,
          data: data,
        }
        // updating userMobx with new value for credits
        if (userMobx.me.data) {
          userMobx.me.data = {
            ...userMobx.me.data,
            _company: {
              ...userMobx.me.data._company,
              credits: userMobx.me.data._company.credits - charge
            }
          }
        }

        toast.success(i18n.t('mycastings:extend-casting:extend-confirmation'), {
          theme: 'colored',
        })
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.brief = {
        ...this.brief,
        loading: false,
        error: errorMessage,
        // data: null,
      }
    }
  }

  @action.bound
  async uploadFile<T = null>(
    brief: CustomBriefI & T,
    idBrief: string,
    callBack?: () => void
  ) {
    try {
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: true,
        error: null,
        data: null,
      }
      const { media, inspiration, music, script } = brief
      const formData = new FormData()
      const dd = {}


      if (media) {
        const filteredMedia = media.filter((item) => item !== undefined)
        if (filteredMedia.filter((v) => v instanceof File)?.length > 0) {
          for (const k of media.filter((v) => v instanceof File)) {
            formData.append('media', k as File)
          }
        }
        if ((filteredMedia as Array<MediaBrief>).filter((v) => v.source)?.length > 0) {
          for (const k of (filteredMedia as Array<MediaBrief>).filter((v) => v.source)) {
            formData.append('media', JSON.stringify(k))
          }
        }
      }
      if (
        inspiration?.media &&
        inspiration?.media.filter((v) => v instanceof File)?.length > 0
      ) {
        for (const k of inspiration?.media.filter((v) => v instanceof File)) {
          formData.append('inspiration', k as File)
        }
      }
      if (music instanceof File) formData.append('music', music)
      if (script instanceof File) formData.append('script', script)

      if (Array.from(formData)?.length) {
        const { data, status } = await api.post<BriefI>(
          endpoints.getEndpoint(EndT.uploadFile, idBrief),
          formData
        )

        if (status === 200) {
          this.briefCreationReq = {
            ...this.briefCreationReq,
            loading: false,
            error: null,
            data: data,
          }
          callBack!()
        } else {
          throw new Error('An error occured.')
        }
      } else {
        this.briefCreationReq = {
          ...this.briefCreationReq,
          loading: false,
          error: null,
          data: null,
        }
        callBack!()
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async deleteFile<T extends { _id: string; key: string }>(
    media: T,
    idBrief: string,
    mediaType: 'inspi' | 'media',
    mediaBrief: keyof Pick<BriefI, 'media' | 'inspiration' | 'music' | 'script'>
  ) {
    try {
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: true,
        error: null,
        data: null,
      }
      const { data, status } = await api.delete<BriefI>(
        endpoints.getEndpoint(EndT.deleteFile, idBrief),
        {
          data: {
            media,
            mediaInspi: mediaType === 'inspi' ? mediaBrief : mediaType,
          },
        }
      )
      if (status === 200) {
        this.briefCreationReq = {
          ...this.briefCreationReq,
          loading: false,
          error: null,
          data: data,
        }
        if (mediaType === 'media') {
          this.brief.data = {
            ...(this.brief.data as any),
            media: (this.brief.data?.media as Media[])!
              .slice()
              .filter((v) => v._id !== media._id),
          }
        }
        if (mediaBrief === 'inspiration') {
          this.brief.data = {
            ...(this.brief.data as any),
            inspiration: this.brief.data?.inspiration
              ?.media!?.slice()
              .filter((v) => v._id !== media._id),
          }
        } else if (mediaBrief === 'music') {
          this.brief.data = {
            ...(this.brief.data as any),
            music: undefined,
          }
        } else if (mediaBrief === 'script') {
          this.brief.data = {
            ...(this.brief.data as any),
            script: undefined,
          }
        }
      } else {
        throw new Error('An error occured.')
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast(ToastMsg, {
        autoClose: false,
        type: 'error',
        icon: false,
        data: {
          title: i18n.t('common:error.basic'),
          description: errorMessage,
        },
      })
      this.briefCreationReq = {
        ...this.briefCreationReq,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action async getBriefApplications(
    idB: string,
    getWinners?: boolean,
    callback?: (val: MyCasting[]) => void
  ) {
    const newid = idB.split('#')[0]
    // generateBriefMsg()
    // generateMsg()
    try {
      this.baMessagesCount = {
        ...this.baMessagesCount,
        loading: true,
        data: [],
      }
      this.briefApplication = {
        ...this.briefApplication,
        loading: true,
        error: null,
      }
      const { data, status } = await api.get<MyCasting[] | ErrorRequest>(
        endpoints.getEndpoint(EndT.briefApplications, newid)
      )
      if (status === 200) {
        const datas = data as MyCasting[]

        if (callback) callback!(datas)


        const datasFiltered = datas.filter((dt) => dt?.messages?.length > 0)
        let messages: Array<any> = []
        this.medias = []
        this.briefApplication = {
          ...this.briefApplication,
          loading: false,
          error: null,
          data: datas,
        }
        try {
          for (let i = 0; i < datasFiltered?.length; i++) {
            messages = messages.concat(datasFiltered[i].messages)
          }
          this.baMessagesCount = {
            ...this.baMessagesCount,
            loading: false,
            data: messages,
          }
          for (let i = 0; i < datas?.length; i++) {
            try {
              if (getWinners) {
                const req = await api.get<MediaBA[] | ErrorRequest>(
                  endpoints.getEndpoint(EndT.allMedias, datas[i]?._id!)
                )
                if (
                  req.status === 200 &&
                  this.briefApplication.data![i].media?.length > 0
                ) {
                  // this.briefApplication.data[i].media = (req.data as MediaBA[])
                  for (
                    let l = 0;
                    l <
                    (req.data as MediaBA[]).filter((v) => v.status === 'chosen')
                      ?.length;
                    l++
                  ) {
                    if ((req.data as MediaBA[])?.length > 0) {
                      if (
                        !this.medias
                          .slice()
                          .some((e) => e._id === (req.data as MediaBA[])[l]._id)
                      )
                        this.medias = this.medias?.concat({
                          ...(req.data as MediaBA[])[l],
                          applicant: (datas[i] as MyCasting).applicant as Applicant,
                        })
                    }
                  }
                }
              }
            } catch (error) {
              // console.log(error)
            }
          }
        } catch (e) {
          const error = new GloBalErrorService(e)
          const errorMessage = error.message
          return error.message
        }
      } else
        this.briefApplication = {
          ...this.briefApplication,
          loading: false,
          error: (data as ErrorRequest).message,
        }
    } catch (e) {
      this.briefApplication = {
        ...this.briefApplication,
        loading: false,
        error: (e as ErrorRequest).message,
        data: [],
      }
    }
  }

  @action.bound
  async getOneBa(idB: string, callback?: (ba: BaCustom) => void) {
    //  generateBriefApplication()
    // console.log('first')
    try {
      this.detailBA = {
        ...this.detailBA,
        loading: true,
        error: null,
        data: null,
      }

      const { data, status } = await api.get<DetailBa | ErrorRequest>(
        endpoints.getEndpoint(EndT.detailBA, idB)
      )

      if (status === 200) {
        let briefDetail = data as DetailBa
        const newBa = await new Promise<Request<DetailBa>>(
          (resolve, reject) => {
            briefDetail = {
              ...briefDetail,
              briefApplication: {
                ...briefDetail.briefApplication,
              },
            }
            const formatedMessages = briefDetail.briefApplication?.messages
              .filter((v) => v.hasOwnProperty('sender'))
              .map(
                (message) =>
                  new Message({
                    id: message._id,
                    message: message.text,
                    senderName: message.sender,
                  })
              )
            resolve({
              ...this.detailBA,
              loading: false,
              data: {
                ...(data as DetailBa),
                briefApplication: {
                  ...(data as DetailBa).briefApplication,
                  formatedMessages,
                },
              },
              error: null,
            })
          }
        ).catch((e) => {
          throw new Error(e)
        })
        // console.log({ newBa })
        this.detailBA = newBa
        if (callback) callback(briefDetail.briefApplication)
      }
      //   try {
      //     const req = await api.get<DetailBa | ErrorRequest>(
      //       endpoints.getEndpoint(EndT.userinfo, ba?.applicant as string ?? (ba?.applicant as Applicant)._id)
      //     )
      //     if (req.status === 200) {
      //       const newBa = await new Promise<Request<BaCustom>>(
      //         (resolve, reject) => {
      //           ba.applicant = req.data as ApplicantBa
      //           resolve({
      //             ...this.detailBA,
      //             loading: false,
      //             data: ba,
      //             error: null,
      //           })
      //         }
      //       )
      //       this.detailBA = newBa
      //     } else throw req.data as ErrorRequest
      //   } catch (error) {
      //     throw error as ErrorRequest
      //   }
      // } else {
      //   throw data as ErrorRequest
      // }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      // console.log(errorMessage)
      this.detailBA = {
        ...this.detailBA,
        loading: false,
        data: null,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getOneFavourite(idUser: string, callBack?: () => void) {
    try {
      this.detailFav = {
        ...this.detailFav,
        loading: true,
        error: null,
        data: null,
      }
      const ba = {} as CustomFav
      try {
        const userInfos = userMobx.getUserInfos() ?? userMobx.me.data as ClientI
        const req = await api.get<ApplicantBa | ErrorRequest>(
          endpoints.getEndpoint(userInfos !== null ? EndT.userinfo : EndT.userinfoPublic, idUser as string)
        )
        if (req.status === 200) {
          const newBa = await new Promise<Request<CustomFav>>(
            (resolve) => {
              ba.applicant = req.data as ApplicantBa
              ba.mediaValidatedCount = (req.data as ApplicantBa).userMedias?.length ?? 0
              ba.media = (req.data as ApplicantBa).userMedias ?? []
              resolve({
                ...this.detailFav,
                loading: false,
                data: ba as CustomFav,
                error: null,
              })
            }
          )
          this.detailFav = newBa
          if (callBack) callBack()
        } else throw req.data as ErrorRequest
      } catch (error) {
        throw error as ErrorRequest
      }

    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      // console.log(errorMessage)
      this.detailFav = {
        ...this.detailFav,
        loading: false,
        data: null,
        error: errorMessage,
      }
    }
  }

  // @action.bound
  // async getBriefApplicationMsg(
  //   idB: string,
  //   idBa?: string,
  //   msgBa?: boolean,
  //   callback?: () => void
  // ) {
  //   // generateBriefMsg()
  //   // generateMsg()
  //   this.messages = { ...this.messages, data: [], loading: true, error: null }
  //   const datasBa: MyCasting[] = this.briefApplication.data!
  //   const allMessages: Array<message.Message> = []

  //   if (msgBa) {
  //     // Messages type
  //     const newid = idBa!.split('#')[0]
  //     const req = await api.get<message.Message[] | ErrorRequest>(
  //       endpoints.getEndpoint(EndT.userMessages, newid)
  //     )

  //     const newBa = await new Promise<Request<any>>((resolve, reject) => {
  //       // if (datasBa[i].media.filter(md=>md.status== "validated").length > 0 && datasBa[i].messages.length > 0 && !this.messages.data.some(e => e?._id === datasBa[i].messages[0]._id))
  //       const formatedMessages = (req.data as message.Message[])
  //         .filter((v) => v.hasOwnProperty('sender'))
  //         .map(
  //           (message) =>
  //             new Message({
  //               id: message._id,
  //               message: message.text,
  //               senderName: message.sender,
  //             })
  //         )
  //       resolve({
  //         ...this.detailBA,
  //         loading: false,
  //         data: {
  //           ...this.detailBA.data,
  //           briefApplication: {
  //             ...(this.detailBA.data?.briefApplication as BaCustom),
  //             messages: (req.data as message.Message[]),
  //             formatedMessages,
  //           },
  //         },
  //         error: null,
  //       })
  //     })
  //     // this.messages = newBa
  //     this.detailBA = newBa
  //     if (callback) callback()
  //     // await this.getBriefApplications(idB);
  //   } else {
  //     await this.getBriefApplications(idB)
  //     for (
  //       let i = 0;
  //       i <
  //       (datasBa as MyCasting[]).filter((v) => v.messages?.length > 0)?.length;
  //       i++
  //     ) {
  //       if (datasBa[i].messages?.length === 0) {
  //         try {
  //           const req = await api.get<message.Message[] | ErrorRequest>(
  //             endpoints.getEndpoint(EndT.userMessages, datasBa[i]?._id)
  //           )
  //           datasBa[i].message = (req.data as message.Message[]) || []
  //           for (
  //             let d = 0;
  //             d < ((req.data as message.Message[]) || [])?.length;
  //             d++
  //           ) {
  //             allMessages.push((req.data as message.Message[])[d])
  //           }
  //         } catch (e) {
  //           const error = new GloBalErrorService(e)
  //           const errorMessage = error.message
  //           this.messages = {
  //             ...this.messages,
  //             data: [],
  //             loading: false,
  //             error: errorMessage,
  //           }
  //           return
  //         }
  //       }
  //     }
  //     this.messages = {
  //       ...this.messages,
  //       data: allMessages,
  //       loading: false,
  //       error: null,
  //     }
  //   }
  // }

  @action.bound
  productSent(idBA: string, trackingLink: string, callBack?: () => void) {
    try {
      this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: true, error: null }
      api
        .put<MyCasting | ErrorRequest>(
          endpoints.getEndpoint(EndT.sendProduct, idBA),
          { trackingLink }
        )
        .then(({ data, status }) => {
          if (status === 200) {
            this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: false, error: null }
            if (this.briefApplication.data)
              this.briefApplication.data.find(
                (v, i) => v._id === (data as MyCasting)!._id
              )!.product_sent = (data as MyCasting).product_sent
            if (callBack) callBack()
          } else
            this.reqConfirmStep2 = {
              ...this.reqConfirmStep2,
              loading: false,
              error: (data as ErrorRequest).message,
            }
        })
        .catch((e: any) => {
          const error = new GloBalErrorService(e)
          const errorMessage = error.message
          toast.error(errorMessage, { theme: 'colored' })
          this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: false, error: e.message }
        })
    } catch (e) {
      // console.log(e)
    }
  }

  @action.bound
  confirmRdv(idBA: string, addressId: string, date: Date, callBack?: () => void) {
    try {
      this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: true, error: null }
      api
        .patch<MyCasting | ErrorRequest>(
          endpoints.getEndpoint(EndT.confirmRdv, idBA),
          { addressId, date }
        )
        .then(({ data, status }) => {
          if (status === 200) {
            this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: false, error: null }
            if (this.briefApplication.data) {
              const ba = this.briefApplication.data.find((v) => v._id === (data as MyCasting)?._id)
              if (ba) ba.rendez_vous = (data as MyCasting).rendez_vous
            }
            if (callBack) callBack()
          } else
            this.reqConfirmStep2 = {
              ...this.reqConfirmStep2,
              loading: false,
              error: (data as ErrorRequest).message,
            }
        })
        .catch((e: any) => {
          const error = new GloBalErrorService(e)
          const errorMessage = error.message
          toast.error(errorMessage, { theme: 'colored' })
          this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: false, error: e.message }
        })
    } catch (e) {
      // console.log(e)
    }
  }

  @action.bound
  editRdv(idBA: string, addressId: string, date: Date, callBack?: () => void) {
    try {
      this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: true, error: null }
      api
        .patch<MyCasting | ErrorRequest>(
          endpoints.getEndpoint(EndT.editRdv, idBA),
          { addressId, date }
        )
        .then(({ data, status }) => {
          if (status === 200) {
            toast.success(i18n.t('mycastings:confirm-edit-rdv'), { theme: 'colored' })
            this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: false, error: null }
            if (this.briefApplication.data)
              this.briefApplication.data.find(
                (v, i) => v._id === (data as MyCasting)!._id
              )!.rendez_vous = (data as MyCasting).rendez_vous
            if (callBack) callBack()
          } else
            this.reqConfirmStep2 = {
              ...this.reqConfirmStep2,
              loading: false,
              error: (data as ErrorRequest).message,
            }
        })
        .catch((e: any) => {
          const error = new GloBalErrorService(e)
          const errorMessage = error.message
          toast.error(errorMessage, { theme: 'colored' })
          this.reqConfirmStep2 = { ...this.reqConfirmStep2, loading: false, error: e.message }
        })
    } catch (e) {
      // console.log(e)
    }
  }

  @action.bound
  async sendMessage(msg: string, ba: BaCustom) {
    this.message = { ...this.message, loading: true, error: null }
    try {
      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<message.Message> = await api.post(
        endpoints.getEndpoint(EndT.sendMessage, ba._id),
        { text: msg }
      )
      if (status === 200) {
        this.detailBA.data?.briefApplication?.formatedMessages!?.push(
          (new Message({
            id: (data as message.Message)._id,
            message: (data as message.Message).text,
            senderName: (data as message.Message).sender,
          }) as unknown) as any
        )
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(errorMessage, { theme: 'colored' })
    }
    this.message = { ...this.message, loading: false, error: null }
  }

  @action.bound
  async addToBasket(mediaId: string) {
    try {
      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<IUpdateBasquetReq> = await api.post(
        endpoints.getEndpoint(EndT.updateBasket, mediaId)
      )
      if (status === 200) {
        const dataR = data as IUpdateBasquetReq
        toast.success(
          `La vogz ${dataR.readable_id} a été ${dataR.in_basket ? 'ajouté' : 'retiré'
          } de la sélection`,
          { theme: 'colored', hideProgressBar: true }
        )
        if (this.briefApplication.data!?.length > 0) {
          this.briefApplication
            .data!.find(
              (v, i) =>
                v._id === (data as IUpdateBasquetReq)!.brief_application._id
            )!
            .media.find(
              (v, i) => v._id === mediaId
            )!.in_basket = (data as IUpdateBasquetReq).in_basket
        }
        if (
          data.hasOwnProperty('in_basket') &&
          this.detailBA.data?.briefApplication?.media !== undefined
        )
          this.detailBA.data.briefApplication.media.find(
            (v, i) => v._id === mediaId
          )!.in_basket = (data as IUpdateBasquetReq).in_basket
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(
        errorMessage as ErrorUpdateBasket ? i18n.t(`common:error.${errorMessage}`) : i18n.t('common:error.basic'),
        { theme: 'colored' }
      )
    }
  }

  @action.bound
  async generateMaster(idMedia: string, idBa?: string) {
    try {
      this.master = { ...this.master, loading: true, error: null, data: null }
      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<IUpdateBasquetReq> = await api.patch(
        endpoints.getEndpoint(EndT.generateMaster, idMedia)
      )
      if (1 + 1 === 2) {
        toast.info('Media en cours génération...')
        if (!idBa) {
          this.boughtMedia.data!.find(
            (v) =>
              (v.brief_application as MyCasting)._id ===
              (data as IUpdateBasquetReq).brief_application._id
          )!.status = (data as IUpdateBasquetReq).status as any
        } else {
          this.briefApplication
            .data!.find(
              (v) => v._id === (data as IUpdateBasquetReq).brief_application._id
            )!
            .media.find(
              (k) => k._id === idMedia
            )!.master.status = (data as IUpdateBasquetReq).master.status as any
          this.master = {
            ...this.master,
            loading: false,
            error: null,
            data: null,
          }
        }
        if ((data as IUpdateBasquetReq).master.status === 'preparing') {
          const time = 60 * 1
          this.setTimeoutMedia(idMedia, '00:00', idBa!)
          startTimer(
            time,
            (time) => this.setTimeoutMedia(idMedia, time, idBa!),
            async () => {
              if (!idBa) {
                await this.getAllBoughtMedia()
              } else {
                await this.getBriefApplications(
                  (data as IUpdateBasquetReq).brief._id,
                  true
                )
              }
            }
          )
          if (!idBa) {
            this.boughtMedia.data!.find(
              (v) => v._id === idMedia
            )!.master.status = (data as IUpdateBasquetReq).master.status
            this.boughtMedia.data!.find(
              (v) => v._id === idMedia
            )!.master.expiresAt = (data as IUpdateBasquetReq).master.expiresAt
          } else {
            this.briefApplication
              .data!.find((v) => v._id === idBa)!
              .media.find(
                (v) => v._id === idMedia
              )!.master.status = (data as IUpdateBasquetReq).master.status
            this.briefApplication
              .data!.find((v) => v._id === idBa)!
              .media.find(
                (v) => v._id === idMedia
              )!.master.expiresAt = (data as IUpdateBasquetReq).master.expiresAt
          }
        } else if ((data as IUpdateBasquetReq).master.status === 'ready') {
          toast.success('Media généré. \n Vous pouvez télécharger la vogz.')
        }
        this.master = {
          ...this.master,
          loading: false,
          error: null,
          data: null,
        }
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      this.master = {
        ...this.master,
        loading: false,
        error: error.message as ErrorMaster && i18n.t(`common:error.${error.message}`),
      }
      toast.error(
        error.message as ErrorMaster ? i18n.t(`common:error.${error.message}`) : i18n.t('common:error.basic'),
        { theme: 'colored' }
      )
      console.log(e)
    }
  }

  @action.bound
  setTimeoutMedia(idMedia: string, timeout: string, idBa: string) {
    if (!idBa) {
      this.boughtMedia.data!.find((v) => v._id === idMedia)!.timeout = timeout
    } else {
      this.briefApplication
        .data!.find((v) => v._id === idBa)!
        .media.find((k) => k._id === idMedia)!.timeout = timeout
    }
  }

  @action.bound
  async setSeenMediaFromBA(idBa: string, idMedia: string) {
    try {
      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<MediaBA> = await api.patch(
        endpoints.getEndpoint(EndT.markSeen, idMedia)
      )
      if (status === 200) {
        if (this.briefApplication.data!?.length > 0)
          this.briefApplication
            .data!.find((v) => v._id === idBa)!
            .media!.find(
              (v) => v._id === idMedia
            )!.seen = (data as MediaBA).seen
        if (this.detailBA.data && this.detailBA.data!.briefApplication.media)
          this.detailBA.data!.briefApplication.media!.find(
            (v) => v._id === idMedia
          )!.seen = (data as MediaBA).seen
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      // console.log(e)
    }
  }

  @action.bound
  async buySelection(idBA: string, showToast?: boolean, onSuccess?: () => void) {
    try {
      this.validateSelection = {
        ...this.validateSelection,
        loading: true,
        error: null,
        data: null,
      }
      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<BaCustom> = await api.post(
        endpoints.getEndpoint(EndT.validateSelection, idBA)
      )
      if (status === 200 && (data as BaCustom)) {
        this.validateSelection = {
          ...this.validateSelection,
          loading: false,
          error: null,
          data: data as BaCustom,
        }
        const medias = await getMediaWinners(false, this.briefApplication.data!)
        if (typeof medias !== 'string') this.medias = medias
        this.briefApplication.data!.find((v, i) => {
          return v._id === (data as BaCustom)._id
        })!.status = (data as BaCustom).status
        if (!showToast) {
          setTimeout(() => {
            modal.toogleSelection(undefined, undefined)
            this.validateSelection = {
              ...this.validateSelection,
              loading: false,
              error: null,
              data: null,
            }
            toast.success(
              'Votre commande est validé \n Rendez vous dans MES ACHATS',
              {
                hideProgressBar: true,
              }
            )
            onSuccess && onSuccess()
          }, 2000)
        }
      }
      if (onSuccess)
        onSuccess()
    } catch (e) {
      const error = new GloBalErrorService(e)
      let err = ''
      switch (error.message) {
        case 'no-more-allowance':
        case 'no-more-credits':
          err = i18n.t(`mycastings:modalCart.error.${error.message}`)
          break
        default:
          err = i18n.t(`mycastings:modalCart.error.default`)
          break
      }
      toast.error(err)
      this.validateSelection = {
        ...this.validateSelection,
        loading: false,
        error: err,
        data: null,
      }
    }
  }

  @action.bound
  async validateOrCloseBriefApplication(
    idBA: string,
    type: 'validate' | 'decline',
    showToast?: boolean
  ) {
    try {
      this.actionBa = {
        ...this.actionBa,
        loading: true,
        error: null,
        data: null,
      }

      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<BaCustom> = await api.post(
        endpoints.getEndpoint(
          type === 'decline' ? EndT.declineBa : EndT.validateStep2,
          idBA
        )
      )
      if (status === 200) {
        this.detailBA.data!.briefApplication.status = (data as BaCustom).status
        this.actionBa = {
          ...this.actionBa,
          loading: false,
          error: null,
          data: null,
        }
        if (type === 'decline')
          toast.info(i18n.t('mycastings:vogzter-removed'), {
            theme: 'colored',
          })
        else {
          if (this.detailBA.data!.briefApplication.brief.with_product)
            toast.info(i18n.t('mycastings:go-shipping-products'), {
              theme: 'colored',
            })
          else if (this.detailBA.data!.briefApplication.brief.on_spot && this.detailBA.data!.briefApplication.brief.on_spot?.is_on_spot)
            toast.info(i18n.t('mycastings:go-rvd-vogzter'), {
              theme: 'colored',
            })
          else
            toast.info(i18n.t('mycastings:go-video'), {
              theme: 'colored',
            })
          const match = matchPath(
            { path: '/dashboard/castings/:id/briefapplication/:de' },
            location.pathname
          )
          // eslint-disable-next-line no-restricted-globals
          if ((data as BaCustom).brief.private?.is_private && (data as BaCustom).brief.content_type === 'influence')
            location.href = `/dashboard/castings/${match?.params.id}?status=finals`
          else
            location.href = `/dashboard/castings/${match?.params.id}?status=deliveries`
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      const error = new GloBalErrorService(e)
      this.actionBa = {
        ...this.actionBa,
        loading: true,
        error: error.message,
        data: null,
      }
      toast.error(error.message, { theme: 'colored' })
    }
    modal.toogleAlert(undefined, false)
  }

  // brief exclusive
  @action.bound
  async basketOrDecline(idBA: MyCasting, type: 'validate' | 'decline') {
    try {
      const {
        data,
        status,
      }: GenericAxiosRequestVOGZtype<MediaBA | BaCustom> = await api.post(
        endpoints.getEndpoint(
          type === 'decline' ? EndT.declineBa : EndT.updateBasket,
          type === 'decline' ? idBA._id : idBA.media[0]._id
        )
      )
      if (status === 200) {
        if (type === 'validate')
          this.briefApplication.data!.find(
            (ba) => ba._id === idBA._id
          )!.media[0].in_basket = (data as MediaBA).in_basket
        else
          this.briefApplication.data!.find(
            (ba) => ba._id === idBA._id
          )!.status = (data as BaCustom).status
      }
      // return data;
    } catch (e) {
      const error = new GloBalErrorService(e)
      this.actionBa = {
        ...this.actionBa,
        loading: true,
        error: error.message,
        data: null,
      }
      toast.error(error.message, { theme: 'colored' })
      // console.log(e)

      return e
    }
  }

  @action.bound
  async validateExclu() {
    if (
      this.briefApplication.data!.filter(
        (ba) =>
          !ba?.exclusive?.code &&
          ba.media.filter((md) => md.in_basket === true)?.length > 0
      )?.length
    ) {
      this.briefApplication
        .data!.filter(
          (ba) =>
            !ba?.exclusive?.code &&
            ba.media.filter((md) => md.in_basket === true)?.length > 0
        )
        // eslint-disable-next-line array-callback-return
        .map((ba) => {
          ba.exclusive = { ...ba.exclusive!, error: 'Champs requis.' }
        })
    } else {
      this.validateBaExclu = {
        ...this.validateBaExclu,
        loading: true,
        error: null,
        data: null,
      }

      try {
        const myBas = this.briefApplication.data!.filter(
          (ba) =>
            ba.media.filter((md) => md.in_basket === true)?.length > 0 &&
            ba.status !== 'won'
        )
        const objBas: ValidateExclusiveBriefReq[] = []
        for (let i = 0; i < myBas?.length; i++) {
          objBas.push({ id: myBas[i]._id, coupon: myBas[i].exclusive!?.code })
        }
        const {
          data,
          status,
        }: GenericAxiosRequestVOGZtype<MediaBA | MyCasting[]> = await api.post(
          endpoints.getEndpoint(
            EndT.validateExclus,
            this.briefApplication.data![0].brief._id
          ),
          {
            briefapplications: objBas,
          }
        )
        if (status === 200) {
          this.validateBaExclu = {
            ...this.validateBaExclu,
            loading: false,
            error: null,
            data: data as MyCasting[],
          }

          setTimeout(() => {
            modal.toogleSelection(undefined, undefined, false)
              ; (data as MyCasting[]).forEach((element) => {
                this.briefApplication.data = this.briefApplication.data?.map(
                  (ba) => {
                    if (ba._id === element._id) {
                      ba.status = element.status
                      ba.media = element.media.map((md) => ({
                        ...md,
                        applicant: ba.applicant as Applicant,
                        // status: 'chosen'
                      }))
                    }
                    return ba
                  }
                )!
                // this.medias = this.medias.slice().concat(element.media.map(md=>  md))
              })
            toast.info(i18n.t('mycastings:rdv-buys'), {
              theme: 'colored',
            })
          }, 1000)
        }
      } catch (e) {
        const error = new GloBalErrorService(e)
        this.validateBaExclu = {
          ...this.validateBaExclu,
          loading: false,
          error: error.message,
          data: null,
        }
        toast.error(error.message, { theme: 'colored' })
        // console.log(e)
      }
    }
  }

  @action.bound
  async getFavouriteVogzters() {
    this.vogzters = [
      {
        name: 'Alexandre',
        last_name: 'Zola',
        gender: 'male',
        createdAt: '2021-05-29T13:20:28.035Z',
        src: '',
        favourite: false,
        certification: true,
        id: '629610d343be1a001b0ec915',
      },
      {
        name: 'Sara',
        last_name: 'Kola',
        gender: 'female',
        createdAt: '2021-12-29T13:20:28.035Z',
        src: '',
        favourite: false,
        certification: false,
        id: '622632dcb8a6e6001bca44fe',
      },
      {
        name: 'Julia',
        last_name: 'Pola',
        gender: 'female',
        createdAt: '2021-03-29T13:20:28.035Z',
        src: '',
        favourite: true,
        certification: true,
        id: '6270f78f3907fc001b9133f7',
      },
      {
        name: 'Pierre-Louis',
        last_name: 'Fola',
        gender: 'male',
        createdAt: '2021-06-29T13:20:28.035Z',
        src: '',
        favourite: true,
        certification: false,
        id: 712,
      },
      {
        name: 'Esteban',
        last_name: 'Vola',
        gender: 'female',
        createdAt: '2021-02-29T13:20:28.035Z',
        src: '',
        favourite: false,
        certification: true,
        id: 74512,
      },
    ]
  }

  @action.bound
  setBriefApplication(data: MyCasting[]) {
    this.briefApplication = {
      ...this.briefApplication,
      loading: false,
      error: null,
      data,
    }
  }

  @action.bound
  async sendTips(idBa: string, amount: number, description: string, callBack?: (data: number) => void) {
    this.tipsSent = {
      ...this.tipsSent,
      loading: true,
      error: null,
      data: null,
    }
    try {
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.sendTips, idBa),
        { amount, description }
      )
      if (status === 200) {
        if (userMobx.me.data) {
          userMobx.me.data = {
            ...userMobx.me.data,
            _company: {
              ...userMobx.me.data._company,
              credits: userMobx.me.data._company.credits - amount
            }
          }
        }
        if (callBack) callBack(data)
      }
    } catch (e) {

      const error = new GloBalErrorService(e)
      toast.error(
        error.message as ErrorSendTips ? i18n.t(`common:error.${error.message}`) : i18n.t('common:error.basic'),
        { theme: 'colored' }
      )
    }
  }

  @action.bound
  async publicationDate({ idBA, idMedia, date }: { idBA: string, idMedia: string, date?: string }, callBack?: () => void) {

    this.briefApplication = {
      ...this.briefApplication,
      loading: true,
      error: null,
    }

    try {
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.publicationDate, idMedia),
        { date }
      )
      if (status === 200) {
        if (callBack) callBack()
        const updatedMedia = this.briefApplication.data?.find(v => v._id === idBA)?.media.find(v => v._id === idMedia);

        if (updatedMedia) {
          if (date)
            updatedMedia.publication.date = date
          updatedMedia.publication.status = 'to-post'
          this.briefApplication = {
            ...this.briefApplication,
            loading: false,
            error: null,
            data: this.briefApplication.data?.map(ba => ({
              ...ba,
              media: ba._id === idBA ? ba.media.map(m => (m._id === idMedia ? updatedMedia : m)) : ba.media
            })) ?? []
          };
        }
      }


    } catch (e) {
      const error = new GloBalErrorService(e)
      toast.error(
        error.message as ErrorPublication ? i18n.t(`common:error.${error.message}`) : i18n.t('common:error.basic'),
        { theme: 'colored' }
      )
    }
  }

  @action.bound
  async declinePublication({ idBA, idMedia, reason }: { idBA: string, idMedia: string, reason: Publication['declined_reason'] }, callBack?: () => void) {

    this.briefApplication = {
      ...this.briefApplication,
      loading: true,
      error: null,
    }

    try {
      const { data, status } = await api.patch(
        endpoints.getEndpoint(EndT.declinedPublication, idMedia),
        { reason }
      )
      if (status === 200) {
        if (callBack) callBack()
        const updatedMedia = this.briefApplication.data?.find(v => v._id === idBA)?.media.find(v => v._id === idMedia);

        if (updatedMedia) {
          updatedMedia.publication.declined_reason = reason;
          updatedMedia.publication.status = 'declined'
          this.briefApplication = {
            ...this.briefApplication,
            loading: false,
            error: null,
            data: this.briefApplication.data?.map(ba => ({
              ...ba,
              media: ba._id === idBA ? ba.media.map(m => (m._id === idMedia ? updatedMedia : m)) : ba.media
            })) ?? []
          };
        }
      }


    } catch (e) {
      const error = new GloBalErrorService(e)
      toast.error(
        error.message as ErrorPublication ? i18n.t(`common:error.${error.message}`) : i18n.t('common:error.basic'),
        { theme: 'colored' }
      )
    }
  }

  @action.bound
  async confirmPublication({ idBA, idMedia }: { idBA: string, idMedia: string }, callBack?: () => void) {
    this.briefApplication = {
      ...this.briefApplication,
      loading: true,
      error: null,
    }

    try {
      const { data, status } = await api.patch(
        endpoints.getEndpoint(EndT.confirmPublication, idMedia))
      if (status === 200) {
        if (callBack) callBack()
        const updatedMedia = this.briefApplication.data?.find(v => v._id === idBA)?.media.find(v => v._id === idMedia);

        if (updatedMedia) {
          updatedMedia.publication.status = 'validated'
          this.briefApplication = {
            ...this.briefApplication,
            loading: false,
            error: null,
            data: this.briefApplication.data?.map(ba => ({
              ...ba,
              media: ba._id === idBA ? ba.media.map(m => (m._id === idMedia ? updatedMedia : m)) : ba.media
            })) ?? []
          };
        }
      }


    } catch (e) {
      const error = new GloBalErrorService(e)
      toast.error(
        error.message as ErrorPublication ? i18n.t(`common:error.${error.message}`) : i18n.t('common:error.basic'),
        { theme: 'colored' }
      )
    }
  }

  @action.bound
  async getAllCountries(callback?: () => void) {
    this.countries = { ...this.countries, loading: true, error: null }
    try {
      const { data, status }: GenericAxiosRequestVOGZtype<Country[]> = await api.get(
        endpoints.getEndpoint(EndT.getCountries)
      )
      if (status === 200) {
        this.countries = {
          ...this.countries,
          data: data as Country[],
          loading: false,
          error: null
        }
        if (callback) callback()
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      toast.error(error.message, { theme: 'colored' })
    }
  }


  @action.bound
  async getAllLanguages(callback?: () => void) {
    this.countries = { ...this.countries, loading: true, error: null }
    try {
      const { data, status }: GenericAxiosRequestVOGZtype<Language[]> = await api.get(
        endpoints.getEndpoint(EndT.getLanguages)
      )
      if (status === 200) {
        this.languages = {
          ...this.languages,
          data: data as Language[],
          loading: false,
          error: null
        }
        if (callback) callback()
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      toast.error(error.message, { theme: 'colored' })
    }
  }



}


const briefR = new Brief()
export default briefR
