import React from 'react'
import { observer } from 'mobx-react'
import AccessPlatform from '../Auth/userOnboard/steps/AccessPlatform'

const Premium2 = () => {
  return (
    <AccessPlatform />
  )
}

export default observer(Premium2)
