/* eslint-disable global-require */
import React, { useState, useEffect, useCallback, useRef, useMemo, ElementRef } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { Spinner } from 'grommet'
import algoliasearch from 'algoliasearch'
import { debounce } from 'lodash'
import { getName as getNameCountries } from 'i18n-iso-countries'
import { getName as getNameLanguages } from '@cospired/i18n-iso-languages'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import userMobx from '../../mobx/user'
import Title1 from '../../utils/components/Headings/Title1'
import ScrollToTop from '../../routing/ScrollToTop'
import BtnDrop from '../../utils/components/Button/BtnDrop'
import {
  Category,
  TypeClient,
  VogzterSearchFilters,
  VogzterSearchResult,
} from '../../types-project/Client'
import SearchBar from '../../utils/components/SearchBar'
import BrandSwitcher from '../../utils/components/BrandSwitcher/BrandSwitcher'
import DropdownCheckbox, {
  DropdownCheckboxClassNames,
  DropdownCheckboxOption,
} from '../../utils/components/Ui/dropdown/DropdownCheckbox'
import s from './components/styles.module.scss'
import Button from '../../utils/components/Ui/v2/buttons/Button'
import VogzterSearchItem from './components/VogzterSearchItem'
import DeletableList from './components/DeletableList'
import TextField from '../../utils/components/Ui/inputs/TextField'
import { Country, Language } from '../../types-project/Brief'
import briefR from '../../mobx/brief'
import AccessPlatform from '../Auth/userOnboard/steps/AccessPlatform'
import GalerieVogzterModalLoadMore from '../../utils/components/Modals/GalerieVogzterModalLoadMore'

const FIRST_PAGE_LIMIT = 4
const PAGE_LIMIT = 20

const favouriteVogzter = () => {
  const selectedBrand = userMobx.getBrandSession() ?? {}

  const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID ?? '',
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY ?? ''
  )
  const algoliaIndex = algoliaClient.initIndex('vogzters')
  const algoliaIndexVogzCount = algoliaClient.initIndex(
    'vogzters_sort_vogz_count'
  )
  const algoliaIndexCollabCount = algoliaClient.initIndex(
    'vogzters_sort_collab_count'
  )
  const algoliaIndexCreatedAsc = algoliaClient.initIndex(
    'vogzters_sort_created_asc'
  )
  const algoliaIndexCreatedDesc = algoliaClient.initIndex(
    'vogzters_sort_created_desc'
  )

  // Using useRef to keep track of the real value inside algoliaQuery()
  const allowAlgoliaRequest = useRef(false)

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const ageInterval = {
    minAge: 18,
    maxAge: 100,
    filters: ['18-25', '26-35', '+35'],
  }
  const [filters, updateFilters] = useState<VogzterSearchFilters>({
    fav: false,
    man: false,
    woman: false,
    certified: false,
    active: false,
    recommended: false,
    age18To25: false,
    age26To35: false,
    ageMoreThan35: false,
    socials: {
      // Must have the same name as the algolia index field
      tiktokLink: false,
      instagramLink: false,
      snapchatLink: false,
      youtubeLink: false,
    },
    followers: [],
    categories: [],
    region: undefined,
    budget: undefined,
    languages: [],
    countries: [],
  })
  const isBaseCreatorActivated =
    userMobx.me.data?._company.base_creator_activated
  const refDCVogzterType = useRef<DropdownCheckbox>(null)
  const refDCRegion = useRef<DropdownCheckbox>(null)
  const refDCGender = useRef<DropdownCheckbox>(null)
  const refDCAge = useRef<DropdownCheckbox>(null)
  const refDCCategories = useRef<DropdownCheckbox>(null)
  const refDCSocials = useRef<DropdownCheckbox>(null)
  const refDCFollowers = useRef<DropdownCheckbox>(null)
  const refDCLanguages = useRef<DropdownCheckbox>(null)
  const refDCCountries = useRef<DropdownCheckbox>(null)
  const DCFiltersRefs = [
    refDCVogzterType,
    refDCRegion,
    refDCGender,
    refDCAge,
    refDCCategories,
    refDCSocials,
    refDCFollowers,
    refDCLanguages,
    refDCCountries,
  ]

  const followers = [
    { label: '1K', value: { min: 0, max: 1000 } },
    { label: '5K', value: { min: 1000, max: 5000 } },
    { label: '10K', value: { min: 5000, max: 10000 } },
    { label: '50K', value: { min: 10000, max: 50000 } },
    { label: '100K', value: { min: 50000, max: 100000 } },
    { label: '500K', value: { min: 100000, max: 500000 } },
    { label: '1M', value: { min: 500000, max: 1000000 } },
    { label: '5M', value: { min: 1000000, max: 5000000 } },
    { label: '10M', value: { min: 5000000, max: 10000000 } },
    { label: '+10M', value: { min: 10000000, max: 100000000000 } },
  ]
  const getCategoryOptions = (): DropdownCheckboxOption[] => {
    return (
      userMobx.usedCategories?.data?.map((category: Category) => ({
        id: category._id,
        label: category.name,
        data: category as Category,
        onClick: (selected) =>
          updateFilters((prev) => {
            const newCategories = [...prev.categories]
            const element = newCategories.find(
              (c) => c.data._id === category._id
            )
            if (element) {
              element.selected = selected
            }
            return { ...prev, categories: newCategories }
          }),
      })) ?? []
    )
  }
  let dcCategoryOptions: DropdownCheckboxOption[] = useMemo(
    () => getCategoryOptions(),
    [userMobx.usedCategories.data]
  )
  const { t } = useTranslation()
  const [userLanguage, setUserLanguage] = useState(navigator.language)

  const getLanguagesOptions = (): DropdownCheckboxOption[] => {
    return (
      briefR.languages?.data?.map((language: Language) => ({
        id: language._id ?? language.name,
        label: `${getNameLanguages(
          ['us', 'uk'].indexOf(language.name) === -1 ? language.name : 'en',
          userLanguage.split('-')[0]
        )}`,
        data: language as Language,
        onClick: (isSelected: any) => {
          if (isSelected) {
            // Ajouter la langue aux filtres s'il n'est pas déjà sélectionné
            updateFilters((prev) => ({
              ...prev,
              languages: [...prev.languages, { name: language.name }],
            }))
          } else {
            // Retirer la langue des filtres s'il est déjà sélectionné
            updateFilters((prev) => ({
              ...prev,
              languages: prev.languages.filter(
                (lang) => lang.name !== language.name
              ),
            }))
          }
        },
      })) ?? []
    )
  }
  const getCountriesOptions = (): DropdownCheckboxOption[] => {
    return (
      briefR.countries?.data?.map((country: Country) => ({
        id: country._id ?? country.name,
        label: `${getNameCountries(country.name, userLanguage.split('-')[0])}`,
        data: country as Country,
        onClick: (isSelected: any) => {
          if (isSelected) {
            updateFilters((prev) => ({
              ...prev,
              countries: [...prev.countries, { name: country.name }],
            }))
          } else {
            updateFilters((prev) => ({
              ...prev,
              countries: prev.countries.filter((e) => e.name !== country.name),
            }))
          }
        },
      })) ?? []
    )
  }

  let dcCountryOptions: DropdownCheckboxOption[] = useMemo(
    () => getCountriesOptions(),
    [briefR.countries.data]
  )
  let dcLanguageOptions: DropdownCheckboxOption[] = useMemo(
    () => getLanguagesOptions(),
    [briefR.languages.data]
  )

  type SortingOptionsType = {
    key: number
    text: string
    value:
      | 'favoritedBy'
      | 'vogzCount'
      | 'createdAt(desc)'
      | 'createdAt(asc)'
      | 'collabCount'
    algoliaIndex: typeof algoliaIndex
  }

  const options: SortingOptionsType[] = [
    {
      key: 1,
      text: t('favorite:favorite-vogzters'),
      value: 'favoritedBy',
      algoliaIndex: algoliaIndex,
    },
    {
      key: 2,
      text: t('favorite:most-active-vogzter'),
      value: 'vogzCount',
      algoliaIndex: algoliaIndexVogzCount,
    },
    {
      key: 3,
      text: t('favorite:lastest-vogzter'),
      value: 'createdAt(desc)',
      algoliaIndex: algoliaIndexCreatedDesc,
    },
    {
      key: 4,
      text: t('favorite:oldest-vogzter'),
      value: 'createdAt(asc)',
      algoliaIndex: algoliaIndexCreatedAsc,
    },
    {
      key: 5,
      text: t('favorite:nb-of-collab'),
      value: 'collabCount',
      algoliaIndex: algoliaIndexCollabCount,
    },
  ]
  const [sortType, setSortType] = useState<
    SortingOptionsType['value'] | undefined
  >('favoritedBy')

  const observer = useRef<IntersectionObserver>()
  const globalPageAside = document.getElementById('page-aside')
  const GalerieVogzterMediaModalLoadMoreRef = useRef<
    ElementRef<typeof GalerieVogzterModalLoadMore>
  >(null)

  const lastVogzterRef = useCallback(
    (node) => {
      if (!allowAlgoliaRequest.current) return
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          userMobx.vogzters.data?.hasMore === true
        ) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [userMobx.vogzters.loading]
  )

  const [selectedOptions, setSelectedOptions] = useState<
    DropdownCheckboxOption[]
  >([])
  const selectedOptionsIds = JSON.parse(
    sessionStorage.getItem('selectedFiltersIds') ?? '[]'
  ) as string[]

  const algoliaQuery = (reset?: boolean) => {
    if (!allowAlgoliaRequest.current) return

    if (userMobx.vogzters.data?.hasMore || reset) {
      // empty data because we only update the loading property here
      userMobx.updateVogzters([], page, 0, false, true, true)

      if (filters.age18To25) {
        ageInterval.minAge = 18
        ageInterval.maxAge = 25
      }
      if (filters.age26To35) {
        if (!filters.age18To25) ageInterval.minAge = 26
        ageInterval.maxAge = 35
      }
      if (filters.ageMoreThan35) {
        if (!filters.age18To25 && !filters.age26To35) ageInterval.minAge = 35
        ageInterval.maxAge = 100
      }
      if (
        filters.age18To25 &&
        filters.ageMoreThan35 &&
        filters.age26To35 === false
      ) {
        filters.age26To35 = true
        refDCAge.current?.select('hasBetween-26-35-yo')
      }

      const minLastActivity = new Date()
      minLastActivity.setDate(minLastActivity.getDate() - 4)
      const fActive = filters.active
        ? `lastActivityTimestamp > ${minLastActivity.getTime()}`
        : undefined

      const numericFilters = [
        `age >= ${ageInterval.minAge}`,
        `age <= ${ageInterval.maxAge}`,
        fActive,
      ].filter((f) => f !== undefined) as string[]
      if (filters.certified) numericFilters.push('certified = 1 ')

      const fGender =
        filters.man && filters.woman
          ? ''
          : filters.man
          ? 'gender:male'
          : filters.woman
          ? 'gender:female'
          : ''
      let fFav
      if (userMobx.me.data?._company.type === 'brand') {
        fFav = filters.fav ? `favouritedBy:${userMobx.me.data?.brand?._id}` : ''
      } else {
        if (selectedBrand)
          fFav = filters.fav ? `favouritedBy:${selectedBrand._id}` : ''
      }
      const fSocials = Object.keys(filters.socials).map((key, i) => {
        const value = Object.values(filters.socials)[i]
        return value ? `${key}:true` : ''
      })
      const fCategories = filters.categories
        .filter((category) => category.selected)
        .map((category) => `categories.name:"${category.data.name}"`)
      const fRecommended =
        userMobx.me.data?._company?.type === 'agency'
          ? selectedBrand.categories?.map(
              (category) => `categories.name:"${category.name}"`
            ) || []
          : userMobx.me.data?.brand?.categories?.map(
              (category) => `categories.name:"${category.name}"`
            ) || []

      const fRegion = filters.region ? `region:"${filters.region.label}"` : ''
      let fInstaFollowers
      let fTiktokFollowers
      let fYoutubeFollowers
      let fSnapFollowers
      const fNewSocialsFollowers: string[] = []
      const socialsActif = Object.keys(filters.socials)
        .map((key, i) => {
          const value = Object.values(filters.socials)[i]
          return value ? key : ''
        })
        .filter((social) => social !== '')

      filters.followers?.forEach((follower) => {
        fInstaFollowers =
          filters.followers &&
          (socialsActif.length === 0 || socialsActif.includes('instagramLink'))
            ? `followers.instagram.max >= ${follower.max} `
            : null
        fTiktokFollowers =
          filters.followers &&
          (socialsActif.length === 0 || socialsActif.includes('tiktokLink'))
            ? `followers.tiktok.max >= ${follower.max}`
            : null
        fYoutubeFollowers =
          filters.followers &&
          (socialsActif.length === 0 || socialsActif.includes('youtubeLink'))
            ? `followers.youtube.max >= ${follower.max}`
            : null
        fSnapFollowers =
          filters.followers &&
          (socialsActif.length === 0 || socialsActif.includes('snapchatLink'))
            ? `followers.snapchat.max >= ${follower.max}`
            : null
        if (fInstaFollowers) fNewSocialsFollowers.push(fInstaFollowers)
        if (fTiktokFollowers) fNewSocialsFollowers.push(fTiktokFollowers)
        if (fYoutubeFollowers) fNewSocialsFollowers.push(fYoutubeFollowers)
        if (fSnapFollowers) fNewSocialsFollowers.push(fSnapFollowers)
      })

      const fSocialsFollowers = [...fNewSocialsFollowers].join(' OR ')
      const fBudget = filters.budget
        ? `pricingOfferMin >= ${filters.budget}`
        : ''
      const fLanguages =
        (filters.languages?.length ?? 0) > 0
          ? filters.languages
              ?.map((language) => `languages:${language.name}`)
              ?.join(' OR ')
          : ''
      const fCountries =
        (filters.countries?.length ?? 0) > 0
          ? filters.countries
              .map((country) => `country:${country.name}`)
              ?.join(' OR ')
          : ''

      const requestfilters = buildRequestFilters(
        'AND',
        fGender,
        filters.recommended ? buildRequestFilters('OR', ...fRecommended) : '',
        fFav,
        ...fSocials,
        ...fCategories,
        fRegion,
        fSocialsFollowers,
        fBudget,
        fLanguages,
        fCountries
      )
      const curAlgoliaIndex =
        options.find((o) => o.value === sortType)?.algoliaIndex ?? algoliaIndex

      const triggerMainSearch = (firstHits?: VogzterSearchResult[]) => {
        curAlgoliaIndex
          .search(search, {
            filters: requestfilters,
            page: page - 1,
            numericFilters: numericFilters,
            hitsPerPage: page === 1 ? FIRST_PAGE_LIMIT : PAGE_LIMIT,
          })
          .then(({ hits, nbHits }) => {
            let filteredHits = hits as VogzterSearchResult[]
            if (firstHits && firstHits.length > 0) {
              filteredHits = (hits as VogzterSearchResult[]).filter(
                (v) =>
                  firstHits?.find((v2) => v2.objectID === v.objectID) ===
                  undefined
              )
            } else {
              firstHits = []
            }
            userMobx.updateVogzters(
              [...firstHits, ...filteredHits],
              page,
              nbHits,
              (userMobx.vogzters.data?.vogzters?.length ?? 0) < nbHits
            )
          })
      }

      if (sortType === 'favoritedBy') {
        const tmp = `favouritedBy:${userMobx.me.data?.brand?._id}`
        curAlgoliaIndex
          .search(search, {
            filters: requestfilters.concat(
              `${requestfilters && !filters.fav ? ' AND' : ''} ${
                filters.fav ? '' : tmp
              }`
            ),
            page: page - 1,
            numericFilters: numericFilters,
            hitsPerPage: page === 1 ? FIRST_PAGE_LIMIT : PAGE_LIMIT,
          })
          .then(({ hits }) => {
            triggerMainSearch(hits as VogzterSearchResult[])
          })
        return
      }
      triggerMainSearch()
    }
  }

  // Debounce algoliaQuery to avoid sending too many requests
  const debouncedAlgoliaQuery = debounce(algoliaQuery, 200)

  const addOrReplaceURLParam = (param: string, value: string) => {
    const url = new URL(window.location.href)
    url.searchParams.set(param, value)
    window.history.pushState({}, '', url.toString())
  }

  const deleteURLParam = (param: string) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.pushState({}, '', url.toString())
  }

  const onDropdownElementSelected = (selected: DropdownCheckboxOption) => {
    addOrReplaceURLParam(selected.id, '1')
    setSelectedOptions((prev) => {
      const newValue = [...new Set([...prev, selected])]
      sessionStorage.setItem(
        'selectedFiltersIds',
        JSON.stringify(newValue.map((o) => o.id))
      )
      return newValue
    })
  }

  const onDropdownElementRemoved = (removed: DropdownCheckboxOption) => {
    deleteURLParam(removed.id)
    setSelectedOptions((prev) => {
      const newValue = prev.filter((o) => o.id !== removed.id)
      sessionStorage.setItem(
        'selectedFiltersIds',
        JSON.stringify(newValue.map((o) => o.id))
      )
      return newValue
    })
  }

  const dropdownClassnames: DropdownCheckboxClassNames = {
    menu: '!relative !mt-0',
    menuHidden: '!w-0 !h-0',
  }

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    // Get used categories from the API to populate the "Catégorie" filter options
    userMobx.getUsedCategories((usedCategories) => {
      if (!usedCategories) return

      dcCategoryOptions = getCategoryOptions()

      // Get default selected filters from URL and apply them
      // This code is inside the callback to make sure the categories are loaded before applying the filters
      const selectedOptions: DropdownCheckboxOption[] = []
      const urlParamsKeys = Array.from(
        new URL(window.location.href).searchParams.keys()
      )
      if (urlParamsKeys.length > 0) {
        for (let i = 0; i < urlParamsKeys.length; i += 1) {
          const key = urlParamsKeys[i]
          const refDCFilter = DCFiltersRefs.find((dc) =>
            dc.current?.containsId(key)
          )
          const filter = refDCFilter?.current?.getOption(key)
          if (
            refDCFilter &&
            filter &&
            !selectedOptions.find((o) => o.id === key)
          ) {
            sessionStorage.removeItem('selectedFiltersIds')
            selectedOptions.push(filter)
            refDCFilter.current?.select(key)
          }
        }
      } else {
        selectedOptionsIds.forEach((id) => {
          const refDCFilter = DCFiltersRefs.find((dc) =>
            dc.current?.containsId(id)
          )
          const option = refDCFilter?.current?.getOption(id)
          if (refDCFilter && option) {
            if (!selectedOptions.find((o) => o.id === id)) {
              selectedOptions.push(option)
              refDCFilter.current?.select(id)
              addOrReplaceURLParam(id, '1')
            }
          }
        })
      }
      setSelectedOptions(selectedOptions)

      // Once all the computations are done, we allow algolia HTTP requests.
      // If we don't do this, unnecessary requests will be sent almost at the same time
      // which also causes algolia to return wrong results.
      allowAlgoliaRequest.current = true

      // Triggers the useEffect() hook to launch the first algolia query
      updateFilters((prev) => ({
        ...prev,
        categories:
          usedCategories.map((category: Category) => ({
            data: category,
            selected: false,
          })) ?? [],
      }))
    })
    briefR.getAllCountries(() => {
      dcCountryOptions = getCountriesOptions()
    })
    briefR.getAllLanguages(() => {
      dcLanguageOptions = getLanguagesOptions()
    })
    setUserLanguage(navigator.language)
  }, [])

  useEffect(() => {
    debouncedAlgoliaQuery()
  }, [page])

  // Resets vogzter list only if search, filters or sortType state changes
  useEffect(() => {
    if (!allowAlgoliaRequest.current) return
    if (observer.current) observer.current.disconnect()
    if (globalPageAside) {
      globalPageAside.scrollTo(0, 0)
    }
    setPage(1)
    debouncedAlgoliaQuery(true)
  }, [search, filters, sortType])

  const [isShowPayment, setIsShowPayment] = useState(false)
  const handleLoadMore = () => {
    if (!isBaseCreatorActivated) {
      setIsShowPayment(true)
    } else {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const { card } = useParams<{ card: 'ok' | 'ko' }>()

  useEffect(() => {
    if (card === 'ok') {
      toast.success(t('register-process:payment-success'), { theme: 'colored' })
    } else if (card === 'ko') {
      toast.error(t('register-process:payment-error'), { theme: 'colored' })
    }
  }, [card])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Vogzter</title>
      </Helmet>
      <main className={s['favorites-page']}>
        {!isShowPayment ? (
          <div>
            {userMobx.me.data?._company?.type === TypeClient.AGENCY && (
              <div className="z-[99] h-24">
                <BrandSwitcher
                  className={`${s['brand-switcher']} z-40`}
                  classNames={{
                    brandList: '!right-0',
                  }}
                  dntComesFromBriefList
                />
              </div>
            )}
            <ScrollToTop />
            <div className={s['all-body']}>
              <div className={`${s['header']} ${s['p-horizontal']}`}>
                <div className="flex w-full justify-between flex-wrap">
                  <Title1
                    text={t('favorite:title')}
                    style={{ textTransform: 'uppercase' }}
                    size={33}
                  />
                  <div className={s['search-bar-wrapper']}>
                    <SearchBar
                      inputClass={s['search-bar']}
                      maxLength={20}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="flex flex-wrap w-full mt-6">
                  <div
                    className={`${s['filter-container']} scroll-container-x`}
                  >
                    {/* 
                  Every option from each DropdownCheckbox must have a unique ID.
                  It enables the DeletableList component to retrieve the removed filter,
                  and to add default selected filters via the URL 
                */}
                    <DropdownCheckbox
                      ref={refDCVogzterType}
                      multiSelection
                      classNames={dropdownClassnames}
                      placeholder={t('favorite:vogzter-type')}
                      options={[
                        // TODO: Not implemented yet { id: 'is-recommanded', label: t('favorite:filters:recommanded-vogzter'), },
                        {
                          id: 'is-favorite',
                          label: 'favorite:filters:favorites',
                          onClick: (selected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              fav: selected,
                            })),
                        },
                        {
                          id: 'is-certified',
                          label: 'favorite:filters:certified',
                          onClick: (selected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              certified: selected,
                            })),
                        },
                        {
                          id: 'is-active',
                          label: 'favorite:filters:active',
                          onClick: (selected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              active: selected,
                            })),
                        },
                        {
                          id: 'is-recommended',
                          label: 'favorite:filters:recommended',
                          onClick: (selected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              recommended: selected,
                            })),
                        },
                      ]}
                      onElementSelected={onDropdownElementSelected}
                      onElementRemoved={onDropdownElementRemoved}
                    />
                    <DropdownCheckbox
                      ref={refDCRegion}
                      classNames={dropdownClassnames}
                      placeholder={t('favorite:region')}
                      isSearchable
                      searchPlaceholder={t('favorite:search-region')}
                      options={[
                        {
                          id: 'auvergne-rhone-alpes',
                          label: 'Auvergne-Rhône-Alpes',
                        },
                        {
                          id: 'bourgogne-franche-comte',
                          label: 'Bourgogne-Franche-Comté',
                        },
                        { id: 'bretagne', label: 'Bretagne' },
                        {
                          id: 'centre-val-de-loire',
                          label: 'Centre-Val de Loire',
                        },
                        { id: 'corse', label: 'Corse' },
                        { id: 'grand-est', label: 'Grand Est' },
                        { id: 'hauts-de-france', label: 'Hauts-de-France' },
                        { id: 'ile-de-france', label: 'Île-de-France' },
                        { id: 'normandie', label: 'Normandie' },
                        {
                          id: 'nouvelle-aquitaine',
                          label: 'Nouvelle-Aquitaine',
                        },
                        { id: 'occitanie', label: 'Occitanie' },
                        { id: 'pays-de-la-loire', label: 'Pays de la Loire' },
                        {
                          id: 'provence-alpes-cote-d-azur',
                          label: "Provence-Alpes-Côte d'Azur",
                        },
                      ]}
                      onElementSelected={(selectedItem) => {
                        if (filters.region) {
                          onDropdownElementRemoved(filters.region)
                        }
                        updateFilters((prev) => ({
                          ...prev,
                          region: selectedItem,
                        }))
                        onDropdownElementSelected(selectedItem)
                      }}
                      onElementRemoved={(removedItem) => {
                        onDropdownElementRemoved(removedItem)
                        updateFilters((prev) => ({
                          ...prev,
                          region: undefined,
                        }))
                      }}
                    />
                    <DropdownCheckbox
                      ref={refDCGender}
                      multiSelection
                      classNames={dropdownClassnames}
                      placeholder={t('favorite:gender')}
                      options={[
                        {
                          id: 'isMan',
                          label: 'favorite:filters:man',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              man: isSelected,
                            })),
                        },
                        {
                          id: 'isWoman',
                          label: 'favorite:filters:woman',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              woman: isSelected,
                            })),
                        },
                      ]}
                      onElementSelected={onDropdownElementSelected}
                      onElementRemoved={onDropdownElementRemoved}
                    />
                    <DropdownCheckbox
                      ref={refDCAge}
                      multiSelection
                      classNames={dropdownClassnames}
                      placeholder={t('favorite:age')}
                      options={[
                        {
                          id: 'hasBetween-18-25-yo',
                          label: 'favorite:filters:age-between-18-25',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              age18To25: isSelected,
                            })),
                        },
                        {
                          id: 'hasBetween-26-35-yo',
                          label: 'favorite:filters:age-between-26-35',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              age26To35: isSelected,
                            })),
                        },
                        {
                          id: 'hasMoreThan-35-yo',
                          label: 'favorite:filters:age-more-than-35',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              ageMoreThan35: isSelected,
                            })),
                        },
                      ]}
                      onElementSelected={onDropdownElementSelected}
                      onElementRemoved={onDropdownElementRemoved}
                    />
                    {dcCategoryOptions.length > 0 && (
                      <DropdownCheckbox
                        ref={refDCCategories}
                        multiSelection
                        classNames={dropdownClassnames}
                        placeholder={t('favorite:category')}
                        options={dcCategoryOptions}
                        onElementSelected={onDropdownElementSelected}
                        onElementRemoved={onDropdownElementRemoved}
                      />
                    )}
                    {dcCountryOptions.length > 0 && (
                      <DropdownCheckbox
                        ref={refDCCountries}
                        multiSelection
                        classNames={dropdownClassnames}
                        placeholder={t('favorite:country.title')}
                        options={dcCountryOptions}
                        onElementSelected={onDropdownElementSelected}
                        onElementRemoved={onDropdownElementRemoved}
                      />
                    )}
                    {dcLanguageOptions.length > 0 && (
                      <DropdownCheckbox
                        ref={refDCLanguages}
                        multiSelection
                        classNames={dropdownClassnames}
                        placeholder={t('favorite:languages.title')}
                        options={dcLanguageOptions}
                        onElementSelected={onDropdownElementSelected}
                        onElementRemoved={onDropdownElementRemoved}
                      />
                    )}
                    <DropdownCheckbox
                      ref={refDCSocials}
                      multiSelection
                      classNames={dropdownClassnames}
                      placeholder={t('favorite:socials')}
                      options={[
                        {
                          id: 'tiktok',
                          label: 'Tiktok',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              socials: {
                                ...prev.socials,
                                tiktokLink: isSelected,
                              },
                            })),
                        },
                        {
                          id: 'instagram',
                          label: 'Instagram',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              socials: {
                                ...prev.socials,
                                instagramLink: isSelected,
                              },
                            })),
                        },
                        {
                          id: 'snapchat',
                          label: 'Snapchat',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              socials: {
                                ...prev.socials,
                                snapchatLink: isSelected,
                              },
                            })),
                        },
                        {
                          id: 'youtube',
                          label: 'Youtube',
                          onClick: (isSelected) =>
                            updateFilters((prev) => ({
                              ...prev,
                              socials: {
                                ...prev.socials,
                                youtubeLink: isSelected,
                              },
                            })),
                        },
                      ]}
                      onElementSelected={onDropdownElementSelected}
                      onElementRemoved={onDropdownElementRemoved}
                    />
                    <DropdownCheckbox
                      ref={refDCFollowers}
                      multiSelection
                      classNames={dropdownClassnames}
                      placeholder={t('favorite:followers')}
                      options={followers.map((follower) => ({
                        id: follower.label,
                        label: follower.label,
                        onClick: (isSelected) => {
                          if (isSelected) {
                            updateFilters((prev) => ({
                              ...prev,
                              followers: [...prev.followers, follower.value],
                            }))
                          } else {
                            // delete nb follower in list of filters
                            updateFilters((prev) => ({
                              ...prev,
                              followers: prev.followers.filter(
                                (f) =>
                                  JSON.stringify(f) !==
                                  JSON.stringify(follower.value)
                              ),
                            }))
                          }
                        },
                      }))}
                      onElementSelected={onDropdownElementSelected}
                      onElementRemoved={onDropdownElementRemoved}
                    />
                    <div className={s['input-filter']}>
                      <TextField
                        placeholder={t('favorite:budget')}
                        type="number"
                        min={80}
                        onChange={(e) => {
                          // eslint-disable-next-line radix
                          updateFilters((prev) => ({
                            ...prev,
                            budget: parseInt(e.target.value),
                          }))
                        }}
                        label={''}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center ml-auto">
                    <BtnDrop
                      onSelect={(e) => {
                        setSortType(e as SortingOptionsType['value'])
                      }}
                      defaultVal={t('mycastings:sort')}
                      options={options}
                    />
                  </div>
                </div>
                <br />
              </div>
              <div className={`flex flex-col w-full ${s['p-horizontal']}`}>
                <hr className="w-full mb-1" />
                <DeletableList
                  list={selectedOptions.map((option) => ({
                    id: option.id,
                    label: option.label,
                    data: option,
                  }))}
                  handleDeletion={(newList, removedId) => {
                    setSelectedOptions(newList)
                    const refDCFilter = DCFiltersRefs.find((dc) =>
                      dc.current?.containsId(removedId)
                    )
                    if (refDCFilter) {
                      refDCFilter.current?.unselect(removedId)
                    }
                  }}
                />
                <b className="flex self-start ml font-hurmeBold text-[#B1B1B1] text-xs mt-2">
                  {userMobx.vogzters.data?.nbHits} profil(s)
                </b>
              </div>
              {userMobx.vogzters.loading && <Spinner />}
              <section className={`${s['section-favourite']}`}>
                <div className={s['div-favourite']}>
                  <AnimatePresence>
                    {userMobx.vogzters.data?.vogzters?.length === 0 &&
                    !userMobx.vogzters.loading ? (
                      <motion.div
                        className="mt-5"
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          type: 'spring',
                          stiffness: 200,
                          damping: 25,
                        }}
                      >
                        <p>{t('favorite:no-vogzter-for-query')}</p>
                      </motion.div>
                    ) : (
                      userMobx.vogzters.data?.vogzters?.map(
                        (vogzter: VogzterSearchResult, i) => (
                          <motion.div
                            // ref={userMobx.vogzters.data?.vogzters?.length === i + 1 ? lastVogzterRef : null}
                            className="m-3 mb-4"
                            layout
                            key={i}
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              type: 'spring',
                              stiffness: 200,
                              damping: 25,
                            }}
                            animate={{ opacity: 1 }}
                          >
                            <VogzterSearchItem vogzter={vogzter} />
                          </motion.div>
                        )
                      )
                    )}
                  </AnimatePresence>
                </div>
              </section>
              {userMobx.vogzters.data?.hasMore && (
                <Button
                  className="sticky z-10 bottom-6 !min-w-0 !w-64 mb-3 !rounded-full"
                  variant="outLineBlue"
                  onclick={handleLoadMore}
                  isLoading={userMobx.vogzters.loading}
                >
                  {t('favorite:load-more-profiles')}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <AccessPlatform
            onBack={() => setIsShowPayment(false)}
            isBaseCreatorDefault
          />
        )}
      </main>
      <GalerieVogzterModalLoadMore ref={GalerieVogzterMediaModalLoadMoreRef} />
    </>
  )
}

export default observer(favouriteVogzter)

const buildRequestFilters = (
  operation: string,
  ...filters: Array<string | undefined>
) => {
  const sanitizedFilters = filters.filter((f) => f !== undefined && f !== '')

  let requestFilters = ''
  sanitizedFilters.forEach((f, i) => {
    if (operation === 'OR') {
      if (i === 0) requestFilters += `(${f}`
      if (i === sanitizedFilters.length - 1) requestFilters += ` ${f})`
    } else {
      requestFilters += f
    }

    if (i < sanitizedFilters.length - 1) {
      requestFilters += ` ${operation} `
    }
  })
  return requestFilters
}
