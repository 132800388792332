/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */
// import/no-named-as-default-member
import Cookies from 'js-cookie'
import { action, configure, makeAutoObservable, observable } from 'mobx'
import { toast } from 'react-toastify'
import { chain } from 'lodash';
import {
  Account,
  Address,
  BrandI,
  ClientI,
  Company2,
  Guest,
  LoginSignupData,
  TypeClient,
  Vogzter,
  Profile as ProfileCl,
  Plan,
  ClientUpdate,
  ProductsBrand,
  PlanOlder,
  AllMessages,
  GroupedMessages,
  Vogzters,
  VogzterSearchResult,
  TypeCompanyRole,
  Category,
  SubscriptionPlan,
  CardPricePROPS,
  Review,
  RatingType,
  Subscription
} from '../types-project/Client'
import { GloBalErrorService } from '../types-project/models/services/Errors'
import { Request, ErrorRequest, ApiError } from '../types-project/Request'
import api, { Endpoint, EndT } from '../utils/apiUtils'
import i18n from '../utils/i18n'
import { ApplicantBa, ErrorPublication, MediaBA, MyCasting, Offer } from '../types-project/Brief'
import { CustomProductBrand } from '../pages/Castings/NewCasting/subscreens/Steps/ProductPicker'
import modal from './modal';
// @ts-ignore
import { GalerieVogzterMedia, PublicMedias } from '../types-project/GalerieVogzter';

configure({
  enforceActions: 'never',
})

type BrandL = BrandI & { logo?: string }

let instance: User
const endpoints = new Endpoint()

// TODO create Client class and reorganize the code to seperate the data

export class User {
  constructor() {
    if (instance) {
      makeAutoObservable(this)
      return instance
    }
    instance = this
    makeAutoObservable(this)
  }

  @observable brand: Request<BrandI<Array<Guest>>> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable client: Request<BrandI<Array<Guest>>> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable allBrands: Request<BrandI<Array<Guest>>[]> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable user: Request<LoginSignupData> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable me: Request<ClientI> = {
    loading: true,
    data: null,
    error: null,
  }

  @observable
  vogzter: Request<ApplicantBa> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  publicMedia: Request<PublicMedias> = {
    loading: false,
    data: { medias: [], nbHits: 0, hasMore: false },
    error: null,
  }

  @observable
  vogzters: Request<Vogzters> = {
    loading: false,
    data: { vogzters: [], nbHits: 0, hasMore: false },
    error: null,
  }

  @observable
  favouriteVogzters: Request<Array<Vogzter>> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable
  company: Request<Company2> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  plans: Request<Array<Plan>> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  publicPlans: Request<Array<CardPricePROPS>> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  planOlder: Request<PlanOlder> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  planOlders: Request<PlanOlder[]> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  reqSaveMedia: Request<Company2> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  reqUnsaveMedia: Request<Company2> = {
    loading: false,
    data: null,
    error: null,
  }

  @observable
  messages: Request<Array<GroupedMessages>> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable
  messageUser: Request<Array<AllMessages>> = {
    loading: false,
    data: [],
    error: null,
  }

  @observable brandSession: Omit<
    Request<BrandI<Guest> | undefined>,
    'error'
  > = {
      loading: true,
      data: null,
    }

  @observable
  updateFav: Request<undefined> = {
    loading: false,
    error: null,
    data: undefined,
  }

  @observable
  categories: Request<Category[]> = {
    loading: false,
    error: null,
    data: [],
  }

  @observable
  usedCategories: Request<Category[]> = {
    loading: false,
    error: null,
    data: [],
  }

  @observable
  subscriptionsPlans: Request<SubscriptionPlan | SubscriptionPlan[]> = {
    loading: false,
    error: null,
    data: [],
  }

  @observable
  offer: Request<Offer> = {
    loading: false,
    error: null,
    data: null,
  }

  @observable
  VogzterOffers: Request<Offer[]> = {
    loading: false,
    error: null,
    data: [],
  }

  @observable
  reviews: Request<Review[]> = {
    loading: false,
    error: null,
    data: [],
  }

  @observable
  linkStripe: Request<string> = {
    loading: false,
    error: null,
    data: null,
  }

  @observable
  subscriptionInfo: Request<Subscription> = {
    loading: false,
    error: null,
    data: null,
  }

  @action.bound
  getUserInfos() {
    const userInfos = JSON.parse(
      localStorage.getItem('userInfos') ?? '{}'
    ) as ClientI
    if (Object.keys(userInfos).length > 0)
      return userInfos
    else return null
  }

  @action.bound
  setBrandSession(brand: BrandI<Guest>) {
    localStorage.setItem('brand', JSON.stringify(brand))
    this.brandSession = JSON.parse(localStorage.getItem('brand') ?? '{}')
  }

  @action.bound
  resetBrandSession() {
    localStorage.removeItem('brand')
    this.brandSession.data = undefined
  }

  @action.bound
  setToken(token: string) {
    Cookies.set('token', token, { expires: 30 })
    api.defaults.headers = { Authorization: `bearer ${token}` }
  }

  @action.bound
  getToken() {
    return Cookies.get('token')
  }



  @action.bound
  setUserInfos(user: ClientI) {
    localStorage.setItem('userInfos', JSON.stringify(user))
  }

  @action.bound
  resetToken() {
    const token = this.getToken()
    if (token) {
      this.setToken(token)
    }
  }

  @action.bound
  getBrandSession(): BrandI<any> | undefined {
    return (
      this.brandSession.data ?? JSON.parse(localStorage.getItem('brand') ?? '{}')
    )
  }

  @action.bound
  async getVogzter(id: string) {
    try {
      const userInfos = this.getUserInfos() ?? userMobx.me.data as ClientI
      const { data, status } = await api.get<ApplicantBa | ErrorRequest>(
        endpoints.getEndpoint(userInfos !== null ? EndT.userinfo : EndT.userinfoPublic, id)
      )
      if (status === 200)
        this.vogzter = { data: data as ApplicantBa, loading: false, error: null }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.vogzter = { data: null, loading: false, error: errorMessage }
      toast.error((e as ErrorRequest).message)
    }
  }

  @action.bound
  async updateVogzters(hits: VogzterSearchResult[], page: number, nbHits: number, hasMore: boolean, updateLoadingOnly?: boolean, loading?: boolean,) {

    try {
      if (updateLoadingOnly && loading !== undefined) {
        this.vogzters = {
          ...this.vogzters,
          loading,
        }
        return
      }
      this.vogzters = {
        ...this.vogzters,
        loading: loading ?? false,
        data: {
          vogzters: page === 1 ? hits : [...this.vogzters.data?.vogzters ?? [], ...hits],
          nbHits,
          hasMore
        },
        error: null,
      }
    } catch (e) {
      toast.error((e as ErrorRequest).message)
    }
  }

  @action.bound
  async updatePublicMedia(hits: GalerieVogzterMedia[], page: number, nbHits: number, hasMore: boolean, updateLoadingOnly?: boolean, loading?: boolean,) {
    try {
      if (updateLoadingOnly && loading !== undefined) {
        this.publicMedia = {
          ...this.publicMedia,
          loading,
        }
        return
      }

      this.publicMedia = {
        ...this.publicMedia,
        loading: loading ?? false,
        data: {
          medias: (page === 1) ? hits : [...this.publicMedia.data?.medias ?? [], ...hits],
          nbHits,
          hasMore
        },
        error: null,
      }
    } catch (e) {
      toast.error((e as ErrorRequest).message)
    }
  }

  @action.bound
  async getFavouriteVogzters() {
    try {
      this.favouriteVogzters = {
        ...this.favouriteVogzters,
        loading: true,
        data: [],
        error: null,
      }
      const userInfos = this.getUserInfos() as ClientI
      const brand = this.getBrandSession() as BrandI<Guest>
      const { data, status } = await api.get<Array<Vogzter> | ErrorRequest>(
        endpoints.getEndpoint(
          EndT.favourite,
          userInfos._company.type === TypeClient.BRAND ? (this.me.data?.brand?._id ?? userInfos?.brand?._id) : (brand._id ?? '')
        )
      )
      if (status === 200)
        this.favouriteVogzters = {
          ...this.favouriteVogzters,
          loading: false,
          data: data as Array<Vogzter>,
          error: null,
        }
      else { throw new Error(data as any) }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.favouriteVogzters = {
        ...this.favouriteVogzters,
        loading: false,
        data: [],
        error: errorMessage,
      }
      toast.error((e as ErrorRequest).message)
    }
  }

  @action.bound
  async addFavouriteVogzters(idVogzter: string, showToast?: boolean, callback?: () => void) {
    this.updateFav = { loading: true, error: null, data: undefined }
    try {
      const userInfos = this.getUserInfos() as ClientI
      const brand = this.getBrandSession() as BrandI<Guest>
      const { data, status } = await api.post<BrandI<Guest[]> | ErrorRequest>(
        endpoints
          .getEndpoint(
            EndT.actionFavourite,
            userInfos._company.type === TypeClient.BRAND ? (this.me.data?.brand?._id ?? userInfos?.brand?._id) : (brand._id ?? '')
          )
          .replace(':favouriteId', idVogzter)
      )
      if (status === 200) {
        this.brand = { error: null, data: data as BrandI<Guest[]>, loading: false }
        this.setBrandSession(data as BrandI<Guest[]>)
        this.updateFav = { loading: false, error: null, data: undefined }
        if (callback) callback()
        if (showToast)
          toast.success(i18n.t('favorite:added-confirmation'))
        // console.log(this.company.data)
      } else { throw new Error(data as any) }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(i18n.t(`common:error.${errorMessage}`), {})
      this.updateFav = { loading: false, error: error.message, data: undefined }
    }
  }


  @action.bound
  async deleteFavouriteVogzters(id: string) {
    this.updateFav = { loading: true, error: null, data: undefined }
    try {
      const userInfos = this.getUserInfos() as ClientI
      const brand = this.getBrandSession() as BrandI<Guest>
      const { data, status } = await api.delete<BrandI<Guest[]> | ErrorRequest>(
        endpoints
          .getEndpoint(
            EndT.actionFavourite,
            userInfos._company.type === TypeClient.BRAND ? (this.me.data?.brand?._id ?? userInfos?.brand?._id) : (brand._id ?? '')
          )
          .replace(':favouriteId', id)
      )
      if (status === 200) {
        this.brand = { error: null, data: data as BrandI<Guest[]>, loading: false }
        this.setBrandSession(data as BrandI<Guest[]>)
        this.updateFav = { loading: false, error: null, data: undefined }
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(i18n.t(`common:error.${errorMessage}`), {})
      this.updateFav = { loading: false, error: error.message, data: undefined }
    }
  }

  @action.bound
  async getMe(callBack?: (user: ClientI) => void) {
    try {
      this.me = { ...this.me, loading: true, error: null }
      const brand = this.getBrandSession() as BrandI<Guest>
      const userInfos = this.getUserInfos() as ClientI
      if (userInfos !== null) {
        this.me = {
          ...this.me,
          data: userInfos!,
          loading: false,
          error: null,
        }
        this.company.data = { ...userInfos!._company }
        if (callBack) callBack(userInfos!)
      }
      const { data, status } = await api.get<ClientI>(endpoints.getEndpoint(EndT.me))
      if (status === 200) {
        // if (userInfos._company.type === TypeClient.BRAND)
        //   this.brand.data = { ...data!.brand as BrandI<any> }

        const handleProducts = () => {
          if (data._company.type === 'agency' && brand) {
            return brand.products
          } else {
            return (data.brand)?.products
          }
        }
        const handleAddresses = () => {
          if (data._company.type === 'agency' && brand) {
            return brand.addresses
          } else {
            return (data.brand)?.addresses
          }
        }
        const data_ = { ...data, products: handleProducts(), addresses: handleAddresses() }
        this.setUserInfos(data_)
        this.company.data = { ...data_._company }
        if (userInfos._company.type === TypeClient.AGENCY) {
          this.brand.data = brand as BrandI<any>
        } else {
          this.brand.data = { ...data_!.brand as BrandI<any> }
        }
        this.me = {
          ...this.me,
          data: data_,
          loading: false,
          error: null,
        }
      } else throw new Error('basic')
    } catch (e) {
      const userInfos = this.getUserInfos() as ClientI
      // console.log(e)
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      // if (!this.me.error)
      //   toast.error(i18n.t(`common:errors.${errorMessage}`), {})
      if (userInfos !== null)
        return null;
      else {
        // location.replace('/login')
        this.me = { ...this.me, loading: false, error: errorMessage, data: null }
      }
    }
  }

  @action.bound
  async login(email: string, password: string) {
    try {
      this.user = { ...this.user, loading: true, error: null, data: null }
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.login),
        { email, password }
      )
      if (status === 200) {
        this.setToken(data.token)
        this.setUserInfos(data.client)
        this.me = {
          ...this.me,
          data: data.client as ClientI,
          loading: false,
          error: null,
        }
        this.user = { ...this.user, loading: false, error: null, data }
        return data.client as ClientI
      } else return false
    } catch (e) {
      const error = new GloBalErrorService(e)

      const errorMessage = error.message
      this.user = { ...this.user, loading: false, error: errorMessage }
      toast.error(i18n.t(`signup-login:errors.${errorMessage}`), {})
    }
  }

  @action.bound
  async generatePassword(email: string, callBack?: () => void) {
    try {
      this.user = { ...this.user, loading: true, error: null, data: null }
      // remove existing token
      Cookies.remove('token')
      delete api.defaults.headers.Authorization
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.generatePassword),
        { email }
      )
      if (status === 200) {
        if (callBack) callBack()
        this.user = { ...this.user, loading: false, error: null, data }
        toast.success(i18n.t(`signup-login:create-password_link`))
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.user = { ...this.user, loading: false, error: errorMessage }
      toast.error(i18n.t(`signup-login:errors.${errorMessage}`), {})
    }
  }

  @action.bound
  async setPassword(password: string, callBack?: (data: {
    token: string;
    firstLogin: boolean;
    client: ClientI
  }) => void) {
    try {
      this.user = { ...this.user, loading: true, error: null, data: null }
      const { data, status } = await api.post<{
        token: string;
        firstLogin: boolean;
        client: ClientI
      }>(
        endpoints.getEndpoint(EndT.setPassword),
        { password }
      )
      if (status === 200) {
        toast.success(i18n.t(`signup-login:create-password_confirm`))
        this.setToken(data.token)
        this.setUserInfos(data.client)
        this.me = {
          ...this.me,
          data: data.client as ClientI,
          loading: false,
          error: null,
        }
        // this.user = { ...this.user, loading: false, error: null, data }
        if (callBack) callBack(data)
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.user = { ...this.user, loading: false, error: errorMessage }
      toast.error(i18n.t(`signup-login:errors.${errorMessage}`), {})
    }
  }

  async logout() {
    location.replace('/login')
    Cookies.remove('token')
    Cookies.remove('userInfos')
    sessionStorage.clear()
    localStorage.clear()
  }

  @action.bound
  async register(usr: Account, callBack?: () => void) {
    try {
      this.user = { ...this.user, loading: true, error: null, data: null }
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.newSignup),
        { ...usr }
      )
      if (status === 200) {
        toast.success(i18n.t(`signup-login:signup-confirm`))
        if (callBack) callBack()
        this.me = {
          ...this.me,
          data: data.client as ClientI,
          loading: false,
          error: null,
        }
        this.user = { ...this.user, loading: false, error: null, data }
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.user = { ...this.user, loading: false, error: errorMessage }
      toast.error(i18n.t(`signup-login:errors.${errorMessage}`), {})
    }
  }

  async validateOtp(otp: number, callBack?: () => void): Promise<ClientI | boolean> {
    try {
      this.user.error = null
      const userData = { ...this.user.data, conditions: true }
      delete userData.otpSent
      const { data, status } = await api.post<{
        token: string
        client: ClientI
      }>(endpoints.getEndpoint(EndT.validateOtp), {
        ...userData,
        otp,
      })
      if (status === 200) {
        this.setToken(data.token)
        this.setUserInfos(data.client)
        this.me = {
          ...this.me,
          data: data.client as ClientI,
          loading: false,
          error: null,
        }
        if (callBack) callBack!()
        return data.client as ClientI
      } else return false
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.user.error = errorMessage
      toast.error(i18n.t(`signup-login:errors.${errorMessage}`), {})
      return false
    }
  }

  async addProduct(product: CustomProductBrand, callBack?: () => void) {
    // getting the brand infos from the local storage
    const userInfos = this.getUserInfos() as ClientI
    let brand: BrandI;
    if (userInfos !== null) {
      // if the user is an agency, we get the brand from the session storage
      if (userInfos._company.type === 'agency') {
        brand = this.getBrandSession() as BrandI;
      } else {
        // if the user is a brand, we keep the brand we got from the local storage
        brand = userInfos.brand!;
      }
    }
    if (brand!) {
      try {
        const formData = new FormData()
        if (product.images.length > 0) {
          for (let index = 0; index < product.images.length; index++) {
            const element = product.images[index];
            formData.append('images', element as File)
          }
        }
        for (const key in product) {
          if (product[key as keyof CustomProductBrand] !== undefined) {
            formData.append(key, (product)[key as keyof ProductsBrand] as (keyof typeof product))
          }
        }

        const { data, status } = await api.post<ProductsBrand>(endpoints.getEndpoint(EndT.addProduct, brand._id), formData)
        if (status === 200 && this.me.data) {
          this.me = { ... this.me, data: { ...this.me.data, products: this.me.data?.products?.concat(data) } }
          if (this.me.data)
            this.setUserInfos(this.me.data)
          if (callBack) callBack()
        }
      } catch (e) {
        const error = new GloBalErrorService(e)
        const errorMessage = error.message
        toast.error(errorMessage, {})
      }
    }
  }

  @action.bound
  async deleteProduct(productId: string | undefined) {
    // getting the brand infos from the local storage
    const userInfos = this.getUserInfos() as ClientI
    let brand: BrandI;
    if (userInfos !== null) {
      // if the user is an agency, we get the brand from the local storage
      if (userInfos._company.type === 'agency') {
        brand = this.getBrandSession() as BrandI;
      } else {
        // if the user is a brand, we keep the brand we got from the local storage
        brand = userInfos.brand!;
      }
    }
    if (brand!) {
      try {
        const { data, status } = await api.delete<ProductsBrand>(endpoints.getEndpoint(EndT.deleteProduct, brand._id).replace(':productId', productId ?? ''))
        if (status === 200 && this.me.data) {
          this.me = { ... this.me, data: { ...this.me.data, products: this.me.data?.products?.filter((product) => product._id !== productId) } }
          if (this.me.data)
            this.setUserInfos(this.me.data)
          toast.success(i18n.t('myprofil:brand-page.prod.product-deleted'))
        }
      } catch (e) {
        const error = new GloBalErrorService(e)
        const errorMessage = error.message
        toast.error(errorMessage, {})
      }
    }
  }

  @action.bound
  async addAddress(address: Address, callBack?: () => void) {
    // getting the brand infos from the local storage
    const userInfos = this.getUserInfos() as ClientI
    let brand: BrandI;
    if (userInfos !== null) {
      // if the user is an agency, we get the brand from the session storage
      if (userInfos._company.type === 'agency') {
        brand = this.getBrandSession() as BrandI;
      } else {
        // if the user is a brand, we keep the brand we got from the local storage
        brand = userInfos.brand!;
      }
    }
    if (brand!) {
      try {
        const formData = new FormData()
        if (address.images.length > 0) {
          for (let index = 0; index < address.images.length; index++) {
            const element = address.images[index];
            formData.append('images', element as File)
          }
        }
        for (const key in address) {
          if (address[key as keyof Address] !== undefined && key !== 'coordinates') {
            formData.append(key, (address)[key as keyof Address] as (keyof typeof address))
          }
        }
        formData.append('coordinates', JSON.stringify(address.coordinates))

        const { data, status } = await api.post<Address>(endpoints.getEndpoint(EndT.addAddress, brand._id), formData)
        if (status === 200 && this.me.data) {
          this.me = { ... this.me, data: { ...this.me.data, addresses: this.me.data?.addresses?.concat(data) } }
          if (this.me.data)
            this.setUserInfos(this.me.data)
          if (callBack) callBack()
        }
      } catch (e) {
        const error = new GloBalErrorService(e)
        const errorMessage = error.message
        toast.error(errorMessage, {})
      }
    }
  }

  @action.bound
  async deleteAddress(addressId: string | undefined) {
    // getting the brand infos from the local storage
    const userInfos = this.getUserInfos() as ClientI
    let brand: BrandI;
    if (userInfos !== null) {
      // if the user is an agency, we get the brand from the session storage
      if (userInfos._company.type === 'agency') {
        brand = this.getBrandSession() as BrandI;
      } else {
        // if the user is a brand, we keep the brand we got from the local storage
        brand = userInfos.brand!;
      }
    }
    if (brand!) {
      try {
        const { data, status } = await api.delete<Address>(endpoints.getEndpoint(EndT.deleteAddress, brand._id).replace(':addressId', addressId ?? ''))
        if (status === 200 && this.me.data?.addresses) {
          this.me = { ... this.me, data: { ...this.me.data, addresses: this.me.data.addresses.filter((address) => address._id !== addressId) } }
          if (this.me.data)
            this.setUserInfos(this.me.data)
          toast.success(i18n.t('myprofil:brand-page.addresses.address-deleted'))
        }
      } catch (e) {
        const error = new GloBalErrorService(e)
        const errorMessage = error.message
        toast.error(errorMessage, {})
      }
    }
  }

  @action.bound
  async createBrand(brand: BrandI<Array<string>>, callBack?: () => void) {
    try {
      this.client = { ...this.client, loading: true, error: null, data: null }
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.brands, this.me.data?._company._id),
        { ...brand }
      )
      if (status === 200) {
        // eslint-disable-next-line no-restricted-globals
        sessionStorage.setItem('newBrand', JSON.stringify(data))
        location.href = '/dashboard/profile/brand'
        this.client = { ...this.client, loading: false, error: null, data }
        callBack!()
      }
    } catch (e) {
      const error = e as ApiError<any>
      const errorMessage = error.response?.data.message ?? error.message
      const textToShow =
        errorMessage === 'some-email-already-registered'
          ? "L'un des emails à déjà enregistré."
          : errorMessage
      toast.error(textToShow, { theme: 'colored' })
    }
  }

  @action.bound
  async updateGuestBrand(
    idBrand: string,
    brand: BrandI<Array<string>>,
    callBack?: () => void
  ) {
    const userInfos = this.getUserInfos()
    try {
      const { data, status } = await api.put<BrandI<Guest[]>>(
        endpoints.getEndpoint(EndT.actionBrands, idBrand),
        { ...brand }
      )
      if (status === 200) {
        if (callBack) await this.getListGuestsFromBrand(idBrand)
        const dataGuests = this.brand.data?.guests
        // @ts-ignore
        this.brand = {
          ...this.brand,
          loading: false,
          error: null,
          data: {
            ...(data as BrandI),
            guests: dataGuests!,
          },
        }
        if (callBack) {
          toast.success('La marque à été mise à jour', { theme: 'colored' })
          callBack!()
        } else {
          if ((userInfos as ClientI)._company.type === 'agency') {
            this.setBrandSession(data as BrandI<Guest[]>)
          } else {
            localStorage.setItem(
              'userInfos',
              JSON.stringify({ ...userInfos, brand: data as BrandI })
            )
          }
        }
      }
    } catch (e) {
      const error = e as ApiError<any>
      const errorMessage = error.response?.data.message ?? error.message
      const textToShow =
        errorMessage === 'some-email-already-registered'
          ? "L'un des emails à déjà enregistré."
          : errorMessage
      toast.error(textToShow, { theme: 'colored' })
    }
  }


  // Call to update brand
  @action.bound
  async updateBrand(idBrand: string, brand: BrandI<any>, callBack?: () => void) {
    try {
      this.brand = {
        ...this.brand,
        loading: true,
        error: null,
      }
      const brand_ = { ...brand, info: { ...brand.info, social_links: brand.info?.social_links?.filter((link: any) => link.url !== '') } }
      const { data, status } = await api.put<BrandI>(
        endpoints.getEndpoint(EndT.actionBrands, idBrand),
        { ...brand_ }
      )
      const userInfos = this.getUserInfos() as ClientI

      if (status === 200) {
        if (userInfos !== null && userInfos._company.type === TypeClient.AGENCY) {
          this.setBrandSession(data as BrandI<Guest[]>)
        } else {
          this.setUserInfos({ ...userInfos, brand: data as BrandI })
        }
        // @ts-ignore
        this.brand = {
          ...this.brand,
          loading: false,
          error: null,
        }
        if (callBack)
          callBack!()
        else
          toast.success('La marque à été mise à jour', { theme: 'colored' })
      }
    } catch (e) {
      const error = e as ApiError<any>
      const errorMessage = error.response?.data.message ?? error.message
      toast.error(errorMessage, { theme: 'colored' })
    }
  }

  @action.bound
  async updateBrandLogo(
    idBrand: string,
    logo?: File | string | FormData,
    callBack?: () => void
  ) {
    const userInfos = this.getUserInfos()
    const formData = new FormData()
    if (logo instanceof File) {
      formData.append('logo', logo)
    }
    try {
      const { data, status } = await api.put<BrandI<Guest[]>>(
        endpoints.getEndpoint(EndT.actionBrands, idBrand),
        formData
      )
      if (status === 200) {
        // @ts-ignore
        this.brand = {
          ...this.brand,
          loading: false,
          error: null,
          data: {
            ...data,
          },
        }
        toast.success('La marque à été mise à jour', { theme: 'colored' })
        if (callBack) {
          callBack!()
        } else {
          if (userInfos !== null && (userInfos as ClientI)._company.type === 'agency') {
            this.setBrandSession(data as BrandI<Guest[]>)
          } else {
            localStorage.setItem(
              'userInfos',
              JSON.stringify({ ...userInfos, brand: data as BrandI })
            )
          }
        }
      }
    } catch (e) {
      const error = e as ApiError<any>
      const errorMessage = error.response?.data.message ?? error.message
      toast.error(errorMessage, { theme: 'colored' })
    }
  }

  @action.bound
  async getBrands(callBack?: (data: BrandI<Array<Guest>>[]) => void) {
    try {
      // @ts-ignore
      this.clientsAll = {
        ...this.allBrands,
        loading: true,
        error: null,
        data: [],
      }
      const userInfos = this.getUserInfos() as ClientI
      if (
        userInfos?.company_role !== TypeCompanyRole.GUEST &&
        (this.me.data?._company.type ?? userInfos!?._company.type) ===
        TypeClient.AGENCY
      ) {
        const { data, status } = await api.get(
          endpoints.getEndpoint(
            EndT.brands,
            this.me.data?._company?._id ?? userInfos!?._company!?._id
          )
        )
        if (status === 200)
          if (callBack) callBack(data)
        this.allBrands = {
          ...this.allBrands,
          loading: false,
          error: null,
          data,
        }
      }
    } catch (e) {
      const error = e as ApiError<any>
      const errorMessage = error.response?.data?.message ?? error.message
      toast.error(errorMessage, { theme: 'colored' })
      this.allBrands = {
        ...this.allBrands,
        loading: false,
        error: errorMessage,
        data: null,
      }
    }
  }

  @action.bound
  async getListGuestsFromBrand(idBrand: string) {
    try {
      const { data, status } = await api.get<Array<Guest> | ErrorRequest>(
        endpoints.getEndpoint(EndT.guestList, idBrand)
      )
      if (status === 200) {
        this.brand.data = {
          ...(this.brand.data as BrandI<Array<Guest>>),
          guests: data as Array<Guest>,
        }
        // @ts-ignore
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      this.brand = { ...this.brand, loading: false, error: null, data: null }
      // eslint-disable-next-line no-console
      // console.log(e)
    }
  }

  @action.bound
  async getBrand(idBrand: string) {
    try {
      this.brand = { ...this.brand, loading: true, error: null, data: null }
      const { data, status } = await api.get<BrandI | ErrorRequest>(
        endpoints.getEndpoint(EndT.actionBrands, idBrand)
      )
      if (status === 200) {
        await this.getListGuestsFromBrand(idBrand)
        const dataGuests = this.brand.data?.guests
        // @ts-ignore
        this.brand = {
          ...this.brand,
          loading: false,
          error: null,
          data: {
            ...(data as BrandI),
            guests: dataGuests!,
          },
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      this.brand = { ...this.brand, loading: false, error: null, data: null }
      // eslint-disable-next-line no-console
      // console.log(e)
    }
  }

  @action.bound
  resetBrand() {
    this.brand = { ...this.brand, loading: false, error: null, data: null }
  }

  @action.bound
  selectBrand(brand: BrandI<any>) {
    this.brand = { ...this.brand, loading: false, error: null, data: brand }
  }

  @action.bound
  async deleteBrandGuest(idBrand: string, idGuest: string) {
    try {
      const { data, status } = await api.delete<BrandI | ErrorRequest>(
        endpoints
          .getEndpoint(EndT.deleteBrands, idBrand)
          .replace(':guestId', idGuest)
      )
      if (status === 200) {
        this.brand.data!.guests = this.brand.data!.guests?.filter(
          (guest: { _id: string }) => guest._id !== idGuest
        )
        toast.success("L'invité à bien été retiré.", { theme: 'colored' })
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      // console.log(e)
    }
  }

  @action.bound
  async updateClient(updateClient: ClientUpdate, callBack?: () => void) {
    try {
      const { data, status } = await api.patch<ClientI>(
        endpoints.getEndpoint(EndT.me),
        { updateClient }
      )
      if (status === 200) {
        this.me = {
          ...this.me,
          data: data,
          loading: false,
          error: null,
        }
        Cookies.set('userInfos', JSON.stringify(data), { secure: true })
        if (callBack) callBack!()
      }
    } catch (e) {
      console.log(e)
    }
  }

  @action.bound
  async updateCompany(logo?: File | string | FormData, callBack?: (data: Company2) => void) {
    try {
      const formData = new FormData()
      if (logo instanceof File) {
        formData.append('logo', logo)
      }
      const userInfos = this.getUserInfos() as ClientI
      const { data, status } = await api.put<Company2>(
        endpoints.getEndpoint(
          EndT.company,
          this.me.data?._company._id ?? userInfos?._company._id
        ),
        formData
      )

      if (status === 200) {
        if (userInfos !== null && userInfos._company.type === TypeClient.AGENCY) {
          this.setBrandSession(data as BrandI<Guest[]>)
        } else {
          this.setUserInfos({ ...userInfos, brand: data as BrandI })
        }
        this.me = {
          ...this.me,
          data: { ...this.me.data, _company: data } as ClientI,
          loading: false,
          error: null,
        }
        Cookies.set('userInfos', JSON.stringify(this.me), { secure: true })
        this.company.data = data
        if (callBack) callBack!(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  @action.bound
  async addStripePaymentMethod(
    onSucess: (stripeUrl: string) => void,

    onFail: () => void
  ) {
    try {
      const { data, status } = await api.get(
        endpoints.getEndpoint(
          EndT.addStripePaymentMethod,
          this.me.data?._company._id
        )
      )
      if (status === 200) onSucess(data.url)
    } catch (e) {
      onFail()
      // console.log(`company role : ${this.me.data?.company_role}`)
      // console.log(e)
    }
  }

  @action.bound
  async getTopUpLink(
    amount: number,
    redirect: {
      ok: string,
      ko: string
    },
    onSuccess: (stripeUrl: string) => void,
    onFail: () => void,
    planId?: string) {
    try {
      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.getTopUpLink, this.me.data?._company._id),
        { amount, planId, redirect }
      )
      if (status === 200) {
        onSuccess(data)
      }
    } catch (e) {
      onFail()
    }
  }

  @action.bound
  async getPlans() {
    try {
      this.plans = { ...this.plans, loading: true, error: null, data: null }
      const { data, status } = await api.get<Array<Plan> | ErrorRequest>(
        endpoints.getEndpoint(EndT.getPlans)
      )
      if (status === 200) {
        // @ts-ignore
        this.plans = {
          ...this.plans,
          loading: false,
          error: null,
          data: data as Plan[],
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      this.plans = { ...this.plans, loading: false, error: null, data: null }
    }
  }

  @action.bound
  async getPublicPlans() {
    try {

      this.publicPlans = { ...this.publicPlans, loading: true, error: null, data: null }
      const userInfos = this.getUserInfos() as ClientI
      const { data, status } = await api.get<Array<CardPricePROPS> | ErrorRequest>(
        endpoints.getEndpoint(userInfos !== null && this.getToken() ? EndT.getSubscriptionsPlans : EndT.getPublicSubscriptionsPlans)
      )
      if (status === 200) {
        // @ts-ignore
        this.publicPlans = {
          ...this.publicPlans,
          loading: false,
          error: null,
          data: data as CardPricePROPS[],
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      this.publicPlans = { ...this.publicPlans, loading: false, error: null, data: null }
    }
  }

  @action.bound
  async getPlanOrders() {
    try {
      const userInfos = JSON.parse(Cookies.get('userInfos') ?? '{}') as ClientI
      this.planOlders = { ...this.planOlders, loading: true, error: null, data: null }
      const { data, status } = await api.get<PlanOlder[] | ErrorRequest>(
        endpoints.getEndpoint(EndT.PlanOlder, this.me.data?._company._id ?? userInfos?._company._id)
      )
      if (status === 200) {
        // @ts-ignore
        this.planOlders = {
          ...this.planOlders,
          loading: false,
          error: null,
          data: data as PlanOlder[],
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      this.planOlders = { ...this.planOlders, loading: false, error: null, data: null }
    }
  }

  hasPaymentMethod() {
    if (
      this.me.data?._company.credits === 0 ||
      this.me.data?._company.credits === undefined
    ) {
      return false
    }
    return true
  }

  @action.bound
  async toggleSaveMedia(mediaId: string) {
    try {
      this.reqSaveMedia = { ...this.reqSaveMedia, loading: true, error: null, data: null }
      if (userMobx.company.data?.media_saved.map((v) => v.media?._id ?? '').includes(mediaId ?? '')) {
        const { data, status } = await api.delete<Company2 | ErrorRequest>(
          endpoints.getEndpoint(EndT.saveMedia, this.me.data?._company._id).replace(':mediaId', mediaId)
        )
        if (status === 200) {
          this.reqSaveMedia = { ...this.reqSaveMedia, loading: false, error: null, data: data as Company2 }
          toast.success(i18n.t("mycastings:not-bookmarked"),
            { type: 'success', theme: 'colored', position: 'top-right', hideProgressBar: false, autoClose: 1500, delay: 0 }
          )
        } else throw new Error((data as ErrorRequest).message)
      } else {
        const { data, status } = await api.post<Company2 | ErrorRequest>(
          endpoints.getEndpoint(EndT.saveMedia, this.me.data?._company._id).replace(':mediaId', mediaId)
        )
        if (status === 200) {
          this.reqSaveMedia = { ...this.reqSaveMedia, loading: false, error: null, data: data as Company2 }
          toast.success(i18n.t("mycastings:bookmarked"),
            { type: 'success', theme: 'colored', position: 'top-right', hideProgressBar: false, autoClose: 1500, delay: 0 }
          )
        } else throw new Error((data as ErrorRequest).message)
      }
      userMobx.company.data = this.reqSaveMedia.data
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(errorMessage, { theme: 'colored' })
      this.reqSaveMedia = { ...this.reqSaveMedia, loading: false, error: errorMessage, data: null }
    }
  }

  @action.bound
  async getAllMsgBrand(callback?: () => void) {

    const userInfos = this.getUserInfos() as ClientI
    const brand = this.getBrandSession() as BrandI
    this.messages = { ...this.messages, data: [], loading: true, error: null }
    try {
      const { data, status } = await api.get<Array<GroupedMessages> | ErrorRequest>(
        endpoints.getEndpoint(EndT.brandMessages, userInfos._company.type === TypeClient.BRAND ? (this.me.data?.brand?._id ?? userInfos?.brand?._id) : (brand._id ?? '')),
      )
      if (status === 200) {
        this.messages = {
          ...this.messages,
          data: data as GroupedMessages[],
          loading: false,
          error: null,
        }
        if (callback) callback()
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.messages = {
        ...this.messages,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getMsgBrandSocket(data: AllMessages) {
    this.messages = {
      ...this.messages,
      data: this.messages.data?.map(group => {
        if (group.user && (group.user?._id === data.user))
          return { ...group, messages: [...group.messages, { ...data, user: group.user }] }
        return group
      }) as GroupedMessages[],
      loading: false,
      error: null,
    }
    if ((this.messageUser.data?.length ?? 0) > 0 && this.messageUser.data !== undefined && (this.messageUser.data?.at(0)?.user as Vogzter)?._id === data.user)
      this.messageUser = {
        ...this.messageUser,
        data: [...this.messageUser.data as AllMessages[], { ...data, user: (this.messageUser.data?.at(0)?.user as Vogzter) }],
        loading: false,
        error: null,
      }
    this.allBrands.data = (this.allBrands.data as BrandI<Guest[]>[]).map(brand => {
      if (brand._id === data.brand)
        return { ...brand, messages_count: (brand.messages_count ?? 0) + 1 }
      return brand
    })
  }

  @action.bound
  async updateMsgBrandSocket(data: string) {
    this.messages = {
      ...this.messages,
      data: this.messages.data?.map(group => {
        if (group.messages.length > 0) {
          group.messages = group.messages.map(msg => {
            if (msg._id === data)
              return { ...msg, read: true }
            return msg
          })
        }
        return group
      }) as GroupedMessages[],
      loading: false,
      error: null,
    }
    if ((this.messageUser.data?.length ?? 0) > 0 && this.messageUser.data !== undefined)
      this.messageUser = {
        ...this.messageUser,
        data: (this.messageUser.data as AllMessages[]).map(msg => {
          if (msg._id === data)
            return { ...msg, read: true }
          return msg
        }),
        loading: false,
        error: null,
      }
  }

  @action.bound
  async getUserMessages(userId: string) {

    const userInfos = this.getUserInfos() as ClientI
    const brand = userMobx.getBrandSession() ?? this.brand.data
    this.messageUser = { ...this.messageUser, data: [], loading: true, error: null }
    try {
      const { data, status } = await api.get<Array<GroupedMessages> | ErrorRequest>(
        endpoints.getEndpoint(EndT.brandMessages, this.me.data?.brand?._id ?? userInfos!?.brand?._id ?? brand?._id),
        { params: { userId } }
      )
      if (status === 200) {
        this.messageUser = {
          ...this.messageUser,
          data: (data as GroupedMessages[])?.at(0)?.messages ?? [],
          loading: false,
          error: null,
        }
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.messageUser = {
        ...this.messageUser,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async readMsgBrand(
    idB: string,
    userId: string,
    isUserMsg?: boolean,
    callback?: () => void
  ) {
    try {
      const { data, status } = await api.patch<Array<AllMessages> | ErrorRequest>(
        endpoints.getEndpoint(EndT.brandMessagesRead, idB),
        { userId }
      )
      if (status === 200) {
        if (isUserMsg) {
          const newArray = this.messageUser.data?.map((msg) => {
            if (['user', 'app'].includes(msg.sender) && msg.read === false) {
              msg.read = true
            }
            return msg
          }) ?? []
          this.messageUser = {
            ...this.messageUser,
            data: newArray,
            loading: false,
            error: null,
          }
        }
        else {
          const messsagesRead = this.messages.data?.filter(group => {
            if (group.user?._id === userId)
              group.messages.filter(message => {
                if (['user', 'app'].includes(message.sender) && message.read === false) {
                  message.read = true
                }
                return message
              })
            return group
          })
          this.messages = {
            ...this.messages,
            data: messsagesRead!,
            loading: false,
            error: null,
          }
        }
        callback?.()
      }


    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.messages = {
        ...this.messages,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async sendMessage(msg: string, userId: string, idBrand: string) {
    try {
      const {
        data,
        status,
      } = await api.post(
        endpoints.getEndpoint(EndT.brandMessages, idBrand),
        { message: msg, userId: userId }
      )
      if (status === 200) {
        this.messages.data?.find(message => message.user?._id === userId)?.messages.push(
          data
        )
        this.messageUser.data?.push(data)
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      const userInfos = this.getUserInfos() as ClientI
      if (errorMessage === 'not-authorised') {
        modal.resetData()
        modal.setTitle(i18n.t('mycastings:profil-vogzter.message-limit'))
        modal.setDesc(`${i18n.t('mycastings:profil-vogzter:message-limit-desc')}`)
        modal.setOnConfirm(() => {
          // ! Il faut repasser sur ça
          if (!userInfos._company.premium)
            location.href = '/dashboard/premium'
          else
            location.href = '/dashboard/credits-vogz'
        })
        modal.toogleAlert('message-restriction')
      } else {
        toast.error(errorMessage, { theme: 'colored' })
      }
    }
    this.messages = { ...this.messages, loading: false, error: null }
  }

  @action.bound
  async getCategories(callback?: (data: Category[]) => void) {
    try {
      const { data, status } = await api.get<Array<Category> | ErrorRequest>(
        endpoints.getEndpoint(EndT.getCategories)
      )
      if (status === 200) {
        this.categories = {
          ...this.categories,
          data: data as Category[],
          loading: false,
          error: null,
        }
        callback?.(data as Category[])
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.categories = {
        ...this.categories,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getUsedCategories(callback?: (usedCategories: Category[] | null) => void) {
    try {
      const { data, status } = await api.get<Array<Category> | ErrorRequest>(
        endpoints.getEndpoint(EndT.getUsedCategories)
      )
      if (status === 200) {
        this.usedCategories = {
          ...this.usedCategories,
          data: data as Category[],
          loading: false,
          error: null,
        }
        if (callback) callback(this.usedCategories.data)
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.usedCategories = {
        ...this.usedCategories,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getOffer(idOffer: string, callback?: (data: Offer) => void) {
    try {
      const { data, status } = await api.get<Offer | ErrorRequest>(
        endpoints.getEndpoint(EndT.getOffer, idOffer)
      )
      if (status === 200) {
        this.offer = {
          ...this.offer,
          data: data as Offer,
          loading: false,
          error: null,
        }
        if (callback) callback(data as Offer)
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.offer = {
        ...this.offer,
        data: null,
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getOffersByVogzter(id: string) {
    try {
      const { data, status } = await api.get(endpoints.getEndpoint(EndT.getOffersByVogzter, id))
      if (status === 200) {
        this.VogzterOffers = {
          ...this.VogzterOffers,
          data: data as Offer[],
          loading: false,
          error: null,
        }
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.VogzterOffers = {
        ...this.VogzterOffers,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getLinkStripe(callback?: (data: string) => void) {
    try {
      const userInfos = JSON.parse(Cookies.get('userInfos') ?? '{}') as ClientI
      const { data, status } = await api.get(endpoints.getEndpoint(EndT.getLinkStripe, this.me.data?._company._id ?? userInfos?._company._id))
      if (status === 200) {
        this.linkStripe = {
          ...this.linkStripe,
          data: data as string,
          loading: false,
          error: null,
        }
        if (callback) callback(data as string)
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.linkStripe = {
        ...this.linkStripe,
        data: '',
        loading: false,
        error: errorMessage,
      }
    }
  }


  @action.bound
  async goPremium(subscriptionId: string, parentCode?: string, callBack?: (data: string) => void) {
    try {
      this.company.loading = true;
      const userInfos = this.getUserInfos() as ClientI
      const { data, status } = await api.post<Company2 | ErrorRequest>(
        endpoints.getEndpoint(EndT.goPremium, this.me.data?._company._id ?? userInfos?._company._id),
        { subscriptionId, parentCode }
      )
      this.company.loading = false;
      if (status === 200) {
        // @ts-ignore
        if (callBack) callBack!(data)

      } else throw new Error((data as ErrorRequest).message)
    }
    catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(["already-referrer", "no-referrer", "already-premium"].indexOf(errorMessage) > -1 ? i18n.t(`premium:errors.${errorMessage}`) : errorMessage, { theme: 'colored' })
    }
  }

  @action.bound
  async upgradeBaseCreator(subscriptionId: string, callBack?: (data: string) => void) {
    try {
      this.company.loading = true;
      const userInfos = this.getUserInfos() as ClientI
      const { data, status } = await api.post<Company2 | ErrorRequest>(
        endpoints.getEndpoint(EndT.upgradeBaseCreator, this.me.data?._company._id ?? userInfos?._company._id),
        { subscriptionId }
      )
      this.company.loading = false;
      if (status === 200) {
        // @ts-ignore
        if (callBack) callBack!(data)

      } else throw new Error((data as ErrorRequest).message)
    }
    catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      toast.error(["base-creator-activated-already"].indexOf(errorMessage) > -1 ? i18n.t(`premium:errors.${errorMessage}`) : errorMessage, { theme: 'colored' })
    }
  }

  @action.bound
  async getSubscriptionsPlans() {
    try {
      const userInfos = this.getUserInfos() as ClientI


      const { data, status } = await api.get<Array<SubscriptionPlan | SubscriptionPlan[]> | ErrorRequest>(
        endpoints.getEndpoint(userInfos !== null && this.getToken() ? EndT.getSubscriptionsPlans : EndT.getPublicSubscriptionsPlans)
      )
      if (status === 200) {
        this.subscriptionsPlans = {
          ...this.subscriptionsPlans,
          data: data as SubscriptionPlan[],
          loading: false,
          error: null,
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.subscriptionsPlans = {
        ...this.subscriptionsPlans,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async getReviews(userId: string) {
    try {
      const { data, status } = await api.get<Array<Review> | ErrorRequest>(
        endpoints.getEndpoint(EndT.getReviews, userId)
      )
      if (status === 200) {
        this.reviews = {
          ...this.reviews,
          data: data as Review[],
          loading: false,
          error: null,
        }
      } else throw new Error((data as ErrorRequest).message)
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.reviews = {
        ...this.reviews,
        data: [],
        loading: false,
        error: errorMessage,
      }
    }
  }

  @action.bound
  async addReview(userId: string, rating: RatingType, comment: string) {
    try {
      const userInfos = this.getUserInfos() as ClientI
      let brand: BrandI
      if (userInfos._company.type === 'agency') {
        brand = this.getBrandSession() as BrandI
      } else {
        brand = userInfos.brand!
      }

      const { data, status } = await api.post(
        endpoints.getEndpoint(EndT.addReview, userId),
        { brandId: brand._id ?? '', rating, comment }
      )

      if (status === 200) {
        const newReview = data as Review
        const existingReview = this.reviews.data?.find((review) => review._id === newReview._id)

        if (existingReview) {
          this.reviews.data?.map((review) => {
            if (review._id === newReview._id) {
              review.comment = newReview.comment
              review.rating = newReview.rating
            }
            return review
          })
        } else {
          this.reviews.data?.push(newReview)
        }

        this.reviews = { ...this.reviews, loading: false, error: null, data: this.reviews.data }
        toast.success(i18n.t('vogzters:reviews.rate-success'), { theme: 'colored' })
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      if (error.message === 'not-authorized') {
        toast.error(i18n.t('vogzters:reviews.rate-not-authorized'), { theme: 'colored' })
      } else {
        toast.error(i18n.t('vogzters:reviews:rate-fail'), { theme: 'colored' })
      }
      const errorMessage = error.message
      this.reviews = {
        ...this.reviews,
        loading: false,
        error: errorMessage,
      }
    }
  }


  @action.bound
  async getSubscriptionInfo() {
    try {
      const { data, status } = await api.get(endpoints.getEndpoint(EndT.getSubscriptionInfo))
      if (status === 200) {
        this.subscriptionInfo = {
          ...this.subscriptionInfo,
          data: data as Subscription,
          loading: false,
          error: null,
        }
      }
    } catch (e) {
      const error = new GloBalErrorService(e)
      const errorMessage = error.message
      this.subscriptionInfo = {
        ...this.linkStripe,
        data: null,
        loading: false,
        error: errorMessage,
      }
    }
  }


}


const userMobx = new User()
export default userMobx
