import * as React from 'react'
import PropTypes from 'prop-types'

interface IconProps {
  color?: string
}

const HeartIcon: React.FC<IconProps> = ({ color }) => (
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_339_17928)">
      <path
        d="M1.95455 2.04693C-0.652902 4.77546 -0.650134 9.16616 1.95455 11.8971L12.7702 23.2417C13.1639 23.6546 13.8228 23.655 14.217 23.2425C17.8263 19.4652 21.4358 15.6879 25.0451 11.9105C27.6526 9.18197 27.6526 4.78877 25.0451 2.06033C22.4377 -0.668115 18.2398 -0.668472 15.6325 2.06033L13.5066 4.28519L11.3676 2.0464C8.76012 -0.682133 4.56183 -0.681597 1.95455 2.04693Z"
        fill={color}
      />
    </g>
    <defs>
      <filter
        id="filter0_i_339_17928"
        x="0"
        y="-0.999725"
        width="28.0007"
        height="24.5514"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.113725 0 0 0 0 0.407843 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_339_17928"
        />
      </filter>
    </defs>
  </svg>
)

HeartIcon.propTypes = {
  color: PropTypes.string,
}

HeartIcon.defaultProps = {
  color: '#2A42A7',
}

export default HeartIcon
