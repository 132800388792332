import React, { useEffect } from 'react'
import { AnimatePresence, motion, MotionStyle } from 'framer-motion'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Cross as Hamburger } from 'hamburger-react'
import { observer } from 'mobx-react'
import Login from '../pages/Auth/login'
import Register from '../pages/Auth/register'
import Register2 from '../pages/Auth/register2'
import CGVPage from '../pages/CGV/cgv'
import Favorite from '../pages/Favorites-vogzters'
import ProfilFavorite from '../pages/Favorites-vogzters/Profil-vogzters'
import Home from '../pages/Home'
import Messages from '../pages/Castings/Messages'
import MyCastings from '../pages/Castings/MyCastings'
import Brands from '../pages/brands'
import BrandsBrief from '../pages/brands/briefs'
import NewBrand from '../pages/brands/new'
import EditBrand from '../pages/brands/edit'
import { pageVariantsAnimation } from '../utils/constants'
import AdminRoute from './AdminRoute'
import RightPanel from '../utils/components/RightPanel'
import { offOverlay, onOverlay } from '../utils/helpers'
import utilsMobx from '../mobx/utils'
import MyCasting from '../pages/Castings/MyCasting'
import BriefApplication from '../pages/Castings/BriefApplication'
import SettingCasting from '../pages/Castings/NewCasting/subscreens/SettingCasting'
import EditCasting from '../pages/Castings/NewCasting/subscreens/EditCasting'
import NewCasting from '../pages/Castings/NewCasting'
import Summary from '../pages/Castings/NewCasting/subscreens/Summary'
import EndedCasting from '../pages/Castings/NewCasting/subscreens/EndedCasting'
import AddFavourites from '../pages/Castings/AddFavourites'
import userMobx from '../mobx/user'
import { ClientI, TypeClient } from '../types-project/Client'
import PageNotFound from '../pages/Auth/404'
import ExtendCasting from '../pages/Castings/ExtendCasting'
import Purchases from '../pages/Purchases'
import RegisterProcess from '../pages/Auth/RegisterProcess'
import Profile from '../pages/Profile'
import Team from '../pages/Profile/components/Team'
import MyBrand from '../pages/Profile/components/Brand'
import MyProfile from '../pages/Profile/components/SectionProfile'
import CreditVogz from '../pages/Credit-vogz'
import Messaging from '../pages/Messaging'
import NotificationBrief from '../pages/Castings/NewCasting/subscreens/NotificationBrief'
import NewDeal from '../pages/Castings/NewDeal'
import Premium from '../pages/Premium'
import Premium2 from '../pages/Premium2'
import UserOnboard from '../pages/Auth/userOnboard'
import MediaPublic from '../pages/MediaPublic'
import { DashBoard } from '../pages/Dashboard'
// import ExtendCasting from '../pages/Castings/ExtendCasting'

function Router() {
  const location = useLocation()
  const userInfos = userMobx.getUserInfos() as ClientI

  useEffect(() => {
    // if a user is logged in and the first argument in the url is 'profil-vogzter' or 'premium', I redirect the user to the credit page, depending on the type of user or whether the user is already premium.
    if (
      (Object.keys(userMobx.me.data ?? {}).length > 0 || userInfos !== null) &&
      ['profil-vogzter', 'premium'].indexOf(location.pathname.split('/')[1]) >
        -1
    ) {
      if (
        (userMobx.me.data?._company?.type === TypeClient.AGENCY ??
          userInfos?._company.type === TypeClient.AGENCY) &&
        location.pathname.split('/')[1] === 'profil-vogzter'
      ) {
        window.location.href = `/dashboard/brands`
      } else {
        window.location.href = `/dashboard${
          location.pathname + location.search
        }`
      }
    } else if (
      location.pathname === '/dashboard/premium' &&
      userMobx.me.data?._company.premium
    )
      window.location.href = `/dashboard/credits-vogz`
    // check if the user is premium, if not, redirect to the premium page if the user is on the new-castings or credits page
    else if (
      ['/dashboard/credits-vogz'].indexOf(location.pathname) > -1 &&
      !userMobx.me.data?._company.premium
    )
      window.location.href = `/dashboard/premium`
  }, [userMobx.me.data, location.pathname])
  return (
    <div className="container-router" id="container-router">
      <div
        id={'overlay'}
        onClick={() => {
          offOverlay()
          utilsMobx.handleMobile()
        }}
      />
      <Routes>
        <Route path="/" element={<AdminRoute routes={() => <DashBoard />} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register2 />} />
        <Route path="/onboard" element={<UserOnboard />} />
        <Route path="/premium" element={<Premium2 />} />
        <Route path="/cgv" element={<CGVPage />} />
        <Route path="/vogz-tunnel" element={<RegisterProcess />} />
        <Route
          path="/new-deal/:idOffer"
          key="new-deal/:idOffer"
          element={
            <Page>
              <NewDeal />
            </Page>
          }
        />
        <Route
          path="/profil-vogzter/:idba"
          key="profil-vogzter/:idba"
          element={
            <Page>
              <ProfilFavorite />
            </Page>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <>
              <AdminRoute routes={() => <RightPanel />} />
              <AdminRoute routes={(client) => <PageAuth client={client} />} />
            </>
          }
        />
        {/* <AdminRoute path="/castings/:idcasting/" element={DetailsCasting} /> */}
        {/* <Route
          path="/castings/:idcasting/details-casting/:iddetail"
          element={<DetailsCasting />}
        /> */}
        <Route
          path="/dashboard/castings/:id/validate"
          element={
            <Page>
              <AdminRoute routes={(client) => <PageAuth client={client} />} />
              <EndedCasting />
            </Page>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  )
}

const PageComponent = ({
  children,
  style,
}: {
  // eslint-disable-next-line react/require-default-props
  children?: JSX.Element | JSX.Element[]
  // eslint-disable-next-line react/require-default-props
  style?: MotionStyle
}) => {
  return (
    <motion.aside
      className={`Page ${utilsMobx.open ? 'active' : ''}  ${
        utilsMobx.mobile ? 'mobile' : ''
      } ${utilsMobx.aside_animation_ended ? '!transform-none' : ''}`}
      custom={{ direction: 'forward', delay: 0 }}
      initial="initial"
      animate="in"
      exit="out"
      onAnimationStart={() => {
        utilsMobx.handleAssideEnded(false)
      }}
      onAnimationComplete={(evt) => {
        // utilsMobx.handleAssideEnded(true)
      }}
      id="page-aside"
      // variants={pageVariantsAnimation}
      style={{ width: '100%', top: 0, left: 0, ...style }}
    >
      {Object.keys(userMobx.me.data ?? {}).length > 0 ||
      userMobx.getUserInfos() !== null ? (
        <div className={'hamburger-menu-div'}>
          <Hamburger
            toggled={utilsMobx.mobile}
            toggle={(bool: any) => {
              onOverlay()
              utilsMobx.handleMobile(bool.valueOf() as boolean)
            }}
          />
        </div>
      ) : null}
      <>{children}</>
    </motion.aside>
  )
}

const PageAuth = ({ client }: { client: ClientI }) => {
  const location = useLocation()
  const locationArr = location.pathname?.split('/') ?? []
  return !userMobx.me.loading ? (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={locationArr[2]}>
        <Route
          key="*castings-id"
          path="/castings/:id"
          element={
            <Page>
              <MyCasting />
            </Page>
          }
        />

        <Route
          key="*brandsrr-"
          path="/brands/:id/edit"
          element={
            <Page>
              <EditBrand />
            </Page>
          }
        />

        <Route
          key="*brands.f"
          path="/brands/new"
          element={
            <Page>
              <NewBrand />
            </Page>
          }
        />

        <Route
          key="*brands.rf"
          path="/brands"
          element={
            <Page>
              <Brands />
            </Page>
          }
        />
        <Route
          path="/purchases"
          key="*purchases"
          element={
            <Page>
              <Purchases />
            </Page>
          }
        />
        <Route
          path="/profile"
          key="*profil"
          element={
            <Page>
              <Profile />
            </Page>
          }
        />
        <Route
          path="/profile/team"
          key="*profil/team"
          element={
            <Page>
              <Team />
            </Page>
          }
        />
        <Route
          path="/profile/brand"
          key="*profil/brand"
          element={
            <Page>
              <MyBrand />
            </Page>
          }
        />
        <Route
          path="/profile/my-profile"
          key="*profil/my-profile"
          element={
            <Page>
              <MyProfile />
            </Page>
          }
        />
        <Route
          path="/messaging"
          key="chat"
          element={
            <Page>
              <Messaging />
            </Page>
          }
        />
        <Route
          path="/public-vogz"
          key="chat"
          element={
            <Page>
              <MediaPublic />
            </Page>
          }
        />
        <Route
          path="/new-deal/:idOffer"
          key="new-deal"
          element={
            <Page>
              <NewDeal />
            </Page>
          }
        />

        {['/castings', '/brands/:id/briefs'].map((path) => (
          <Route
            key={`castings.${path}`}
            path={path}
            element={
              <Page>
                <AdminRoute routes={() => <MyCastings />} />
              </Page>
            }
          />
        ))}

        <Route
          key="*new-castings.type"
          path="/new-castings"
          element={
            <Page>
              <NewCasting />
            </Page>
          }
        />

        <Route
          key="*new-castings-new"
          path="/new-castings/:type_casting"
          element={
            <Page>
              <SettingCasting client={client} />
            </Page>
          }
        />

        <Route
          key="*new-castings-edit"
          path="/castings/:id/edit"
          element={
            <Page>
              <EditCasting />
            </Page>
          }
        />

        <Route
          key="*notification-brief"
          path="/castings/:id/notifications"
          element={
            <Page>
              <NotificationBrief />
            </Page>
          }
        />
        <Route
          key="*extend-castings"
          path="/castings/:id/extend"
          element={
            <Page>
              <ExtendCasting />
            </Page>
          }
        />
        <Route
          key="*add-favourite"
          path="/castings/:id/add-favourites"
          element={
            <Page>
              <AddFavourites />
            </Page>
          }
        />
        <Route
          path="/castings/:id/briefapplication/:idba"
          key="*castings/:id/sd"
          element={
            <Page style={{ transform: 'none' }}>
              <BriefApplication />
            </Page>
          }
        />
        <Route
          path="/favorites-vogzters"
          key="favorites"
          element={
            <Page>
              <Favorite />
            </Page>
          }
        />
        <Route
          path="/profil-vogzter/:idba"
          key="profil-vogzter/:idba"
          element={
            <Page>
              <ProfilFavorite />
            </Page>
          }
        />
        <Route
          path="/premium"
          key="premium"
          element={
            <Page>
              <Premium2 />
            </Page>
          }
        />
        {/* <Route
          path="/castings/:id/messages"
          key="messages/e"
          element={
            <Page>
              <Messages />
            </Page>
          }
        /> */}
        <Route
          path="/castings/:id/summary"
          key="summary/casting"
          element={
            <Page>
              <Summary />
            </Page>
          }
        />
        <Route
          path="/credits-vogz"
          key="credits-vogz"
          element={
            <Page>
              <CreditVogz />
            </Page>
          }
        />
      </Routes>
    </AnimatePresence>
  ) : (
    <div />
  )
}

const Page = observer(PageComponent)

export default Router
