
import React, { FC, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'  
import { BrandCategory } from '../../../types-project/GalerieVogzter'

const ListOverflowHandlerCategories: FC<
  IListOverflowHandlerCategoriesProps
> = ({ categories, nextCallbackPressed }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [visibleCategories, setVisibleCategories] = useState<string[]>([])
  const [hiddenCount, setHiddenCount] = useState(0)

  useEffect(() => {
    const calculateOverflow = () => {
      const container = containerRef.current
      if (!container) return

      let totalWidth = 0
      const maxContainerWidth = container.offsetWidth
      const visible: string[] = []

      categories.forEach((category) => {
        const categoryWidth = getTextWidth(category.name, '12px Arial') + 32 // Account for padding (px-4)
        if (totalWidth + categoryWidth <= maxContainerWidth) {
          visible.push(category.name)
          totalWidth += categoryWidth + 8 // Include gap between tags
        }
      })

      // Ensure at least one category is visible if categories exist
      if (visible.length === 0 && categories.length > 0) {
        visible.push(categories[0].name) // Force the first category to be visible
      }

      setVisibleCategories(visible)
      setHiddenCount(categories.length - visible.length)
    }

    calculateOverflow()
    window.addEventListener('resize', calculateOverflow)

    return () => {
      window.removeEventListener('resize', calculateOverflow)
    }
  }, [categories])

  // Helper function to calculate text width using a canvas element
  const getTextWidth = (text: string, font: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = font
      return context.measureText(text).width
    }
    return 0
  }

  return (
    <div
      ref={containerRef}
      className="flex gap-1 overflow-x-auto whitespace-nowrap"
    >
      {visibleCategories.length > 0 && (
        <>
          {visibleCategories.map((category, index) => (
            <span
              key={index}
              className="bg-alice-blue font-bold rounded-2xl text-primary-blue px-4 py-1 inline-block text-[12px]"
            >
              {category}
            </span>
          ))}

          {hiddenCount > 0 && (
            <span
              className="bg-gray-300 font-bold text-[12px] text-black px-4 py-1 rounded-2xl cursor-pointer"
              onClick={() =>
                nextCallbackPressed(categories[visibleCategories.length])
              }
            >
              {`+${hiddenCount}`}
            </span>
          )}
        </>
      )}
    </div>
  )
}

ListOverflowHandlerCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  nextCallbackPressed: PropTypes.func.isRequired,
}

type IListOverflowHandlerCategoriesProps = {
  categories: BrandCategory[]
  nextCallbackPressed: (brandCategory: BrandCategory) => void
}

export default ListOverflowHandlerCategories
