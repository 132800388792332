
import React, { FC, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GalerieVogzterMedia as IGalerieVogzterMedia } from '../../../types-project/GalerieVogzter';
import ListOverflowHandlerView from './ListOverFlowView'
import modal from '../../../mobx/modal';


export const GalerieVogzterMedia: FC<IGalerieVogzterMediaProps> = ({
  media,
  itemsOverflowCategories = [],
}) => {
  const redirectionLink = `https://app.vogz.io/dashboard/profil-vogzter/${media.vogzter.objectID}`
  const [showHiddenElements, setShowHiddenElements] = useState(false)
  const [GIFHandler, updateGIFHandler] = useState({ animate: false })

  useEffect(() => {
    if (itemsOverflowCategories.length > 0) {
      setShowHiddenElements(true)
    } else {
      setShowHiddenElements(false)
    }
  }, [itemsOverflowCategories])

  return (
    <div className="bg-white shadow-md rounded-[40px] overflow-hidden relative">
      {showHiddenElements && (
        <ListOverflowHandlerView
          list={itemsOverflowCategories}
          onClose={() => {
            setShowHiddenElements(false)
          }}
        />
      )}
      <div
        onClick={(e) => {
          modal.toogleModalVideo(media.source, true)
          e.preventDefault()
        }}
        className="w-full h-[470px] cursor-pointer relative justify-center flex items-center"
      >
        <img
          src={
            (GIFHandler.animate ? media.gif : media.thumbnail) ??
            '/assets/imgs/placeholder-media.png'
          }
          alt={media.brand.name}
          onMouseEnter={() => {
            updateGIFHandler((prev) => {
              let newGIFHandler = prev
              newGIFHandler = { animate: true }
              return newGIFHandler
            })
          }}
          onMouseLeave={() => {
            updateGIFHandler((prev) => {
              let newGIFHandler = prev
              newGIFHandler = { animate: false }
              return newGIFHandler
            })
          }}
          className="w-full h-full object-cover"
        />
        <i
          className="fa-sharp fa-solid fa-play text-3xl absolute text-white"
          style={{ textShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)' }}
        />
      </div>
      {media.vogzter?.picture && (
        <button
          onClick={() => {
            window.open(redirectionLink, '_blank')
          }}
          className=" rounded-[100px] overflow-hidden cursor-pointer absolute top-4 right-6"
          type="button"
        >
          <img
            src={media.vogzter?.picture}
            alt={media.brand.name}
            className="w-[60px] h-[60px] rounded-full"
          />
        </button>
      )}
    </div>
  )
}
GalerieVogzterMedia.propTypes = {
  media: PropTypes.any.isRequired,
  itemsOverflowCategories: PropTypes.array,
}

GalerieVogzterMedia.defaultProps = {
  itemsOverflowCategories: [],
}

type IGalerieVogzterMediaProps = {
  media: IGalerieVogzterMedia
  itemsOverflowCategories?: Array<string>
}
