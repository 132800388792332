import React, { useEffect, useRef, useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import Button from '../../../utils/components/Ui/v2/buttons/Button'
import TextField from '../../../utils/components/Ui/v2/inputs/TextField'
import ErrorMsg from '../../../utils/components/Ui/errors/ErrorMsg'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import userMobx from '../../../mobx/user'
import { BrandI, Category, ClientI } from '../../../types-project/Client'
import Step5 from './steps/Step5'
import Step6 from './steps/Step6'
import { getOpenAIResponse } from '../../../utils/open-ai'
import { DropdownCheckboxOption } from '../../../utils/components/Ui/dropdown/DropdownCheckbox'
import { validateUrl } from '../../../utils/validateUrl'
import { removeEmoji } from '../../../utils/removeEmoj'

const initialValues = {
  brand_name: '',
  description: '',
  instagram: '',
  tiktok: '',
  facebook: '',
  twitter: '',
  company_url: '',
  categories: [],
}

const prompt = (url: string, text: string, category: string) => {
  return `${url} : ${text} ${category}`
}

const questions = [
  "regarde cette page, résume l'activité de manière inspirante en 5 phrases et dit « nous » »",
  'find instagram and tiktok links of this website and send me them in a [JSON] format without any comments.',
  'Analysez le site puis choisissez la catégorie qui vous convient le mieux dans la liste des catégories ci-dessous :'
]

interface AdditionInformation {
  brand_name: string
  company_url: string
  description: string
  instagram: string
  tiktok: string
  facebook: string
  twitter: string
  categories: Category[]
}

const UserOnboard = () => {
  const [isValidate, setIsValidate] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const validationSchema = Yup.object().shape({
    categories: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
        })
      )
      .min(1, 'Veuillez sélectionner une catégorie'),
    brand_name: Yup.string().required(t('onboard:form-error:brand_name')),
    company_url: Yup.string()
      .url(t('signup-login:form-errors:company_url'))
      .required(t('signup-login:form-required:company_url')),
    description: Yup.string().required(t('onboard:form-error:description')),
    instagram: Yup.string().url(t('onboard:form-error:url')),
    tiktok: Yup.string().url(t('onboard:form-error:url')),
    facebook: Yup.string().url(t('onboard:form-error:url')),
    twitter: Yup.string().url(t('onboard:form-error:url')),
  })

  const formik = useFormik<AdditionInformation>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const user = userMobx.getUserInfos()
      if (!user) {
        navigate('/login')
        return
      }
      const brand = user?.brand
      if (!brand) {
        navigate('/login')
        return
      }
      userMobx.updateBrand(
        brand?._id!,
        {
          name: values.brand_name,
          categories: values.categories,
          info: {
            text: values.description,
            url: values.company_url,
            social_links: [
              {
                url: values.instagram,
              },
              {
                url: values.tiktok,
              },
              {
                url: values.facebook,
              },
              {
                url: values.twitter,
              },
            ],
          },
        },
        () => {
          toast.success('La marque à été mise à jour', { theme: 'colored' })
          setStep(2)
          // navigate('/dashboard/new-castings/creator')
        }
      )
    },
  })

  //   // getting the brand infos from the local storage
  const userInfos = userMobx.getUserInfos() as ClientI
  //   // in case the new URL field is empty, we get the old one from before the model change of the object brand
  const brand = userInfos?.brand!

  //   // if brand.info url exists or if social links' length is greater than 0 or if brand.info text exists, it means the client already provided the info
  //   // so we set the default step to 2 otherwise we set it to 1
  const [step, setStep] = useState(
    brand?.info?.url ||
      (brand?.info?.social_links && brand?.info?.social_links?.length > 0) ||
      brand?.info?.text
      ? 2
      : 1
  )

  const [categoryOptions, setCategoryOptions] = useState<
    DropdownCheckboxOption[]
  >([])
  useEffect(() => {
    userMobx.getCategories((res) => {
      setCategoryOptions(
        res.map((category: Category) => ({
          id: category._id,
          label: category.name,
          data: category as Category,
        }))
      )
    })
  }, [])

  useEffect(() => {
    const user = userMobx.getUserInfos();
    if(!user){
      navigate('/login')
    }
  }, [])

const handleAutoFill = async () => {
  if (!formik.values.company_url) {
    formik.setFieldError('company_url', t('signup-login:form-required:company_url'));
    return;
}

if (!validateUrl(formik.values.company_url)) {
  formik.setFieldError('company_url', t('signup-login:form-errors:company_url'));
  return;
}
setIsValidate(true)
    const rawCategoryOptions = categoryOptions.map((m) => removeEmoji(m.label).trim())
    const response = await Promise.all(
      questions.map(async (question, index) => {
        return getOpenAIResponse(
          prompt(
            formik.values.company_url,
            question,
            index === 2
              ? JSON.stringify(rawCategoryOptions)
              : ''
          )
        )
      })
    )
    setIsValidate(false)
    formik.setFieldValue('description', response[0])
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(JSON.parse(response[1]))) {
      formik.setFieldValue(key.toLowerCase(), value)
    }
    if(!response[2]){
      return;
    }
    const raw = response[2].replaceAll('\n', '').trim();
    const category = rawCategoryOptions.indexOf(raw)
    if(category !== -1){
    formik.setFieldValue(
      'categories',
      [categoryOptions[category]?.data]
    )}else{
      const category = rawCategoryOptions.indexOf("Entrepreneur")
      formik.setFieldValue(
        'categories',
        [categoryOptions[category]?.data]
      )
    }
}


  return (
    <div
      className="w-screen h-screen p-3 overflow-y-auto"
      style={{
        background: step === 2 ? '#f2f6ff' : 'inherit',
      }}
    >
      {step === 1 && (
        <Step1
          formik={formik}
          isLoading={userMobx.brand.loading || isValidate}
          categoryOptions={categoryOptions}
          handleAutoFill={handleAutoFill}
        />
      )}
      {step === 2 && (
        <Step2 onNext={() => navigate('/dashboard/new-castings/creator')} />
      )}
      {step === 3 && (
        <Step3
          onBack={() => setStep((state) => state - 1)}
          onNext={() => setStep((state) => state + 1)}
        />
      )}
      {step === 4 && (
        <Step4
          onBack={() => setStep((state) => state - 1)}
          onNext={() => setStep((state) => state + 1)}
        />
      )}
      {step === 5 && (
        <Step5
          onBack={() => setStep((state) => state - 1)}
          onNext={() => setStep((state) => state + 1)}
        />
      )}
      {step === 6 && <Step6 />}
      {/* <ChooseCastingStep
          nextBtnRef={ref}
          setStep={() => {}}
        /> */}
    </div>
  )
}

export default observer(UserOnboard)
