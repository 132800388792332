import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../utils/components/Ui/v2/buttons/Button'
import { ReturnButton } from '../../../../utils/components/Ui/v2/buttons/ReturnButton'


interface Step5Props {
    onBack?: () => void
    onNext?: () => void
  }

const Step5:React.FC<Step5Props> = ({onBack,onNext}
) => {
    const [active, setActive] = React.useState(1)
  return (
    <div className="max-w-[1180px] mx-auto">
        <div className="mb-8">
            <ReturnButton onClick={onBack}/>
        </div>
        <div>
            <h1 className="font-semibold text-4xl mb-6 text-center">
                Accès à la plateforme self-service
            </h1>
            <div className='text-[15px] text-center mb-20'>
                Social Content Creator Platform Management
            </div>
            <div className='flex flex-col items-center mb-16'>
                <div className='flex bg-[#D4DDF0] h-[90px] w-[460px] rounded-[20px] p-3 gap-4'>
                    <div className='h-[66px] w-[210px] flex justify-center items-center rounded-2xl text-base font-bold text-[#97A3BB] cursor-pointer' style={{background: active === 1 ? '#fff' : ''}} onClick={() =>setActive(1)}>
                        Mois
                    </div>
                    <div className='h-[66px] w-[210px] flex justify-center items-center rounded-2xl text-base font-bold text-[#97A3BB] cursor-pointer' style={{background: active === 2 ? '#fff' : ''}} onClick={() =>setActive(2)}>
                        Année
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center mb-6'>
                <div className='w-[450px] py-16 px-11 border-4 border-[#F5F8FF] rounded-[40px]'>
                    <div className='text-[#303056] text-7xl font-bold mb-16'>
                        149€<span className='text-2xl font-semibold'>/mois</span>
                    </div>
                    <div className='flex justify-between pb-5 border-b border-[#D7D7D7] mb-6'>
                        <div className='flex flex-col gap-3'>
                            <div className='text-[#303056] text-base font-semibold'>Gestionnaire de Castings</div>
                            <div className='text-[#7C7C7C] text-sm font-light w-[265px]'>Briefez les créateurs et gérez vos stratégies d’achat. Collectez des candidatures spontanées (UGC / Influence)</div>
                        </div>
                        <div className='flex flex-col justify-center'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#7885ED"/>
                                <path d="M5 10.2941L8.66667 14L16 7" stroke="white" stroke-width="2"/>
                            </svg>
                        </div>
                    </div>
                    <div className='flex justify-between pb-5 border-b border-[#D7D7D7] mb-6'>
                        <div className='flex flex-col gap-3'>
                            <div className='text-[#303056] text-base font-semibold'>Gestionnaire de Castings</div>
                            <div className='text-[#7C7C7C] text-sm font-light w-[265px]'>Briefez les créateurs et gérez vos stratégies d’achat. Collectez des candidatures spontanées (UGC / Influence)</div>
                        </div>
                        <div className='flex flex-col justify-center'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#7885ED"/>
                                <path d="M5 10.2941L8.66667 14L16 7" stroke="white" stroke-width="2"/>
                            </svg>
                        </div>
                    </div>
                    <div className='flex justify-between pb-5 border-b border-[#D7D7D7] mb-6'>
                        <div className='flex flex-col gap-3'>
                            <div className='text-[#303056] text-base font-semibold'>Gestionnaire de Castings</div>
                            <div className='text-[#7C7C7C] text-sm font-light w-[265px]'>Briefez les créateurs et gérez vos stratégies d’achat. Collectez des candidatures spontanées (UGC / Influence)</div>
                        </div>
                        <div className='flex flex-col justify-center'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#7885ED"/>
                                <path d="M5 10.2941L8.66667 14L16 7" stroke="white" stroke-width="2"/>
                            </svg>
                        </div>
                    </div>
                    <div className='flex justify-between items-end'>
                        <div className='flex flex-col gap-3'>
                            <div className='text-[#303056] text-base font-semibold'>Marque(s) additionnelle(s)</div>
                            <div className='text-[#7C7C7C] text-sm font-light w-[265px]'>Ajouter et gérez autant de marques que vous le souhaitez </div>
                        </div>
                        <div className='text-[#7885ED] text-xl font-bold'>
                            +100€<span className='text-xs font-normal'>/mois</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center mb-16'>
                <div className='w-[450px] bg-[#303056] p-11 rounded-3xl text-white'>
                    <div className='flex justify-between mb-4'>
                        <div className='text-base font-semibold'>Base créateur</div>
                        <div className='text-base font-bold'>+10€<span className='text-xs font-normal'>/mois</span></div>
                    </div>
                    <div className='flex justify-between'>
                        <div className='text-sm font-light w-[265px]'>Accédez à l’ensemble de nos talents et collaborez en direct avec les créateurs de votre choix</div>
                        <div className='flex flex-col justify-center'>
                            <div className="relative inline-block w-12 h-7">
                                <input id="switch-component" type="checkbox" className="peer appearance-none w-12 h-7 bg-[#D7D9E9] rounded-full checked:bg-[#7885ED] cursor-pointer transition-colors duration-300" />
                                <label htmlFor="switch-component" className="absolute top-[3px] left-0 translate-x-1 shadow shadow-[#00000033] w-5 h-5 bg-white rounded-full transition-transform duration-300 peer-checked:translate-x-6 cursor-pointer">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <Button className='w-[450px] h-16' onclick={onNext}>
                    Commencer
                </Button>
            </div>
        </div>
    </div>
  )
}

Step5.defaultProps = {
    onBack: () => {},
    onNext: () => {}
}

Step5.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
}

export default Step5
