/* eslint-disable global-require */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { Spinner } from 'grommet'
import algoliasearch from 'algoliasearch'
import { debounce } from 'lodash'
import userMobx from '../../mobx/user'
import Title1 from '../../utils/components/Headings/Title1'
import ScrollToTop from '../../routing/ScrollToTop'
import BtnDrop from '../../utils/components/Button/BtnDrop'
import {
  Category,
  TypeClient,
  VogzterSearchFilters,
  VogzterSearchResult,
} from '../../types-project/Client'
import SearchBar from '../../utils/components/SearchBar'
import BrandSwitcher from '../../utils/components/BrandSwitcher/BrandSwitcher'
import DropdownCheckbox, {
  DropdownCheckboxClassNames,
  DropdownCheckboxOption,
} from '../../utils/components/Ui/dropdown/DropdownCheckbox'
import s from './components/styles.module.scss'
import BtnBasic from '../../utils/components/Button/BtnBasic'
import TextField from '../../utils/components/Ui/inputs/TextField'
import {
  GalerieVogzterMedia,
  SearchVogzterMediaFilter,
} from '../../types-project/GalerieVogzter'
import GalerieVogzterMedias from './components/GalerieVogzterMedias'
import DeletableList from '../Favorites-vogzters/components/DeletableList'

const MediaPublic = () => {
  const selectedBrand = userMobx.getBrandSession() ?? {}

  const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID ?? '',
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY ?? ''
  )
  const algoliaIndex = algoliaClient.initIndex('media_public')

  // Using useRef to keep track of the real value inside algoliaQuery()
  const allowAlgoliaRequest = useRef(false)

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const ageInterval = {
    minAge: 0,
    maxAge: 100,
    filters: ['18-25', '26-35', '+35'],
  }
  const [filters, updateFilters] = useState<SearchVogzterMediaFilter>({
    age18To25: false,
    age26To35: false,
    ageMoreThan35: false,
    categories: [],
    man: false,
    woman: false,
    recommended: false,
    socials: {
      tiktokLink: false,
      instagramLink: false,
      snapchatLink: false,
      youtubeLink: false,
    },
  })

  const refDCGender = useRef<DropdownCheckbox>(null)
  const refDCAge = useRef<DropdownCheckbox>(null)
  const refDCCategories = useRef<DropdownCheckbox>(null)
  const refDCSocials = useRef<DropdownCheckbox>(null)
  const refDCFollowers = useRef<DropdownCheckbox>(null)
  const refDCLanguages = useRef<DropdownCheckbox>(null)
  const refDCCountries = useRef<DropdownCheckbox>(null)
  const DCFiltersRefs = [
    refDCGender,
    refDCAge,
    refDCCategories,
    refDCSocials,
    refDCFollowers,
    refDCLanguages,
    refDCCountries,
  ]
  const getCategoryOptions = (): DropdownCheckboxOption[] => {
    return (
      userMobx.usedCategories?.data?.map((category: Category) => ({
        id: category._id,
        label: category.name,
        data: category as Category,
        onClick: (selected) =>
          updateFilters((prev) => {
            const newCategories = [...prev.categories]
            const element = newCategories.find(
              (c) => c.data._id === category._id
            )
            if (element) {
              element.selected = selected
            }
            return { ...prev, categories: newCategories }
          }),
      })) ?? []
    )
  }
  let dcCategoryOptions: DropdownCheckboxOption[] = useMemo(
    () => getCategoryOptions(),
    [userMobx.usedCategories.data]
  )
  const { t } = useTranslation()
  const [userLanguage, setUserLanguage] = useState(navigator.language)

  const observer = useRef<IntersectionObserver>()
  const globalPageAside = document.getElementById('page-aside')

  const lastVogzterRef = useCallback(
    (node) => {
      if (!allowAlgoliaRequest.current) return
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          userMobx.publicMedia.data?.hasMore === true
        ) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [userMobx.publicMedia.loading]
  )

  const [selectedOptions, setSelectedOptions] = useState<
    DropdownCheckboxOption[]
  >([])
  const selectedOptionsIds = JSON.parse(
    sessionStorage.getItem('selectedFiltersIds') ?? '[]'
  ) as string[]

  const algoliaQuery = (reset?: boolean) => {
    if (!allowAlgoliaRequest.current) return

    if (userMobx.publicMedia.data?.hasMore || reset) {
      // empty data because we only update the loading property here
      userMobx.updateVogzters([], page, 0, false, true, true)

      if (filters.age18To25) {
        ageInterval.minAge = 18
        ageInterval.maxAge = 25
      }
      if (filters.age26To35) {
        if (!filters.age18To25) ageInterval.minAge = 26
        ageInterval.maxAge = 35
      }
      if (filters.ageMoreThan35) {
        if (!filters.age18To25 && !filters.age26To35) ageInterval.minAge = 35
        ageInterval.maxAge = 100
      }
      if (
        filters.age18To25 &&
        filters.ageMoreThan35 &&
        filters.age26To35 === false
      ) {
        filters.age26To35 = true
        refDCAge.current?.select('hasBetween-26-35-yo')
      }

      const minLastActivity = new Date()
      minLastActivity.setDate(minLastActivity.getDate() - 4)

      const numericFilters = [
        `vogzter.age >= ${ageInterval.minAge}`,
        `vogzter.age <= ${ageInterval.maxAge}`,
      ].filter((f) => f !== undefined) as string[]
      if (filters.certified) numericFilters.push('certified = 1 ')

      const fGender =
        filters.man && filters.woman
          ? ''
          : filters.man
          ? 'gender:male'
          : filters.woman
          ? 'gender:female'
          : ''

      const fCategories = filters.categories
        .filter((category) => category.selected)
        .map((category) => `categories.name:"${category.data.name}"`)
      const fRecommended =
        userMobx.me.data?._company?.type === 'agency'
          ? selectedBrand.categories?.map(
              (category) => `categories.name:"${category.name}"`
            ) || []
          : userMobx.me.data?.brand?.categories?.map(
              (category) => `categories.name:"${category.name}"`
            ) || []


      const requestfilters = buildRequestFilters(
        'AND',
        fGender,
        filters.recommended ? buildRequestFilters('OR', ...fRecommended) : '',
        ...fCategories,
      )

      const curAlgoliaIndex = algoliaIndex

      const triggerMainSearch = () => {
        curAlgoliaIndex
          .search(search, {
            filters: requestfilters,
            page: page - 1,
            numericFilters: numericFilters,
          })
          .then(({ hits, nbHits }) => {
            userMobx.updatePublicMedia(
              hits as GalerieVogzterMedia[],
              page,
              nbHits,
              (userMobx.publicMedia.data?.medias?.length ?? 0) < nbHits
            )
          })
      }
      triggerMainSearch()
    }
  }

  // Debounce algoliaQuery to avoid sending too many requests
  const debouncedAlgoliaQuery = debounce(algoliaQuery, 200)

  const addOrReplaceURLParam = (param: string, value: string) => {
    const url = new URL(window.location.href)
    url.searchParams.set(param, value)
    window.history.pushState({}, '', url.toString())
  }

  const deleteURLParam = (param: string) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.pushState({}, '', url.toString())
  }

  const onDropdownElementSelected = (selected: DropdownCheckboxOption) => {
    addOrReplaceURLParam(selected.id, '1')
    setSelectedOptions((prev) => {
      const newValue = [...new Set([...prev, selected])]
      sessionStorage.setItem(
        'selectedFiltersIds',
        JSON.stringify(newValue.map((o) => o.id))
      )
      return newValue
    })
  }

  const onDropdownElementRemoved = (removed: DropdownCheckboxOption) => {
    deleteURLParam(removed.id)
    setSelectedOptions((prev) => {
      const newValue = prev.filter((o) => o.id !== removed.id)
      sessionStorage.setItem(
        'selectedFiltersIds',
        JSON.stringify(newValue.map((o) => o.id))
      )
      return newValue
    })
  }

  const dropdownClassnames: DropdownCheckboxClassNames = {
    menu: '!relative !mt-0',
    menuHidden: '!w-0 !h-0',
  }

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    // Get used categories from the API to populate the "Catégorie" filter options
    userMobx.getUsedCategories((usedCategories) => {
      if (!usedCategories) return

      dcCategoryOptions = getCategoryOptions()

      // Get default selected filters from URL and apply them
      // This code is inside the callback to make sure the categories are loaded before applying the filters
      const selectedOptions: DropdownCheckboxOption[] = []
      const urlParamsKeys = Array.from(
        new URL(window.location.href).searchParams.keys()
      )
      if (urlParamsKeys.length > 0) {
        for (let i = 0; i < urlParamsKeys.length; i += 1) {
          const key = urlParamsKeys[i]
          const refDCFilter = DCFiltersRefs.find((dc) =>
            dc.current?.containsId(key)
          )
          const filter = refDCFilter?.current?.getOption(key)
          if (
            refDCFilter &&
            filter &&
            !selectedOptions.find((o) => o.id === key)
          ) {
            sessionStorage.removeItem('selectedFiltersIds')
            selectedOptions.push(filter)
            refDCFilter.current?.select(key)
          }
        }
      } else {
        selectedOptionsIds.forEach((id) => {
          const refDCFilter = DCFiltersRefs.find((dc) =>
            dc.current?.containsId(id)
          )
          const option = refDCFilter?.current?.getOption(id)
          if (refDCFilter && option) {
            if (!selectedOptions.find((o) => o.id === id)) {
              selectedOptions.push(option)
              refDCFilter.current?.select(id)
              addOrReplaceURLParam(id, '1')
            }
          }
        })
      }
      setSelectedOptions(selectedOptions)

      // Once all the computations are done, we allow algolia HTTP requests.
      // If we don't do this, unnecessary requests will be sent almost at the same time
      // which also causes algolia to return wrong results.
      allowAlgoliaRequest.current = true

      // Triggers the useEffect() hook to launch the first algolia query
      updateFilters((prev) => ({
        ...prev,
        categories:
          usedCategories.map((category: Category) => ({
            data: category,
            selected: false,
          })) ?? [],
      }))
    })

    setUserLanguage(navigator.language)
  }, [])

  useEffect(() => {
    debouncedAlgoliaQuery()
  }, [page])

  // Resets vogzter list only if search, filters or sortType state changes
  useEffect(() => {
    if (!allowAlgoliaRequest.current) return
    if (observer.current) observer.current.disconnect()
    if (globalPageAside) {
      globalPageAside.scrollTo(0, 0)
    }
    setPage(1)
    debouncedAlgoliaQuery(true)
  }, [search, filters])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Vogzter</title>
      </Helmet>
      <main className={s['favorites-page']}>
        {userMobx.me.data?._company?.type === TypeClient.AGENCY && (
          <div className="z-50 h-24">
            <BrandSwitcher
              className={s['brand-switcher']}
              classNames={{
                brandList: '!right-0',
              }}
              dntComesFromBriefList
            />
          </div>
        )}
        <ScrollToTop />
        <div className={s['all-body']}>
          <div className={`${s['header']} ${s['p-horizontal']}`}>
            <div className="flex w-full justify-between flex-wrap">
              <Title1
                text={t('favorite:title-medias')}
                style={{ textTransform: 'uppercase' }}
                size={33}
              />
            </div>
            <div className="flex flex-wrap w-full mt-6">
              <div className={`${s['filter-container']} scroll-container-x`}>
              <DropdownCheckbox
                  ref={refDCGender}
                  multiSelection
                  classNames={dropdownClassnames}
                  placeholder={t('favorite:gender')}
                  options={[
                    { id: 'isMan', label: 'favorite:filters:man', onClick: (isSelected) => updateFilters(prev => ({ ...prev, man: isSelected })) },
                    { id: 'isWoman', label: 'favorite:filters:woman', onClick: (isSelected) => updateFilters(prev => ({ ...prev, woman: isSelected })) },
                  ]}
                  onElementSelected={onDropdownElementSelected}
                  onElementRemoved={onDropdownElementRemoved}
                />

                <DropdownCheckbox
                  ref={refDCAge}
                  multiSelection
                  classNames={dropdownClassnames}
                  placeholder={t('favorite:age')}
                  options={[
                    {
                      id: 'hasBetween-18-25-yo',
                      label: 'favorite:filters:age-between-18-25',
                      onClick: (isSelected) =>
                        updateFilters((prev) => ({
                          ...prev,
                          age18To25: isSelected,
                        })),
                    },
                    {
                      id: 'hasBetween-26-35-yo',
                      label: 'favorite:filters:age-between-26-35',
                      onClick: (isSelected) =>
                        updateFilters((prev) => ({
                          ...prev,
                          age26To35: isSelected,
                        })),
                    },
                    {
                      id: 'hasMoreThan-35-yo',
                      label: 'favorite:filters:age-more-than-35',
                      onClick: (isSelected) =>
                        updateFilters((prev) => ({
                          ...prev,
                          ageMoreThan35: isSelected,
                        })),
                    },
                  ]}
                  onElementSelected={onDropdownElementSelected}
                  onElementRemoved={onDropdownElementRemoved}
                />
                {dcCategoryOptions.length > 0 && (
                  <DropdownCheckbox
                    ref={refDCCategories}
                    multiSelection
                    classNames={dropdownClassnames}
                    placeholder={t('favorite:category')}
                    options={dcCategoryOptions}
                    onElementSelected={onDropdownElementSelected}
                    onElementRemoved={onDropdownElementRemoved}
                  />
                )}
              
              </div>
            </div>
            <br />
          </div>
          <div className={`flex flex-col w-full ${s['p-horizontal']}`}>
            <hr className="w-full mb-1" />
            <DeletableList
              list={selectedOptions.map((option) => ({
                id: option.id,
                label: option.label,
                data: option,
              }))}
              handleDeletion={(newList, removedId) => {
                setSelectedOptions(newList)
                const refDCFilter = DCFiltersRefs.find((dc) =>
                  dc.current?.containsId(removedId)
                )
                if (refDCFilter) {
                  refDCFilter.current?.unselect(removedId)
                }
              }}
            />
            <b className="flex self-start ml font-hurmeBold text-[#B1B1B1] text-xs mt-2">
              {userMobx.publicMedia.data?.nbHits} medias(s)
            </b>
          </div>
          {userMobx.publicMedia.loading && <Spinner />}
          <GalerieVogzterMedias
            medias={{
              data: userMobx.publicMedia.data?.medias ?? [],
              loading: userMobx.publicMedia.loading,
            }}
            lastVogzterRef={lastVogzterRef}
          />
          {userMobx.publicMedia.data?.hasMore && (
            <BtnBasic
              className="sticky z-10 bottom-6 !min-w-0 !w-64 mb-3 !rounded-full"
              btnName={t('favorite:load-more-medias')}
              type="sky-violet-bordered"
              onclick={() => setPage((prevPage) => prevPage + 1)}
            />
          )}
        </div>
      </main>
    </>
  )
}

export default observer(MediaPublic)

const buildRequestFilters = (
  operation: string,
  ...filters: Array<string | undefined>
) => {
  const sanitizedFilters = filters.filter((f) => f !== undefined && f !== '')

  let requestFilters = ''
  sanitizedFilters.forEach((f, i) => {
    if (operation === 'OR') {
      if (i === 0) requestFilters += `(${f}`
      if (i === sanitizedFilters.length - 1) requestFilters += ` ${f})`
    } else {
      requestFilters += f
    }

    if (i < sanitizedFilters.length - 1) {
      requestFilters += ` ${operation} `
    }
  })
  return requestFilters
}
