import { DropPhotoImg } from '../pages/Castings/NewCasting/subscreens/EditCasting/components/ElementPicker'
import { DropdownCheckboxOption } from '../utils/components/Ui/dropdown/DropdownCheckbox'
import { Company, Country, Language, Link, Media, MediaBA, MyCasting, Offer, SocialLinks } from './Brief'

export type BrandI<T = Array<string>> = {
  _id?: string
  name?: string
  guests?: T
  logo?: string
  favourites?: any[]
  messages_count?: number
  links?: {
    save_links?: boolean,
    _links?: [Link]
  }
  products?: Array<ProductsBrand>,
  info?: {
    save_info?: boolean,
    text?: string,
    url?: string,
    target?: string,
    social_links?: Array<SocialLinks>
  }
  addresses?: Array<Address>,
  categories?: Category[]
}  |  {
  _id?: string
  name?: string
  guests?: Array<Guest>
  logo?: string
  favourites?: any[]
  messages_count?: number
  links?: {
    save_links?: boolean,
    _links?: [Link]
  }
  products?: Array<ProductsBrand>,
  info?: {
    save_info?: boolean,
    text?: string,
    url?: string,
    social_links?: Array<SocialLinks>
  },
  addresses?: Array<Address>,
  categories?: Category[]
}


export type ProductsBrand = {
  _id?: string,
  name: string,
  description: string,
  images: Array<File>,
  price?: number,
  url?: string,
  note?: string
} | {
  _id: string,
  name: string,
  description: string,
  images: Array<{ source: string, key: string }>,
  price?: number,
  url?: string,
  note?: string
}

export enum TypeClient {
  AGENCY = 'agency',
  BRAND = 'brand',
}

export enum TypeCompanyRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  GUEST = 'guest',
}

export interface ClientI {
  email: string
  brand?: BrandI
  company: Company
  conditions_accepted: boolean
  profile?: {
    name: string
    first_name: string
    phone: number
  }
  invoices: Invoice[]
  contracts: Contract[]
  _id?: string
  _company: Company2
  company_role: TypeCompanyRole
  products?: ProductsBrand[]
  addresses?: Address[]
  new_message?: boolean
}
export interface Notification {
    new_message?: boolean
    new_vogz?: boolean
    news?: boolean
}

export interface ClientUpdate {
  name?: Profile['name'],
  first_name?: Profile['first_name'],
  email?: ClientI['email'],
  links?: BrandI['links'],
  infos?: BrandI['info'],
  notifications: {
    new_message?: boolean
  }
}
export interface Vogzter {
  profile: ProfileVogzter
  _id: string
  first_name: string
  last_name: string
  createdAt: string
  certified: boolean
  media_count?: number
  nb_collab?: number
  product_sent?: boolean
  status: 'created'| 'signed-up' | 'id-validation' | 'declined' | 'active' | 'deleted',
  last_activity?: string
  categories?: Category[]
  parent_code?: string
}

// Type of the Vogzter search result returned by Algolia
export interface VogzterSearchResult {
  objectID: string
  parentCode: string
  favouritesCount: number
  collabWonCount: number
  collabCount: number
  vogzCount: number
  firstName: string
  lastName: string
  pro: boolean
  certified: boolean
  picture: string
  gender: 'male' | 'female'
  age: number
  favouritedBy: string[]
  lastActivity?: Vogzter['last_activity']
  profileVideoDemo?: Array<VogzterSearchResultMedia>
  profileVideoDemoCount: number
  profileVideoIntro?: VogzterSearchResultMedia
  profileVideoIntroExists: boolean
  tiktokLink?: boolean
  instagramLink?: boolean
  categories?: Array<{
    _id: string
    name: string
  }>
  region?: string
  address?: string
}

export interface VogzterSearchResultMedia {
  gif: string
  source: string
  thumbnail: string
}

export interface Vogzters {
  vogzters: VogzterSearchResult[] | null
  nbHits: number
  hasMore: boolean
}

export interface VogzterSearchFilters {
  fav?: boolean
  woman?: boolean
  man?: boolean
  certified?: boolean
  active: boolean
  recommended?: boolean
  age18To25?: boolean
  age26To35?: boolean
  ageMoreThan35?: boolean
  socials: {
    tiktokLink: boolean
    instagramLink: boolean
    snapchatLink: boolean
    youtubeLink: boolean
  }
  followers:Array<{
    min?: number
    max?: number
  }>
  categories: Array<{
    data: Category
    selected: boolean
  }>
  region?: DropdownCheckboxOption<{ id: string, label: string }>
  budget?: number
  languages: Language[]
  countries: Country[]
}

export interface ProfileVogzter {
  preferred_socials: string[]
  preferred_brief_types: string[]
  birthdate: Date
  gender: 'male' | 'female'
  picture: Picture
  languages: [{
    name: string,
    full_name: string
  }],
  country: {
    name: string
    full_name: string
  }
  bio: string
  socials: Socials[]
}

export interface Socials {
  followers : {
    min: number,
    max: number
  }
  social: {
    img_profile: string
    img_small: string
    img: string
    name: string
  }
  url: string
}
export interface Picture {
  source: string
  public_id: string
}
export interface Stripe {
  id: string
  payment_method: undefined
}
export interface Profile {
  name: string
  first_name: string
  email: string
  logo: string
  gender: "male" | "female"
  picture: {
    source: string
  }
}
export interface Client {
  company: Company
  conditions_accepted: boolean
  stripe: Stripe
  _id: string
  email: string
  parent: string
  phone: string
  invoices: null | Invoice
  contracts: null | Contract
  createdAt: Date
  updatedAt: Date
  __v: number
  _company: Company2
  company_role: string
  passcode: string
  profile: Profile
  notifications: Notification
}
export interface Company2 {
  _id: string
  type: TypeClient
  name: string
  logo?: string
  invoices: any[]
  contracts: any[]
  createdAt: Date
  updatedAt: Date
  stripe: Stripe
  credits: number
  media_saved: MediaSaved[]
  address?: string
  rcs?: {
    city: String,
    number: String
  },
  offered_casting_count?: number
  __v: number
  premium: boolean
  referrer?: Vogzter
  referrer_code?: Vogzter
  base_creator_activated: boolean
}
export interface Contract {
  _id: string
  name: string
  status: StatusContract
  source: string
  createdAt: Date
  external_id: string
  external_data: string
}

export interface MediaSaved {
  _id: string
  company: string
  media: MediaBA
  createdAt: string
  updatedAt: string
}

export interface Review {
  _id: string
  user: Applicant
  client: ClientI
  brand: BrandI
  rating: RatingType
  comment: string
  createdAt: string
  updatedAt: string
  op: 'user' | 'brand'
  reply?: {
    text: string
    date: string
  }
}

export interface RatingType {
  overall?: number
  communication: number
  quality: number
  punctuality: number
}

export interface Category {
  _id: string,
  name: string,
}

export enum StatusContract {
  None = 'none',
  ToCreate = 'to-create',
  ToSign = 'to-sign',
  Signed = 'signed',
  Errored = 'errored',
  Declined = 'declined',
}
export interface Invoice {
  _id: string
  name: string
  source: string
  createdAt: Date
}

export interface Kitmedia {
  _id: string
  name: string
  createdAt: Date
}
export type CustomClient = {
  name: string
  first_name: string
  email: Client['email']
  new_message?: boolean
}
export type Guest = {
  conditions_accepted: boolean
  _id: string
  email: string
  _company: string
  company_role: string
  brand: string
  createdAt: Date
  updatedAt: Date
  __v: number
}

export type Login = {
  email: string
}


// login signup types
export type LoginSignupData = {
  state?: 'login'
  otpSent: boolean
  clientId: string
  conditions: boolean
}

export type LoginAccount = {
  email: string
  password: string
  otp?: number
  conditions?: boolean
  password_confirmation?: string
}

// account signup
export type Account = {
  email: string
  company_url: string
  company_name: string
  brand_name?: string
  contact_phone: string
  contact_firstName: string
  contact_name: string
  otp?: number
  conditions: boolean
  type: 'agency' | 'brand'
  siret_number: string
  referrer?: string
  password?: string
  password_confirmation?: string
  parent_code?: string
}

export type CardPricePROPS = {
  name: String,
  nb_credits: Number,
  nb_sessions: Number,
  price: Number,
  stripe_id: String,
  type_company: 'agency'| 'brand',
  hubspot_id: String,
  _id: string
  active: boolean;
  companies: any[];
  payment_frequency: 'month' | 'quarter' | 'half-year' | 'year';
  nb_videos: number;
  premium_activated: boolean;
  base_creator_activated: boolean;
}

export type Plan ={
  active: boolean;
  companies: any[];
  _id: string;
  description: string;
  credits: number;
  name: string;
  price: number;
  commission: number;
  __v: number;
  discount:number;
  image:string;
  popular:boolean
  payment_method: string;
  background_color: string;
  text_color: string;
}
export interface PlanOlder {
  status: string;
  _id: string;
  company: string;
  plan: Plan;
  payment_method: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  payment_link?: string;
}

export interface AllMessages {
  read: boolean;
  _id: string;
  sender: 'user'| 'app'| 'client';
  text: string;
  brief_application?: {
    status: MyCasting['status']
    brief: MyCasting['brief']
  };
  __v: number;
  createdAt: string;
  updatedAt: Date;
  user: Vogzter | string;
  brand: string;
  offer?: Offer;
  brief?: string;
}

export interface BriefApActif {
  _id: string;
  brief:{
    _id: string;
    name: string;
    private: { is_private: boolean;}
  };
  status: string;
}
export interface GroupedMessages {
  user: Vogzter
  messages: AllMessages[]
  brief_applications?: BriefApActif[]
}
export interface Applicant {
  profile: Profile;
  _id: string;
  first_name: string;
  last_name: string;
  certified?: boolean;
  last_activity?: string;
}

export interface Address {
  _id?: string
  name: string
  address: string
  region: string,
  coordinates: {
    lat: number
    lng: number
  }
  images: Array<DropPhotoImg | undefined>
}

export interface SubscriptionPlan{
  _id: string,
  name: string,
  nb_credits: number,
  nb_sessions: number,
  price: number,
  stripe_id: string,
  type_company: {
      type: string,
      enum: ['agency', 'brand']
  },
  payment_frequency:  "month" | "quarter" | "half-year" | "year",
  premium_activated: boolean,
  base_creator_activated: boolean
}


export interface Subscription{
  _id: string
  company: string
  subscription_plan: SubscriptionPlan
  end_date: string
  start_date: string
  external_data: any
  stripe_id_subscription: string
  payment_count: number
  status: string
  createdAt: string
  updatedAt: string
}