/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Field, useFormik, FormikProvider } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import Loading from 'react-loading'

import userMobx from '../../../mobx/user'
import styles from './components/register.module.scss'
import Button from '../../../utils/components/Ui/v2/buttons/Button'
import ErrorMsg from '../../../utils/components/Ui/errors/ErrorMsg'
import TextField from '../../../utils/components/Ui/v2/inputs/TextField'
import { Account } from '../../../types-project/Client'
import { authCookies } from '../../../utils/helpers'
import { Offer } from '../../../types-project/Brief'
import modal from '../../../mobx/modal'
import Slide from './components/Slide'
import Title1 from '../../../utils/components/Headings/Title1'
import SubTitle1 from '../../../utils/components/Headings/SubTitle1'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const initialValues: Account = {
  email: '',
  company_url: '',
  company_name: '',
  brand_name: '',
  contact_firstName: '',
  contact_phone: '',
  contact_name: '',
  conditions: false,
  otp: undefined,
  type: 'brand',
  siret_number: '',
  password: '',
  password_confirmation: '',
}

function Signup2() {
  // translation
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  useEffect(() => {
    if (token) {
      userMobx.setToken(token)
      setStep(2)
    } else
      userMobx.getMe((user) => {
        authCookies(user)
        if (idOffer) userMobx.getOffer(idOffer)
        userMobx.getVogzter(offer.owner._id)
      })
  }, [])

  // validation
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('signup-login:form-errors:email'))
      .required(t('signup-login:form-required:email')),
    contact_name: Yup.string().required(
      t('signup-login:form-required:contact_name')
    ),
    contact_firstName: Yup.string().required(
      t('signup-login:form-required:contact_firstName')
    ),
    contact_phone: Yup.string()
      .matches(phoneRegExp, t('signup-login:form-errors:contact_phone'))
      .required(t('signup-login:form-required:contact_phone')),
    company_name: Yup.string().required(
      t('signup-login:form-required:company_name')
    ),
    company_url: Yup.string().required(
      t('signup-login:form-required:company_url')
    ),
    conditions: Yup.boolean().oneOf(
      [true],
      t('signup-login:form-required:conditions')
    ),
  })

  const validationSchema2 = Yup.object().shape({
    password: Yup.string()
      .required(t('signup-login:form-required:password'))
      .min(3, t('signup-login:form-errors:password')),
    password_confirmation: Yup.string()
      .required(t('signup-login:form-required:password_confirm'))
      .oneOf(
        [Yup.ref('password'), null],
        t('signup-login:form-errors:password_confirm')
      ),
  })

  const isGuest = localStorage.getItem('isGuest')
  const idOffer = localStorage.getItem('idOffer')
  const offer = userMobx.offer.data ?? ({} as Offer)
  const [step, setStep] = useState(1)

  const navigate = useNavigate()

  const formik = useFormik<Account>({
    initialValues: initialValues,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: step === 1 ? validationSchema : validationSchema2,
    onSubmit: async (values) => {
      const vogzterId = JSON.parse(sessionStorage.getItem('vogzter') ?? '{}')
      const parentCode = JSON.parse(sessionStorage.getItem('referral') ?? '{}')
      if (Object.keys(vogzterId).length > 0) values.referrer = vogzterId
      if (Object.keys(parentCode).length > 0) values.parent_code = parentCode
      handleBtn(values)
    },
  })

  const onSuccess = (stripeUrl: string) => {
    window.location.href = stripeUrl
  }

  const handleBtn = useCallback(
    async (client: Account) => {
      if (step === 1) {
        await userMobx.register(client, () => {
          sessionStorage.removeItem('referral')
          setStep(3)
        })
      } else if (step === 2 && client.password) {
        await userMobx.setPassword(client.password, (data) => {
          if (!userMobx.user.error && !isGuest) {
            if (data?.client?._company.premium) {
              if(idOffer) return onSuccess(`/dashboard/new-deal/${idOffer}`)
              else return onSuccess(`/dashboard/castings`)
            }
            userMobx.getMe()
            navigate('/onboard')
          } else if (isGuest && idOffer) {
            onSuccess(`/dashboard/castings`)
          }
        })
      }
    },
    [isGuest, userMobx, navigate, formik]
  )
  const responsive = window.innerWidth

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Register</title>
      </Helmet>
      <main className="gap-5 w-screen min-h-screen flex flex-col md:justify-center items-center md:p-5 md:flex-row md:overflow-y-hidden overflow-y-auto md:overflow-hidden">
        <Slide />
        <div
          className={`${
            step === 1 ? 'bg-[rgba(0,0,0,.5)] fixed top-0 left-0' : ''
          } md:hidden block h-full w-full`}
        ></div>
        <div className="fixed bottom-0 md:relative bg-white md:bg-transparent w-full md:w-1/2 md:h-full flex flex-col justify-center items-center rounded-t-[45px] md:rounded-t-none px-10 py-14 md:py-0 md:px-0">
          <img
            src="/assets/imgs/v2/text-logo.svg"
            className="mb-10"
            alt="vog-logo"
          />
          <h1 className="font-semibold text-4xl mb-4">
            {t('signup-login:signup_')}
          </h1>
          <div className="mb-6 text-[17px]">
            <span className="font-light">
              {t('signup-login:already-account')}
            </span>{' '}
            <Link className="text-main-blue-dark font-semibold" to="/login">
              {t('signup-login:login')}
            </Link>
          </div>
          <section className="w-full max-w-[600px]">
            <FormikProvider value={formik}>
              <form className={styles['form']}>
                <motion.div
                  key="1"
                  initial={{
                    opacity: 0,
                    marginRight: -160,
                    marginLeft: 160,
                  }}
                  animate={{ opacity: 1, marginRight: 0, marginLeft: 0 }}
                  exit={{ opacity: 0, marginRight: 160, marginLeft: -160 }}
                >
                  {step === 1 && (
                    <div>
                      <div className="flex flex-col mb-6 gap-3 md:gap-1">
                        <div className="flex md:flex-row flex-col gap-3 items-center">
                          <div className="w-full md:w-1/2">
                            <TextField
                              isShort={responsive < 768}
                              autoFocus
                              required
                              error={
                                formik.touched.contact_firstName &&
                                formik.errors.contact_firstName
                                  ? formik.errors.contact_firstName
                                  : ''
                              }
                              onChange={formik.handleChange}
                              value={formik.values.contact_firstName}
                              label=""
                              name="contact_firstName"
                              placeholder={t('signup-login:form.firstname')}
                            />
                          </div>
                          <div className="w-full md:w-1/2">
                            <TextField
                              isShort={responsive < 768}
                              required
                              error={
                                formik.touched.contact_name &&
                                formik.errors.contact_name
                                  ? formik.errors.contact_name
                                  : ''
                              }
                              onChange={formik.handleChange}
                              value={formik.values.contact_name}
                              label=""
                              name="contact_name"
                              placeholder={t('signup-login:form.name')}
                            />
                          </div>
                        </div>
                        <TextField
                          isShort={responsive < 768}
                          required
                          error={
                            formik.touched.contact_phone &&
                            formik.errors.contact_phone
                              ? formik.errors.contact_phone
                              : ''
                          }
                          type="phone"
                          onChange={formik.handleChange}
                          value={formik.values.contact_phone}
                          label=""
                          name="contact_phone"
                          placeholder={t('signup-login:form.contact_phone')}
                        />

                        <TextField
                          isShort={responsive < 768}
                          required
                          error={
                            formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : ''
                          }
                          type="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          label=""
                          name="email"
                          placeholder="Email"
                        />

                        <TextField
                          isShort={responsive < 768}
                          required
                          error={
                            formik.touched.company_name &&
                            formik.errors.company_name
                              ? formik.errors.company_name
                              : ''
                          }
                          onChange={formik.handleChange}
                          value={formik.values.company_name}
                          label=""
                          name="company_name"
                          placeholder={t('signup-login:form.company_name')}
                        />
                        <TextField
                          isShort={responsive < 768}
                          required
                          error={
                            formik.touched.company_url &&
                            formik.errors.company_url
                              ? formik.errors.company_url
                              : ''
                          }
                          onChange={formik.handleChange}
                          value={formik.values.company_url}
                          label=""
                          name="company_url"
                          placeholder={t('signup-login:form.url_site')}
                        />

                      </div>
                      <div className="mb-8">
                        <div className="flex items-center gap-1">
                          <Field
                            type="checkbox"
                            id="conditions"
                            name="conditions"
                            className={`${styles['check-box']} ${
                              formik.errors.conditions
                                ? styles['errored-field']
                                : ''
                            }`}
                            required
                          />
                          <label
                            htmlFor="conditions"
                            className="text-xs text-[#7c7c7c]"
                          >
                            <span className="text-[#7c7c7c]">
                              {t('signup-login:conditions')}{' '}
                            </span>
                            <Link
                              className="text-xs text-[#0c150a] underline font-semibold"
                              onClick={() => {
                                window.open('https://vogz.io/cgu', '_blank')
                              }}
                              target="_blank"
                              to=""
                            >
                              {t('signup-login:conditions_')}
                            </Link>
                          </label>
                        </div>
                        {formik.errors.conditions && (
                          <div className={styles['ErrorMsg']}>
                            <ErrorMsg
                              text={
                                formik.touched.conditions &&
                                formik.errors.conditions
                                  ? formik.errors.conditions
                                  : ''
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {step === 2 && (
                    <div className="flex flex-col mb-6 gap-1">
                      <TextField
                        required
                        error={
                          formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : ''
                        }
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        label=""
                        name="password"
                        type="password"
                        placeholder={t('signup-login:form.password')}
                      />
                      <TextField
                        required
                        error={
                          formik.touched.password_confirmation &&
                          formik.errors.password_confirmation
                            ? formik.errors.password_confirmation
                            : ''
                        }
                        onChange={formik.handleChange}
                        value={formik.values.password_confirmation}
                        label=""
                        name="password_confirmation"
                        type="password"
                        placeholder={t('signup-login:form.password_confirm')}
                      />
                    </div>
                  )}
                  {step === 3 && (
                    <>
                      <Title1
                        text={t('signup-login:signup-end')}
                        className="text-center"
                      />
                      {step === 3 && <br />}
                      <SubTitle1
                        className="text-center text-lg mb-5"
                        text={t('signup-login:signup-confirm')}
                      />
                    </>
                  )}

                  {step !== 3 && (
                    <Button
                      onclick={() => formik.handleSubmit()}
                      className="mb-8"
                      isLoading={userMobx.user.loading}
                    >
                      {t('signup-login:signup_')}
                    </Button>
                  )}
                  {/* <div className="w-full h-[1px] bg-[#edefff] mb-8" />
                  <div className="flex md:flex-col gap-4">
                    <Button variant="outLine">
                      <div className="flex gap-2 items-center">
                        <img
                          src="/assets/imgs/v2/google-icon-sso.svg"
                          alt="google"
                        />
                        <span className='hidden sm:block'>Google</span>
                      </div>
                    </Button>
                    <Button variant="outLine">
                      <div className="flex gap-2 items-center">
                        <img
                          src="/assets/imgs/v2/apple-icon-sso.svg"
                          alt="google"
                        />
                        <span className='hidden sm:block'>Apple</span>
                      </div>
                    </Button>
                    <Button variant="outLine">
                      <div className="flex gap-2 items-center">
                        <img
                          src="/assets/imgs/v2/facebook-icon-sso.svg"
                          alt="google"
                        />
                        <span className='hidden sm:block'>Facebook</span>
                      </div>
                    </Button>
                  </div> */}
                </motion.div>
              </form>
            </FormikProvider>
          </section>
        </div>
      </main>
    </>
  )
}

export default observer(Signup2)
