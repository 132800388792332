import React, { FC, useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion';
import { GalerieVogzterMedia as IGalerieVogzterMedia } from '../../../types-project/GalerieVogzter';
import { GalerieVogzterMedia } from './GalerieVogzterMedia'
import ListOverflowHandlerCategories from './ListOverFlowHandlerCategories'
import styles from './galerie-vogzter-media-styles.module.scss'
import { truncate } from '../../../utils/helpers';

const GalerieVogzterMedias: FC<IGalerieVogzterMediasProps> = ({
  medias,
  lastVogzterRef,
}) => {
  const [itemsOverFlowCategories, setItemsOverFlowCategories] = useState<
    | undefined
    | {
        categories: string[]
        id: string
      }
  >(undefined)


  return (
    <section className={styles['galerie-vogzter-medias']}>
      <div className={styles['grid-galerie-vogzter-medias']}>
        {medias.data.length === 0 && !medias.loading ? (
          <div className="col-span-full text-center mt-5">
            <p className="text-gray-500">
              Aucun Medias ne correspond à votre recherche
            </p>
          </div>
        ) : (
          medias.data.map((media, i) => (
            <div
              ref={medias.data.length === i + 1 ? lastVogzterRef : null}
              key={i}
            >
              <GalerieVogzterMedia
                media={media}
                itemsOverflowCategories={
                  itemsOverFlowCategories?.id === media.objectID
                    ? itemsOverFlowCategories.categories
                    : []
                }
              />
              <div className="py-4">
                <p className={`text-md font-bold`}>
                  {truncate(media.brand.name, 20)}
                </p>
                <div
                  className="my-1 cursor-default z-[4] absolute"
                  onClick={(e) => e.preventDefault()}
                >
                  <ListOverflowHandlerCategories
                    categories={media.brand.categories}
                    nextCallbackPressed={() => {
                      if (itemsOverFlowCategories?.id === media.objectID) {
                        setItemsOverFlowCategories(undefined)
                        return
                      }
                      setItemsOverFlowCategories({
                        categories: media.brand.categories.map(
                          (category) => category.name
                        ),
                        id: media.objectID,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  )
}

GalerieVogzterMedias.propTypes = {
  medias: PropTypes.any.isRequired,
  lastVogzterRef: PropTypes.func.isRequired,
}

type IGalerieVogzterMediasProps = {
  medias: {
    loading: boolean
    data: IGalerieVogzterMedia[]
  }
  lastVogzterRef: (node: HTMLDivElement) => void
}

export default GalerieVogzterMedias
