
import React,{ FC } from 'react'
import PropTypes from 'prop-types'
import BtnClose from '../../../utils/components/Button/BtnClose'

type ListOverflowHandlerProps = {
  list: Array<string>
  onClose?: () => void
}

const ListOverFlowView: FC<ListOverflowHandlerProps> = ({ list, onClose }) => {
  return (
    <div className="flex flex-col w-full z-30 absolute top-0 left-0 right-0 bottom-0 bg-white">
      <div className="flex items-center justify-center h-full">
        <BtnClose
          onClick={onClose}
          className={{
            container:
              'flex-shrink-0 border-primary-blue self-end absolute top-4 right-4',
            icon: 'text-primary-blue',
          }}
        />
        <div className="flex flex-wrap justify-center m-auto scroll-container-y">
          {list
            // .slice(list.length - countHiddenElements, list.length) shows only hidden elements
            .map((item, index) => (
              <span
                className="bg-alice-blue font-bold rounded-2xl text-primary-blue px-4 py-1 inline-block text-[12px] m-1"
                key={index}
              >
                {item}
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}

ListOverFlowView.propTypes = {
  list: PropTypes.array.isRequired,
  onClose: PropTypes.func,
}

export default ListOverFlowView
