import React, { FC } from 'react'
import PropTypes from 'prop-types'
import styles from '../components/styles.module.scss'

const ErrorMsg: FC<Btn1Props> = ({ type, text, errorPosition }) => {
  return <span className={`${styles['err-msg1']} ${errorPosition === 'left' ? 'text-left' : 'text-center'}`}>{text}</span>
}

interface Btn1Props {
  type?: 'darken' | 'lighten'
  text: string
  errorPosition?: 'center' | 'left'
}

ErrorMsg.defaultProps = {
  type: 'lighten',
  errorPosition: 'left',
}

ErrorMsg.propTypes = {
  type: PropTypes.oneOf(['darken', 'lighten']),
  text: PropTypes.string.isRequired,
  errorPosition: PropTypes.oneOf(['center', 'left']),
}

export default ErrorMsg
