import React, { useState, useEffect, CSSProperties, useRef } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'
import Slider from 'react-slick'
import Button from '../../../../utils/components/Ui/v2/buttons/Button'
import { ReturnButton } from '../../../../utils/components/Ui/v2/buttons/ReturnButton'
import { SkipButton } from '../../../../utils/components/Ui/v2/buttons/SkipButton'
import IconStar from '../../../../assets/images/icons/icon-star.svg'
import userMobx from '../../../../mobx/user'
import { CardPricePROPS } from '../../../../types-project/Client'
import LogoRespire from '../../../../assets/images/icons/logo-respire-accessPlatform.svg'
import LogoHeetch from '../../../../assets/images/icons/logo-heetch-accessPlatform.svg'
import LogoCaprisun from '../../../../assets/images/icons/logo-caprisun-accessPlatform.svg'
import LogoWizbii from '../../../../assets/images/icons/logo-wizbii-accessPlatform.svg'
import LogoWellpaper from '../../../../assets/images/icons/logo-wellpaper-accessPlatform.svg'
import LogoOmaMe from '../../../../assets/images/icons/logo-oma&me-accessPlatform.svg'
import LogoEn from '../../../../assets/images/icons/logo-en-accessPlatform.svg'

interface ButtonArrowSlick {
  style?: CSSProperties
  className?: string
  onClick?: () => void
  isBaseCreatorDefault?: boolean
}

export const SampleNextArrow = (props: ButtonArrowSlick) => {
  const { className, style, onClick } = props
  return (
    <div
      className={`next-arrow-slide ${className} `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img
        className="h-4 w-2"
        src="/assets/imgs/button-next-slick.png"
        alt=""
      />
    </div>
  )
}

export const SamplePrevArrow = (props: ButtonArrowSlick) => {
  const { className, style, onClick } = props
  return (
    <div
      className={`perv-arrow-slide ${className}  `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src="/assets/imgs/button-prev-slick.png" alt="" />
    </div>
  )
}

const listComment = [
  {
    firstName: 'Solenne',
    lastName: 'Hernadez',
    job: 'Content Manager',
    comment:
      "Je suis super contente des perfs Vogz !  N'hésite pas à féliciter Priscillia P, son contenu pète les scores !",
    id: 1,
    brand: 'En voiture Simone',
    image:
      'https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/Solenne-Hernandez.png',
  },
  {
    firstName: 'Jordan',
    lastName: 'Nacmias',
    job: 'CEO',
    comment:
      "Je suis super contente des perfs Vogz !  N'hésite pas à féliciter Priscillia P, son contenu pète les scores !",
    id: 2,
    brand: 'BodyCross',
    image:
      'https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/Jordan-Nacmias.png',
  },
  {
    firstName: 'Lauren',
    lastName: 'Klein',
    job: 'Social Média Manager',
    comment:
      "La solution parfaite pour gérer la création de contenu de l'agence, une seule facture ça change tout",
    id: 3,
    brand: 'Dentsu',
    image:
      'https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/Lauren-Klein.png',
  },
  {
    firstName: 'Charles Alexandre',
    lastName: 'MAR',
    job: 'Responsable SMA',
    comment:
      'On gagne du temps et on produit à notre rythem depuis 2 ans, hyper pratique',
    id: 4,
    brand: 'Espace Concours',
    image:
      'https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/Charles-Alexandre.png',
  },
  {
    firstName: 'Dan ',
    lastName: 'Biton',
    job: 'Directeur Agence',
    comment:
      'Des centaines de contenus produits, le système de casting est super utile pour répondre aux besoins des nos clients',
    id: 5,
    brand: 'Seer',
    image:
      'https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/Dan-Bitton.png',
  },
]

interface AccessPlatformProp {
  onBack?: () => void
  onNext?: () => void
  isBaseCreatorDefault?: boolean
}

const AccessPlatform: React.FC<AccessPlatformProp> = ({
  onBack,
  onNext,
  isBaseCreatorDefault,
}) => {
  const { t } = useTranslation()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 2,
    slidesToShow: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const [isYear, setIsYear] = useState(false)
  const [isBaseCreator, setIsBaseCreator] = useState(isBaseCreatorDefault)
  const [selectedPlan, setSelectedPlan] = useState<CardPricePROPS | undefined>(
    undefined
  )

  // eslint-disable-next-line no-restricted-globals
  const param = new URLSearchParams(location.search)
  const referralUrl = param!?.get('referral')

  const [parentCode, setParentCode] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const priceCard = useRef<HTMLDivElement | null>(null)
  const priceBanner = useRef<HTMLDivElement | null>(null)
  const container = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const referral = sessionStorage.getItem('referral')

    if ((referral && Object.keys(referral ?? {}).length > 0) || referralUrl) {
      if (referral) {
        setParentCode(JSON.parse(referral))
      } else if (referralUrl) {
        setParentCode(referralUrl)
      }
    }
  }, [])

  const handleGoPremium = () => {
    if (!selectedPlan) {
      return
    }
    const subscriptionId = selectedPlan?._id
    if (userMobx.getUserInfos()) {
      if (isBaseCreatorDefault) {
        userMobx.upgradeBaseCreator(subscriptionId, (data) => {
          window.location.href = data
        })
      } else {
        userMobx.goPremium(subscriptionId, parentCode, (data) => {
          sessionStorage.removeItem('referral')
          window.location.href = data
        })
      }
    } else {
      if (parentCode) {
        sessionStorage.setItem('referral', JSON.stringify(parentCode))
      }
      navigate('/login')
    }
  }

  useEffect(() => {
    userMobx.getPublicPlans()
    userMobx.getSubscriptionInfo()
  }, [])
  const { loading, data, error } = userMobx.publicPlans
  const { data: subscriptionInfo } = userMobx.subscriptionInfo

  useEffect(() => {
    const duration =
      subscriptionInfo?.subscription_plan.payment_frequency === 'year'
        ? 'year'
        : 'month'
    setIsYear(duration === 'year')
    const plan = data?.find(
      (plan) =>
        plan.base_creator_activated === isBaseCreatorDefault &&
        plan.premium_activated === true &&
        plan.payment_frequency === duration
    )
    setSelectedPlan(plan)
  }, [data, subscriptionInfo])

  const handleChangeTime = (check: boolean) => {
    setIsYear(check)
    const time = check ? 'year' : 'month'
    const plan = data?.find(
      (plan) =>
        plan.base_creator_activated === isBaseCreator &&
        plan.premium_activated === true &&
        plan.payment_frequency === time
    )
    setSelectedPlan(plan)
  }

  const handleChangeBase = (check: boolean) => {
    setIsBaseCreator(check)
    const time = isYear ? 'year' : 'month'
    const plan = data?.find(
      (plan) =>
        plan.base_creator_activated === check &&
        plan.premium_activated === true &&
        plan.payment_frequency === time
    )
    setSelectedPlan(plan)
  }

  const settingsSlideLogo = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    if (!container.current) {
      return
    }
    const handleScroll = () => {
      const priceCardPosition = priceCard.current?.getBoundingClientRect()
      if (priceCardPosition?.top && priceCardPosition?.top <= 0) {
        priceBanner.current?.classList.add('show-banner-price')
      } else {
        priceBanner.current?.classList.remove('show-banner-price')
      }
    }
    container.current.addEventListener('scroll', handleScroll)

    return () => {
      container.current?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className="max-w-[1180px] w-full md:w-auto mx-auto shadow-access-platform pb-[250px] overflow-y-auto h-screen"
      ref={container}
    >
      <div className="flex justify-between">
        {onBack && <ReturnButton onClick={onBack} />}
        {onNext && <SkipButton onClick={onNext} />}
      </div>
      {/*  */}
      <div className="mt-7 px-6 md:px-12 mb-[140px]">
        <div className="flex flex-col gap-16 items-center mb-12">
          <div className="flex flex-col gap-6 items-center text-[#0C150A]">
            <div className="text-3xl font-semibold">
              {t('access-platform:header:title')}
            </div>
            <div className="text-sm font-light">
              {t('access-platform:header:sub-title')}
            </div>
          </div>
          <div className="flex flex-col gap-6 items-center">
            <div className="flex gap-10 items-center mb-6">
              <div
                className={`text-base font-semibold ${
                  isYear === false ? 'text-[#303056]' : 'text-[#D7D9E9]'
                }`}
              >
                {t('access-platform:month')}
              </div>
              <div className="relative inline-block w-[86px] h-7">
                <input
                  checked={isYear}
                  onChange={(e) => handleChangeTime(e.target.checked)}
                  id="switch-component"
                  type="checkbox"
                  className="peer appearance-none w-[86px] h-7 bg-[#D7D9E9] rounded-full checked:bg-[#7885ED] cursor-pointer transition-colors duration-300"
                />
                <label
                  htmlFor="switch-component"
                  className="absolute top-[4px] left-0 translate-x-[4px] shadow shadow-[#00000033] w-5 h-5 bg-white rounded-full transition-transform duration-300 peer-checked:translate-x-[62px] cursor-pointer"
                />
              </div>
              <div className="flex items-center gap-2">
                <div
                  className={`text-base font-semibold ${
                    isYear === true ? 'text-[#303056]' : 'text-[#D7D9E9]'
                  }`}
                >
                  {t('access-platform:year')}
                </div>
                <div className="px-2 py-1 font-semibold text-xs bg-main-blue-dark text-white rounded-xl">
                  {t('access-platform:header:save')} 37%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col-reverse md:flex-row gap-10 ${
            isBaseCreatorDefault ? 'justify-center' : 'justify-between'
          }`}
        >
          {!isBaseCreatorDefault && (
            <div className="flex flex-col gap-4 w-full md:w-[540px]">
              <div className="shadow-access relative z-10 flex gap-5 py-10 px-12 items-start rounded-2xl border border-[#DCE0EF]">
                <div className="mt-1">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#7885ED" />
                    <path
                      d="M6.25 12.8675L10.8333 17.4999L20 8.7499"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-base font-semibold text-[#303056]">
                    {t('access-platform:introduce.administrator')}
                  </div>
                  <div className="text-sm font-light text-[#7C7C7C]">
                    {t('access-platform:introduce.sub-administrator')}
                  </div>
                </div>
              </div>
              <div className="shadow-access relative z-10 flex gap-5 py-10 px-12 items-start rounded-2xl border border-[#DCE0EF]">
                <div className="mt-1">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#7885ED" />
                    <path
                      d="M6.25 12.8675L10.8333 17.4999L20 8.7499"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-base font-semibold text-[#303056]">
                    {t('access-platform:introduce.messaging')}
                  </div>
                  <div className="text-sm font-light text-[#7C7C7C]">
                    {t('access-platform:introduce.sub-messaging')}
                  </div>
                </div>
              </div>
              <div className="shadow-access relative z-10 flex gap-5 py-10 px-12 items-start rounded-2xl border border-[#DCE0EF]">
                <div className="mt-1">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#7885ED" />
                    <path
                      d="M6.25 12.8675L10.8333 17.4999L20 8.7499"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-base font-semibold text-[#303056]">
                    {t('access-platform:introduce.collab-management')}
                  </div>
                  <div className="text-sm font-light text-[#7C7C7C]">
                    {t('access-platform:introduce.sub-collab-management')}
                  </div>
                </div>
              </div>
              <div className="shadow-access relative z-10 flex gap-5 py-10 px-12 items-start rounded-2xl border border-[#DCE0EF]">
                <div className="mt-1">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#7885ED" />
                    <path
                      d="M6.25 12.8675L10.8333 17.4999L20 8.7499"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-base font-semibold text-[#303056]">
                    {t('access-platform:introduce.add-brands')}
                  </div>
                  <div className="text-sm font-light text-[#7C7C7C]">
                    {t('access-platform:introduce.sub-add-brands')}
                  </div>
                  <div className="text-xs font-normal text-[#303056]">
                    <span className="font-bold">+100€</span>/
                    {t('access-platform:introduce.sub-add-brands-price')}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="mx-auto w-full md:mx-0 sm:w-[420px] fit-height rounded-3xl shadow-lg relative"
            ref={priceCard}
          >
            {isBaseCreatorDefault && (
              <div className="text-xs font-black text-[#FFFFFF] px-2 py-1 rounded-md bg-[#7885ED] shadow-lg absolute top-4 left-4">
                {t('access-platform:introduce.price-list.tag')}
              </div>
            )}
            <div className="px-[90px] py-[50px] rounded-t-3xl text-center">
              {loading ? (
                <div className="flex justify-center">
                  <ReactLoading
                    type="spokes"
                    color="#000"
                    width={22}
                    className="flex items-center"
                  />
                </div>
              ) : (
                <div className="text-[#303056] text-6xl font-bold mt-5 mb-5">
                  {selectedPlan?.price}€
                  <span className="text-xl font-semibold">
                    /
                    {isYear
                      ? `${t('access-platform:year')}`
                      : `${t('access-platform:month')}`}
                  </span>
                </div>
              )}
              <div className="text-sm font-light text-[#7C7C7C] mb-6">
                {t('access-platform:introduce.price-list.description')}
              </div>
              <Button
                isLoading={userMobx.company.loading}
                onclick={handleGoPremium}
              >
                {t('access-platform:introduce.price-list.start')}
              </Button>
            </div>
            {!isBaseCreatorDefault && (
              <div className="py-6 px-6 rounded-b-3xl bg-[#303056]">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-xs font-black text-[#FFFFFF] px-2 py-[2px] rounded-md bg-[#7885ED] shadow-lg">
                    {t('access-platform:introduce.price-list.tag')}
                  </div>
                  <div className="w-[250px] text-base font-bold text-white">
                    {t('access-platform:introduce.price-list.creator-base')}{' '}
                    (+15€
                    <span className="font-light">
                      /{t('access-platform:month')}
                    </span>
                    )
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="relative inline-block w-[46px] h-7">
                    <input
                      onChange={(e) => handleChangeBase(e.target.checked)}
                      id="switch-popular"
                      type="checkbox"
                      className="peer appearance-none w-[46px] h-7 bg-[#D7D9E9] rounded-full checked:bg-[#7885ED] cursor-pointer transition-colors duration-300"
                    />
                    <label
                      htmlFor="switch-popular"
                      className="absolute top-[4px] left-0 translate-x-[4px] shadow shadow-[#00000033] w-5 h-5 bg-white rounded-full transition-transform duration-300 peer-checked:translate-x-[22px] cursor-pointer"
                    />
                  </div>
                  <div className="w-[250px] text-xs font-normal text-[#E7E7F4]">
                    {t('access-platform:introduce.price-list.switch-title')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*  */}
      <div className="slick-logo mb-16">
        <Slider {...settingsSlideLogo}>
          <div className="current-logo">
            <img
              src="https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/en-voiture-simone.png"
              alt=""
            />
          </div>
          <div className="current-logo">
            <img
              src="https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/bodycross.png"
              alt=""
            />
          </div>
          <div className="current-logo">
            <img
              src="https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/denstu.png"
              alt=""
            />
          </div>
          <div className="current-logo">
            <img
              src="https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/espace-concours.png"
              alt=""
            />
          </div>
          <div className="current-logo">
            <img
              src="https://vogz-other-media-resources.fra1.cdn.digitaloceanspaces.com/Casting/testimony/seer.png"
              alt=""
            />
          </div>
        </Slider>
      </div>
      {/*  */}
      <div className="px-12 flex flex-col items-center mb-20">
        <div className="flex flex-col gap-5 items-center text-center">
          <div className="text-3xl font-semibold text-[#0C150A]">
            {t('access-platform:feed_back.title')}
          </div>
          <div className="text-sm font-light text-[#0C150A] w-[380px]">
            {t('access-platform:feed_back.sub-title')}
          </div>
        </div>
      </div>
      {/*  */}
      <div className="relative mb-[130px] slick-button">
        <Slider {...settings}>
          {listComment.map((item) => {
            return (
              <div>
                <div
                  key={item.id}
                  className="flex flex-col h-[250px] gap-4 py-9 px-12 border border-[#EAEAF7] rounded-3xl inner-item-slick"
                >
                  <div className="flex items-center gap-5">
                    <div className="flex-shrink-0">
                      <img
                        className="w-16 h-16 rounded-full bg-no-repeat"
                        src={item.image}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-base font-semibold text-[#0C150A]">
                        {item.firstName} {item.lastName}
                      </div>
                      <div className="flex items-center gap-1 text-sm font-normal text-[#ADADAD]">
                        {item.job}-{item.brand}
                        {/* <img src="/assets/imgs/logo-AccessPlatform.png" alt="" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="max-h-[90px]">
                    <div className="text-ellipsis text-lg font-normal text-[#303056] mb-4">
                      {item.comment}
                    </div>
                    <ul className="flex gap-1">
                      {Array.from({ length: 5 }).map((_, index) => {
                        return (
                          <li key={index} className="cursor-pointer">
                            <i className={`text-[#7885ED] fa fa-star`} />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      {/*  */}
      <div className="flex flex-col items-center text-center gap-6 text-[#0C150A] mb-20">
        <div className="text-3xl font-semibold">
          {t('access-platform:questions.title')}
        </div>
        <div className="text-base font-light w-[400px]">
          {t('access-platform:questions.sub-title')}
        </div>
      </div>
      {/*  */}
      <div className="flex flex-col gap-8 px-6 md:px-12">
        <FeedBackForm
          title={t('access-platform:questions.questions-form.question1')}
        >
          {t('access-platform:questions.questions-form.description1')}
        </FeedBackForm>
        <FeedBackForm
          title={t('access-platform:questions.questions-form.question2')}
        >
          {t('access-platform:questions.questions-form.description2')}
        </FeedBackForm>
        <FeedBackForm
          title={t('access-platform:questions.questions-form.question3')}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                'access-platform:questions.questions-form.description3'
              ),
            }}
          />
        </FeedBackForm>
        <FeedBackForm
          title={t('access-platform:questions.questions-form.question4')}
        >
          {t('access-platform:questions.questions-form.description4')}
        </FeedBackForm>
      </div>
      <div
        className="w-full max-w-[1180px] justify-between items-center rounded-3xl bg-[#7885ED] py-3 sm:py-5 px-4 sm:px-9 md:px-[100px] fixed md:py-8 mb-3 top-0 z-20 hidden opacity-0 transition-all"
        ref={priceBanner}
      >
        <div className="flex gap-5 md:gap-[50px] items-center">
          <div className="text-2xl md:text-5xl font-bold text-white">
            {selectedPlan?.price}€
            <span className="text-lg font-semibold">
              /
              {isYear
                ? `${t('access-platform:year')}`
                : `${t('access-platform:month')}`}
            </span>
          </div>
          <div className="max-w-[300px] text-sm md:text-lg font-semibold text-[#D6DBFF] uppercase">
            {t('access-platform:pricing_banner.title')}
          </div>
        </div>
        <Button
          className="w-[140px]"
          variant="white"
          isLoading={userMobx.company.loading}
          onclick={handleGoPremium}
        >
          {t('access-platform:pricing_banner.start')}
        </Button>
      </div>
    </div>
  )
}

interface FeedBackProp {
  title: String
  children?: React.ReactNode
}

const FeedBackForm: React.FC<FeedBackProp> = ({
  title,
  children,
}: FeedBackProp) => {
  const [isOpenDescription, setIsOpenDescription] = useState(false)
  return (
    <div className="shadow-access h-auto rounded-xl py-10 px-10 md:px-[165px] border border-[#DCE0EF]">
      <div className="flex justify-between items-center">
        <div className="text-base font-semibold text-[#303056]">{title}</div>
        <button
          className="w-5 h-5 text-[#7885ED]"
          onClick={() => setIsOpenDescription(!isOpenDescription)}
          type="button"
        >
          <i
            className={`${
              isOpenDescription ? 'rotate-[270deg]' : 'rotate-90'
            } fa-regular fa-angle-right`}
          />
        </button>
      </div>
      {children && isOpenDescription && (
        <div className="text-sm font-normal text-[#7C7C7C] mt-4">
          {children}
        </div>
      )}
    </div>
  )
}

AccessPlatform.defaultProps = {
  onBack: undefined,
  onNext: undefined,
  isBaseCreatorDefault: false,
}

AccessPlatform.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  isBaseCreatorDefault: PropTypes.bool,
}

export default observer(AccessPlatform)
export { FeedBackForm }
