import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '../../../../utils/components/Ui/v2/buttons/Button'
import { ReturnButton } from '../../../../utils/components/Ui/v2/buttons/ReturnButton'


interface Step5Props {
    onNext?: () => void
  }

const Step6:React.FC<Step5Props> = ({onNext}
) => {
  return (
    <div className="max-w-[1180px] mx-auto  mt-[210px]">
        <div className='flex flex-col items-center'>
            <div className='flex flex-col items-center mb-14'>
                <h1 className='text-[40px] font-black text-[#615AF6] mb-3'>
                    Votre brief a bien été envoyé en validation 🎉
                </h1>
                <div className='text-xl font-normal text-[#7C7C7C] w-[585px] text-center'>
                    Nos équipes s’occupent de modérer votre brief ! Cette étape peux prendre jusqu’à 48h. Vous recevrez un mail, vous confirmant la modération de votre brief et l’activation de votre casting !
                </div>
            </div>
            <div className='flex flex-col items-center bg-[#F8F8FF] w-[950px] rounded p-8 mb-14'>
                <div className='flex flex-col items-center gap-1 text-center mb-5'>
                    <div className='text-3xl font-black text-[#3A4161]'>Rappel 👀</div>
                    <div className='max-w-[360px] text-base font-light text-[#3A4161]'>
                        Vous avez déposer un brief pour un <span className='text-base font-black text-[#3A4161]'>casting simple</span> ce type de casting se décompose en deux temps :
                    </div>
                </div>
                <div className='relative flex justify-between w-[545px] rounded-xl bg-white shadow-xl py-4 px-12 mb-5'> 
                    <div className='flex flex-col items-center text-[#38B2F6]'>
                        <div className='text-2xl font-black'>14 jours</div>
                        <div className='text-base font-bold'>Phase de recrutement</div>
                    </div>
                    <div className='flex flex-col items-center text-[#7E44EE]'>
                        <div className='text-2xl font-black'>7 jours</div>
                        <div className='text-base font-bold'>Phase de sélection</div>
                    </div>
                    <div className='absolute top-8 left-2/4 -translate-x-2/4'>
                        <svg width="121" height="8" viewBox="0 0 121 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M120.354 4.35355C120.549 4.15829 120.549 3.84171 120.354 3.64645L117.172 0.464466C116.976 0.269204 116.66 0.269204 116.464 0.464466C116.269 0.659728 116.269 0.976311 116.464 1.17157L119.293 4L116.464 6.82843C116.269 7.02369 116.269 7.34027 116.464 7.53553C116.66 7.7308 116.976 7.7308 117.172 7.53553L120.354 4.35355ZM0 4.5H120V3.5H0V4.5Z" fill="#3A4161"/>
                        </svg>
                    </div>
                </div>
                <div className='w-[370px] text-center text-base font-semibold text-[#3A4161]'>
                    Au delà de ce temps, chaque jour supplémentaires pour acheter vos VOGZ, vous sera facturé 10€
                </div>
            </div>
            <Button className='h-11 w-[360px]'>
                C’est compris !
            </Button>
        </div>
    </div>
  )
}

Step6.defaultProps = {
    onNext: () => {}
}

Step6.propTypes = {
    onNext: PropTypes.func
}

export default Step6
