import React from 'react'
import Slider from "react-slick";

const Slide = () => {
  const responsiveSlide = window.innerWidth
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        centerPadding: "50px",
        slidesToScroll: 1,
        vertical: responsiveSlide > 768,
        centerMode:true,
        slidesToShow: 1,
        autoplay: true,
      };
  return (
    <div
    className="w-full h-[550px] md:w-1/2 md:h-full bg-no-repeat bg-cover bg-center md:rounded-3xl slick-register"
    style={{
      backgroundImage: `url('/assets/imgs/v2/register-banner-left.png')`,
    }}
  >
        <Slider {...settings}>
      {Array.from({ length: 11 }).map((_, index) => (
        <div key={index}>
        <div className='flex justify-center items-center each-slide'>
            <img
            src={`/assets/imgs/v2/slide-register/slide-${index + 1}.png`}
            alt="slick-slide"
          />
        </div>
        </div>
      ))}
    </Slider>
</div>
  )
}

export default Slide;