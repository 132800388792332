import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import Button from '../../../../utils/components/Ui/v2/buttons/Button'
import { ReturnButton } from '../../../../utils/components/Ui/v2/buttons/ReturnButton'

const MOCK_DATA = [
  {
    key: '1',
    name: 'Test Produit',
    describe:
      'Demandez aux Vogzters de réalisez l’essai de votre produit ! Pas de panique : vous n’envoyez votre produit qu’aux Vogzter(s) sélectionné(s) !',
    image: '/assets/imgs/v2/brief/frame-1.png',
  },
  {
    key: '2',
    name: 'chorégraphie',
    describe:
      'Surfez sur la tendance : laissez les Vogzters réaliser leur meilleure danse pour promouvoir votre marque !',
    image: '/assets/imgs/v2/brief/frame-2.png',
  },
  {
    key: '3',
    name: 'créativité',
    describe:
      'Laissez-vous surprendre ! Les Vogzters sont libres de proposer ce qu’ils veulent. Leurs seuls mots d’ordre : créativité et originalité !',
    image: '/assets/imgs/v2/brief/frame-3.png',
  },
  {
    key: '4',
    name: 'acting',
    describe:
      'Controlez votre discours : proposez un script aux Vogzters qu’ils liront et joueront comme de véritables acteurs !',
    image: '/assets/imgs/v2/brief/frame-4.png',
  },
  {
    key: '5',
    name: 'témoignage',
    describe:
      'Laissez les Vogzters partager leur expérience avec votre produit ou votre service. Un bon moyen pour gagner en crédibilité !',
    image: '/assets/imgs/v2/brief/frame-5.png',
  },
]


interface Step3Props {
  onBack?: () => void
  onNext?: () => void
}

const Step3:React.FC<Step3Props> = ({onBack,onNext}) => {
  const { t } = useTranslation()

  return (
    <div className="max-w-[1000px] mx-auto py-8">
      <div className="mb-8">
        <ReturnButton onClick={onBack}/>
      </div>
      <div>
        <h1 className="font-semibold text-4xl mb-4 text-center">
          Quel type de brief souhaitez-vous lancer ?
        </h1>
        <div className="mb-6 text-[17px] text-[#7c7c7c] text-center">
          Séléctionnez le type qui correspond le mieux à vos<br/> besoins parmis nos
          5 choix de brief !
        </div>
        <div className="flex flex-wrap justify-center gap-6">
          {MOCK_DATA.map((item) => (
            <div className="bg-white rounded shadow-md p-5 w-[310px]">
              <img src={item.image} className="" alt="vog-logo" />
              <div className="mt-6">
                <div className="text-[17] font-semibold uppercase mb-2">
                  {item.name}
                </div>
                <div className="text-[13px] text-[#3a4161] leading-4">
                  {item.describe}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div  className='w-full h-[1px] bg-[#eee] mb-10 mt-10'/>
        <div className='flex justify-end'>
          <Button className='w-[150px]' onclick={onNext}>
          Commencer
          </Button>
        </div>
      </div>
    </div>
  )
}

Step3.defaultProps = {
  onBack: () => {},
  onNext: () => {}
}

Step3.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
}

export default Step3
