import React, { FC, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from '../../components/styles.module.scss'
import ErrorMsg from '../../errors/ErrorMsg'

const TextField: FC<Btn1Props> = ({
  required,
  type,
  name,
  label,
  id,
  placeholder,
  onChange,
  error,
  value,
  isShort,
  errorPosition,
  backgroundClass,
  hideError,
  ...props
}) => {
  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordInput = useRef<HTMLInputElement>()
  const togglePasswordVisibility = () => {
    const input = passwordInput.current
    if (input?.getAttribute('type') === 'password') {
      input.setAttribute('type', 'text');
      setPasswordVisible(true);
    } else {
      input?.setAttribute('type', 'password');
      setPasswordVisible(false);
    }
  }
  return (
    <div
      className={`w-full flex flex-col relative ${
        error ? styles['errored'] : ''
      }  ${!isShort ? 'h-[60px]' : ''}`}
    >
      {label && (
        <label htmlFor={name}>
          <span className=' font-semibold text-[#7D7D7D] text-sm'>{label}</span>
          {required && <span className={styles['text-input-required']}>*</span>}
        </label>
      )}
      <input
        ref={passwordInput as any}
        type={type}
        className={`h-[37px] rounded-[100px] ${!error ?  backgroundClass : 'bg-[#ffcdcd]'}  placeholder-[#cacaca] text-sm p-4`}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        {...props}
      />
      {type === 'password' && (
          <i onClick={togglePasswordVisibility} id={id} className={`absolute text-base right-2 top-2 fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`} />
      )}
      {error && !hideError && <ErrorMsg text={error} errorPosition={errorPosition} />}
    </div>
  )
}

interface Btn1Props extends React.HTMLProps<HTMLInputElement> {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  placeholder?: string
  name?: string
  label: string
  id?: string
  error?: string
  isShort?: boolean
  errorPosition?: 'left' | 'center',
  backgroundClass?: string,
  hideError?: boolean
}

TextField.defaultProps = {
  required: false,
  placeholder: undefined,
  name: undefined,
  error: undefined,
  type: 'text',
  id: undefined,
  value: undefined,
  isShort: false,
  errorPosition: 'left',
  backgroundClass: 'bg-[#fbfbfb]',
  hideError: false
}

TextField.propTypes = {
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  isShort: PropTypes.bool,
  errorPosition: PropTypes.oneOf(['left', 'center']),
  backgroundClass: PropTypes.string,
  hideError: PropTypes.bool
}

export default TextField
