import api, { Endpoint, EndT } from './apiUtils';

const endpoints = new Endpoint()

export const getOpenAIResponse = async (prompt:string) => {
   const { data } = await api.post(
          endpoints.getEndpoint(EndT.openAI),
          { prompt }
        )
  return data?.data?.replace("\n\n", "");
};
