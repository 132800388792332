import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Navigation,
  Scrollbar,
  Manipulation,
  Autoplay,
  Pagination,
  EffectCards,
} from 'swiper'
import { useTranslation } from 'react-i18next'
import Button from '../../../../utils/components/Ui/v2/buttons/Button'

interface Step2Props{
  onNext?: () => void
}

const Step2:React.FC<Step2Props> = ({onNext}) => {
  const { t } = useTranslation()
  const swiperRef = useRef<any>(null)
  const responsiveSlide = window.innerWidth

  return (
    <div className="flex items-center h-full swiper-step2">
      <Swiper
        className=""
        modules={[
          Navigation,
          Scrollbar,
          Manipulation,
          Autoplay,
          Pagination,
          EffectCards,
        ]}
        effect="coverflow"
        initialSlide={0}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        // autoplay={{
        //   delay: 8000,
        // }}
        centeredSlides
        slidesPerView={responsiveSlide > 1536 ? 3 : responsiveSlide > 768 ? 2 : 1}
        spaceBetween={0}
        direction="horizontal"
        // pagination={{ clickable: true }}
      >
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className="relative w-[465px] rounded-3xl pt-10 pb-8 px-8 bg-white max-h-[800px] mr-0 md:mr-[165px] flex flex-col justify-between"
              style={{
                height: 'calc(100vh - 40px)',
                boxShadow: '0 4px 4px 0 #dbe0eb',
                border: '3px solid #eef8ff',
                transform: isActive ? 'scale(1)' : 'scale(0.9)',
                opacity: isActive ? 1 : 0.8,
              }}
            >
              <div>
                <div className="text-center mb-10">
                  <span
                    className="font-semibold text-[45px]"
                    role="img"
                    aria-label="flexed biceps"
                  >
                    💪
                  </span>
                </div>
                <div
                  className="text-[25px] text-center"
                  dangerouslySetInnerHTML={{
                    __html: t('onboard:swipper:title1'),
                  }}
                />
                <img
                  src="/assets/imgs/v2/swiper/frame-1.png"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[650px]"
                  alt="vog-logo"
                  style={{
                    maxWidth: isActive ? 'unset' : '100%'
                  }}
                />
              </div>
              <div className="flex justify-end">
                <Button
                  className="w-[130px]"
                  onclick={() => swiperRef.current.slideNext()}
                >
                  <span className="mr-2">{t('onboard:swipper:next')}</span>
                  <img src="/assets/imgs/v2/next-arrow.svg" alt="vog-logo" />
                </Button>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className="relative w-[465px] rounded-3xl pt-10 pb-8 px-8 bg-white max-h-[800px] mr-0 md:mr-[165px] flex flex-col justify-between"
              style={{
                height: 'calc(100vh - 40px)',
                border: '3px solid #eef8ff',
                transform: isActive ? 'scale(1)' : 'scale(0.8)',
                opacity: isActive ? 1 : 0.8,
              }}
            >
              <div>
                <div className="text-center mb-10">
                  <span
                    className="font-semibold text-[45px]"
                    role="img"
                    aria-label="flexed biceps"
                  >
                    🎥
                  </span>
                </div>
                <div
                  className="text-[25px] text-center"
                  dangerouslySetInnerHTML={{
                    __html: t('onboard:swipper:title2'),
                  }}
                />
                <img
                  src="/assets/imgs/v2/swiper/frame-2.png"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[650px]"
                  alt="vog-logo"
                  style={{
                    maxWidth: isActive ? 'unset' : '100%'
                  }}
                />
              </div>
              <div className="flex justify-between">
                <Button
                  className="w-[130px]"
                  onclick={() => swiperRef.current.slidePrev()}
                >
                  <span className="mr-2">{t('onboard:swipper:previous')}</span>
                  <img
                    src="/assets/imgs/v2/next-arrow.svg"
                    className="transform rotate-180"
                    alt="vog-logo"
                  />
                </Button>
                <Button
                  className="w-[130px]"
                  onclick={() => swiperRef.current.slideNext()}
                >
                  <span className="mr-2">{t('onboard:swipper:next')}</span>
                  <img src="/assets/imgs/v2/next-arrow.svg" alt="vog-logo" />
                </Button>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({ isActive }) => (
            <div
              className="relative w-[465px] rounded-3xl pt-10 pb-8 px-8 bg-white max-h-[800px] flex flex-col justify-between"
              style={{
                height: 'calc(100vh - 40px)',
                border: '3px solid #eef8ff',
                transform: isActive ? 'scale(1)' : 'scale(0.8)',
                opacity: isActive ? 1 : 0.8,
              }}
            >
              <div>
                <div className="text-center mb-10">
                  <span
                    className="font-semibold text-[45px]"
                    role="img"
                    aria-label="flexed biceps"
                  >
                    ❤️
                  </span>
                </div>
                <div
                  className="text-[25px] text-center"
                  dangerouslySetInnerHTML={{
                    __html: t('onboard:swipper:title3'),
                  }}
                />
                <img
                  src="/assets/imgs/v2/swiper/frame-3.png"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[650px]"
                  alt="vog-logo"
                  style={{
                    maxWidth: isActive ? 'unset' : '100%'
                  }}
                />
              </div>
              <div className="flex justify-between">
                <Button
                  className="w-[130px]"
                  onclick={() => swiperRef.current.slidePrev()}
                >
                  <span className="mr-2">{t('onboard:swipper:previous')}</span>
                  <img
                    src="/assets/imgs/v2/next-arrow.svg"
                    className="transform rotate-180"
                    alt="vog-logo"
                  />
                </Button>
                <Button
                  className="w-[130px]"
                  onclick={onNext}
                >
                  <span className="mr-2">{t('onboard:swipper:next')}</span>
                  <img src="/assets/imgs/v2/next-arrow.svg" alt="vog-logo" />
                </Button>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

Step2.defaultProps = {
  onNext: () => {}
}
Step2.propTypes = {
  onNext: PropTypes.func
}

export default Step2
