/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import Select, { DropdownIndicatorProps, OptionProps } from 'react-select'
import { AnimatePresence, motion } from 'framer-motion'
import styles from './components/dropdown-checkbox.module.scss'
import i18n from '../../../../i18n'

export type DropdownCheckboxOption<T = any> = {
  isSelectedDefault?: boolean,
  id: string,
  label: string,
  data?: T,
  onClick?: (selected: boolean) => void
}
interface DropdownCheckboxType<T> {
  placeholder: string
  searchPlaceholder?: string
  isSearchable?: boolean
  multiSelection?: boolean
  options: Array<DropdownCheckboxOption<T>>
  onElementSelected?: (selectedItem: DropdownCheckboxOption<T>, allSelection: DropdownCheckboxOption<T>[]) => void
  onElementRemoved?: (removedItem: DropdownCheckboxOption<T>, allSelection: DropdownCheckboxOption<T>[]) => void
  classNames?: DropdownCheckboxClassNames
  values?: Array<DropdownCheckboxOption<T>>
  onClick?: () => void;
}

export type DropdownCheckboxClassNames = {
  container?: string
  select?: string
  menu?: string
  menuHidden?: string
}

type SelectionType<T> = {
  data: Array<DropdownCheckboxOption<T>>
  lastOperation: 'add' | 'add-default' | 'remove' | 'none'
  lastOperationElement?: DropdownCheckboxOption<T>
}

type DropdownCheckboxState<T> = {
  search: string,
  menuIsOpen: boolean,
  allSelection: SelectionType<T>
}

class DropdownCheckbox<T = any> extends Component<DropdownCheckboxType<T>>{

  static openedInstance: DropdownCheckbox<any> | undefined

  dropdownRef: HTMLDivElement | null = null;

  state: DropdownCheckboxState<T> = {
    search: '',
    menuIsOpen: false,
    allSelection: {
      // eslint-disable-next-line react/destructuring-assignment
      data: this.props.values ?? [],
      lastOperation: 'none',
      lastOperationElement: undefined,
    } as SelectionType<T>,
  }
  
  componentDidMount(): void {
    const { options, multiSelection, onElementSelected } = this.props

    const defaultSelected = options.filter((option, index) => (multiSelection ? true : index < 1) && option.isSelectedDefault)
    document.addEventListener('mousedown', this.handleClickOutside);
    if (defaultSelected.length < 1) return

    defaultSelected.forEach((item) => {
      if (item.onClick) item.onClick(true)
      if (onElementSelected) onElementSelected(item, defaultSelected)
    })
    if (defaultSelected.length > 0) {
      this.setState((prev: Readonly<typeof this.state>) => {
        const newSelection: SelectionType<T> = {
          ...prev.allSelection,
          data: defaultSelected,
          lastOperation: 'add-default',
          lastOperationElement: defaultSelected.at(defaultSelected.length - 1)
        }
        return { allSelection: newSelection }
      })
    }  
    

  }

  componentDidUpdate(prevProps: Readonly<DropdownCheckboxType<T>>, prevState: Readonly<DropdownCheckboxState<T>>, snapshot?: any): void {
    const { allSelection, menuIsOpen } = this.state
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.values !== this.props.values) {
      this.updateSelectionFromProps();
    }
    if (prevState.allSelection !== allSelection) {
      this.handleAllSelection(prevProps, prevState, snapshot)
    }
    if (prevState.menuIsOpen !== menuIsOpen && menuIsOpen) {
      if (menuIsOpen && DropdownCheckbox.openedInstance && DropdownCheckbox.openedInstance !== this) {
        DropdownCheckbox.openedInstance.setState({ menuIsOpen: false })
      }
      DropdownCheckbox.openedInstance = menuIsOpen ? this : undefined
    }
  }

  private updateSelectionFromProps() {
    const { options, values } = this.props;
    const defaultSelected = options.filter((option) => values?.some((value) => value.id === option.id));
    if (defaultSelected.length < 1) return;
    if (defaultSelected.length > 0) {
      this.setState((prev: Readonly<typeof this.state>) => {
        const newSelection: SelectionType<T> = {
          ...prev.allSelection,
          data: defaultSelected,
          lastOperation: 'add-default',
          lastOperationElement: defaultSelected.at(defaultSelected.length - 1),
        };
        return { allSelection: newSelection };
      });
    }
  }


  componentWillUnmount() {
    if (DropdownCheckbox.openedInstance === this) {
      DropdownCheckbox.openedInstance = undefined
    }

    document.removeEventListener('mousedown', this.handleClickOutside);

  }

  private handleClickOutside = (event: MouseEvent) => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target as Node)) {
      this.setState({ menuIsOpen: false });
    }
  };

  private handleAllSelection(prevProps: Readonly<DropdownCheckboxType<T>>, prevState: Readonly<typeof this.state>, snapshot?: any) {
    const { onElementSelected, onElementRemoved } = this.props
    const { allSelection } = this.state
    if (!allSelection.lastOperationElement) return
    if (['none', 'add-default'].includes(allSelection.lastOperation)) return

    if (allSelection.lastOperation === "add") {
      if (!onElementSelected) return
      onElementSelected(allSelection.lastOperationElement, allSelection.data)
      return
    }
    if (!onElementRemoved) return
    onElementRemoved(allSelection.lastOperationElement, allSelection.data)
  }

  private editSelection(opType: 'add' | 'add-default' | 'remove', element: DropdownCheckboxOption<T>) {
    const { multiSelection } = this.props

    if (opType === 'add') {
      this.setState((prev: Readonly<typeof this.state>) => {
        const newSelection: SelectionType<T> = {
          ...prev.allSelection,
          data: multiSelection ? [...prev.allSelection.data, element] : [element],
          lastOperation: opType,
          lastOperationElement: element
        }
        return { allSelection: newSelection }
      })
      return
    }
    this.setState((prev: Readonly<typeof this.state>) => {
      const elementsLeft = multiSelection ? prev.allSelection.data.filter(item => item.id !== element.id) : []
      return { allSelection: {
        ...prev.allSelection,
        data: elementsLeft,
        lastOperation: 'remove',
        lastOperationElement: element
      }}
    })
  }

  select(id: string) {
    const { options } = this.props
    const { allSelection } = this.state
    const element = options.find(o => id === o.id)
    if (!element || allSelection.data.find(o => element.id === o.id)) return
    this.editSelection('add', element)
    if (element.onClick) element.onClick(true)
  }

  unselect(id: string) {
    const { options } = this.props
    const { allSelection } = this.state
    const element = options.find(o => id === o.id)
    if (!element || !allSelection.data.find(o => element.id === o.id)) return
    this.editSelection('remove', element)
    if (element.onClick) element.onClick(false)
  }

  containsId(id: string) {
    const { options } = this.props
    const element = options.find(o => id === o.id)
    return !!element
  }

  getOption(id: string) {
    const { options } = this.props
    const element = options.find(o => id === o.id)
    return element
  }

  isSelected(id: string) {
    const { allSelection } = this.state
    return !!allSelection.data.find(o => id === o.id)
  }

  render() {
    const { placeholder, searchPlaceholder, isSearchable, options, classNames } = this.props
    const { search, menuIsOpen, allSelection } = this.state
    const motionProps = {
      initial:{ opacity: 0 },
      animate:{ opacity: 1 },
      exit:{ opacity: 0 },
      transition:{ duration: 0.2 }
    }

    return (
      <div
      ref={(node) => { this.dropdownRef = node; }}
      className={`${styles['dropdown-checkbox']} ${classNames?.container ?? ''}`} onClick={() =>{
        const { onClick } = this.props;
        if (onClick) {
          onClick();
        }
      }}>
        <div className={`${styles['select']} ${classNames?.select ?? ''}`}
          onClick={ () => this.setState((prev: Readonly<typeof this.state>) => ({ menuIsOpen: !prev.menuIsOpen }))}
        >
            <div className={styles['title']}>
              {placeholder}
            </div>
            <div className='flex w-5 items-center justify-center cursor-pointer'>
              <AnimatePresence>
                {allSelection.data.length === 0 ? (
                  <motion.div key="dropdown-caret" {...motionProps}>
                    <i className={`${menuIsOpen ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down' }`} />
                  </motion.div>
                ) : (
                  <motion.div key="selection-count" {...motionProps} className='absolute'>
                    <span className={styles['selection-count']} >
                      <b>{allSelection.data.length}</b>
                    </span>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
        </div>
        <div className={`scroll-container-y ${styles['menu']} ${classNames?.menu ?? ''} ${!menuIsOpen && styles['menu-hidden'].concat(' ', classNames?.menuHidden ?? '')}`}>
          {isSearchable && (
            <>
              <span className={styles['search-bar']}>
                <i className='fa-solid fa-search' />
                <input
                  name='searchegbhkjrtebf'
                  id='seaihfe'
                  type='text' 
                  placeholder={searchPlaceholder}
                  value={search} 
                  onChange={(e) => this.setState({ search: e.target.value })} 
                />
              </span>
              <hr />
            </>
          )}
          <AnimatePresence>
            {options.filter(option => option.label.toLowerCase().includes(search.toLowerCase())).map((option, index) => {
              const isSelected = (allSelection.data.find(o => option.id === o.id) !== undefined)
              return (
                <motion.div
                  key={index}
                  {...motionProps}
                >
                  <div
                    className={styles['menu-item']}
                    onClick={() => {
                      this.editSelection(isSelected ? 'remove' : 'add', option)
                      if (option.onClick) {
                        option.onClick(!isSelected)
                      }
                    }}
                  >
                    <input 
                      className='checkbox' 
                      type="checkbox"
                      checked={isSelected} 
                    />
                    {i18n.t(option.label)}
                  </div>
                </motion.div>
              )
            })}
          </AnimatePresence>
        </div>
      </div>
    )
  }

}

export default DropdownCheckbox

