import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import Button from '../../../../utils/components/Ui/v2/buttons/Button'
import TextField from '../../../../utils/components/Ui/v2/inputs/TextField'
import ErrorMsg from '../../../../utils/components/Ui/errors/ErrorMsg'
import userMobx from '../../../../mobx/user'
import DropdownCheckbox, {
  DropdownCheckboxOption,
} from '../../../../utils/components/Ui/dropdown/DropdownCheckbox'
import { Category } from '../../../../types-project/Client'
import Title1 from '../../../../utils/components/Headings/Title1'

export const Step1: React.FC<Step1Props> = ({
  formik,
  isLoading,
  categoryOptions,
  handleAutoFill,
}) => {
  const categoryRef = useRef<DropdownCheckbox>(null)
  const { t } = useTranslation()
  const [categoryClicked, setCategoryClicked] = useState(false)


  return (
    <div className="relative py-10">
      <FormikProvider value={formik}>
        <div className="flex flex-col items-center justify-center h-full max-w-[460px] mx-auto relative">
          <h1 className="font-semibold text-4xl mb-5 text-center">
            {t('onboard:wellCome')}
          </h1>
          <div className="text-center text-note mb-8">
            {t('onboard:short_profile:description')}
          </div>
          <div className="text-center mb-6 text-xl font-semibold">
            {t('onboard:short_profile:brandName')}
          </div>

          <TextField
            onChange={formik.handleChange}
            value={formik.values.brand_name}
            label=""
            name="brand_name"
            placeholder="Votre Marque"
            error={
              formik.touched.brand_name &&
              formik.errors.brand_name
                ? formik.errors.brand_name
                : ''
            }
            errorPosition="center"
            backgroundClass='bg-[#f7f8ff]'
          />
          <div className="flex gap-2 items-center w-full mt-4">
            <Button variant="outLine" className='w-[100px]' size='sm'>
              Web
            </Button>
              <TextField
                isShort
                type="url"
                onChange={formik.handleChange}
                value={formik.values.company_url}
                label=""
                name="company_url"
                placeholder={t('signup-login:form.url_site')}
                backgroundClass='bg-[#f7f8ff]'
                error={
                  formik.errors.company_url
                    ? formik.errors.company_url
                    : ''
                }
                hideError
              />
          </div>
          {formik.errors.company_url && (
                <ErrorMsg text={formik.errors.company_url} />
              )}
          <Button
            onclick={handleAutoFill}
            isLoading={isLoading}
            className="mb-12  mt-12"
          >
            <img src="/assets/imgs/v2/validate.svg" alt="validate" />
            {t('onboard:short_profile:validate')}
          </Button>

          <div className="p-2 rounded bg-[#fbfcff] flex justify-between items-center w-full mb-4">
            <div className="font-bold">{t('onboard:short_profile:target')}</div>
            <DropdownCheckbox
              onClick={() => setCategoryClicked(true)}
              ref={categoryRef}
              isSearchable
              multiSelection
              placeholder={t('myprofil:brand-page.category')}
              searchPlaceholder={t('myprofil:brand-page.search-category')}
              options={categoryOptions}
              onElementSelected={(element, allSelection) => {
                formik.setFieldValue(
                  'categories',
                  allSelection.flatMap((element) =>
                    JSON.parse(JSON.stringify(element.data) ?? '{}')
                  )
                )
              }}
              onElementRemoved={(element, allSelection) => {
                formik.setFieldValue(
                  'categories',
                  allSelection.flatMap((element) =>
                    JSON.parse(JSON.stringify(element.data) ?? '{}')
                  )
                )
              }}
              values={formik.values.categories.map(
                (category: any) =>
                  categoryOptions.find((f) => f.id === category._id) ?? {}
              )}
            />
          </div>
          {formik.errors.categories && categoryClicked && (
            <ErrorMsg text={formik.errors.categories} />
          )}
          <textarea
            className="text-[13px] w-full h-32 p-2 mt-4 rounded  bg-[#fdfdfd]"
            placeholder={t('onboard:short_profile:descriptionBrand')}
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
          {formik.touched.description && formik.errors.description && (
            <ErrorMsg text={formik.errors.description} />
          )}

          <div className="text-[13px] text-[#b5b9d6] mb-8 text-center mt-6">
            <span className="font-bold">{t('onboard:short_profile:name')}</span>
            :<span>{t('onboard:short_profile:note')}</span>
          </div>
          <div className="text-center font-semibold text-xl mb-6">
            {t('onboard:short_profile:netWorldLink')}
          </div>
          <div className="flex flex-col mb-[60px] w-full">
            <div className="flex gap-2">
              <Button
                className="w-[100px] flex-shrink-0"
                variant="gradient"
                size="sm"
              >
                Instagram
              </Button>
              <TextField
                value={formik.values.instagram}
                onChange={formik.handleChange}
                required
                label=""
                name="instagram"
                placeholder="https://instagram.com"
                error={formik.errors.instagram}
              />
            </div>
            <div className="flex gap-2">
              <Button
                className="w-[100px] flex-shrink-0"
                variant="dark"
                size="sm"
              >
                TikTok
              </Button>
              <TextField
                value={formik.values.tiktok}
                onChange={formik.handleChange}
                required
                label=""
                name="tiktok"
                placeholder="htpps://Tiktok.com"
                error={formik.errors.tiktok}
              />
            </div>
            {/* <div className="flex gap-2">
              <Button
                className="w-[100px] flex-shrink-0"
                variant="outLine"
                size="sm"
              >
                Web
              </Button>
              <TextField
                value={formik.values.website}
                onChange={formik.handleChange}
                required
                label=""
                name="website"
                placeholder="htpps://Votresiteweb.com"
                error={formik.errors.website}
              />
            </div> */}
          </div>
          <Button onclick={() => {formik.handleSubmit();setCategoryClicked(true)}} isLoading={isLoading}>
            {t('onboard:short_profile:launch')}
          </Button>
        </div>
      </FormikProvider>
    </div>
  )
}

interface Step1Props {
  formik: any
  isLoading?: boolean
  categoryOptions: DropdownCheckboxOption[]
  handleAutoFill: () => void
}

Step1.defaultProps = {
  formik: {},
  isLoading: false,
  categoryOptions: [],
  handleAutoFill: undefined,
}

Step1.propTypes = {
  formik: PropTypes.any,
  isLoading: PropTypes.bool,
  categoryOptions: PropTypes.any,
  handleAutoFill: PropTypes.any,
}

export default Step1
