import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import userMobx from '../../mobx/user'
import { ClientI } from '../../types-project/Client';

export const DashBoard = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const userInfos = userMobx.getUserInfos() as ClientI
        if(!userInfos._company.premium){
            navigate('/dashboard/premium')
        }
      }, [])
    return <Navigate to="/dashboard/brands" />
}
