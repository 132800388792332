import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

interface ReturnButtonProps {
  onClick?: () => void
}

export const ReturnButton:React.FC<ReturnButtonProps> = ({onClick}) => {
    const navigate = useNavigate();   
            const { t } = useTranslation() 
  return (
    <button
    type="button"
    className={`btn-txt flex items-center gap-1`}
    onClick={() => {
      if(onClick){
    onClick() 

      }else{
        navigate(-1)
      }

    }}
  >
    <i className="fa-regular fa-angle-left" />{t('signup-login:back')}
  </button>
  )
}



ReturnButton.defaultProps = {
    onClick: undefined
}

ReturnButton.propTypes = {
    onClick: PropTypes.func
}
    