/* eslint-disable jsx-a11y/media-has-caption */
import { observer } from 'mobx-react'
import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Flickity from 'react-flickity-component'
import { split } from 'lodash'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { AnimatePresence, motion } from 'framer-motion'
import { toast } from 'react-toastify'
import { Spinner } from 'grommet'

import { TFunction } from 'i18next'
import styles from './component/style.module.scss'
import briefR from '../../../../../mobx/brief'
import {
  getAvatar,
  Tags,
  truncate,
  MOTION_VARIANTS,
  formatVogzterName,
} from '../../../../../utils/helpers'
import BtnBasic from '../../../../../utils/components/Button/BtnBasic'
import BtnBack from '../../../../../utils/components/Button/BtnBack'
import {
  Brief,
  MediaBrief,
  TypeBrief,
} from '../../../../../types-project/Brief'
import userMobx from '../../../../../mobx/user'
import {
  Address,
  BrandI,
  Company2,
  Vogzter,
} from '../../../../../types-project/Client'
import SubTitle1 from '../../../../../utils/components/Headings/SubTitle1'
import Title1 from '../../../../../utils/components/Headings/Title1'
import CreditVogz from '../../../../Credit-vogz'
import AddressPicker from '../EditCasting/components/AddressPicker/AddressPicker'
import modal from '../../../../../mobx/modal'

function Summary() {
  const [viewInApp, setViewInApp] = useState(false)
  const [viewFeed, setViewFeed] = useState(true)
  const briefData = briefR.brief?.data
  const creditRef = useRef<HTMLDivElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  const flickityOptions = {
    lazyLoad: 3,
    pageDots: false,
    pauseAutoPlayOnHover: false,
    prevNextButtons: false,
    percentPosition: false,
    freeScroll: true,
    imagesLoaded: true,
    initialIndex: 1,
    selectedAttraction: 0.01,
    freeScrollFriction: 0.03,
    friction: 0.15,
    cellAlign: 'center',
    contain: true,
    wrapAround: true,
  }
  const flickityOptions2 = {
    pageDots: false,
    contain: true,
    cellAlign: 'center',
    pauseAutoPlayOnHover: false,
    freeScroll: true,
    prevNextButtons: false,
    lazyLoad: 3,
    groupCells: true,
  }
  const { id } = useParams<{
    id: string
  }>()
  const favouriteVogzters = (userMobx.favouriteVogzters.data ?? []) as Array<
    Vogzter
  >
  const company = userMobx.me.data?._company

  const [showPayementCredits, setShowPayementCredits] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    briefR.getBrief(id as string)
    userMobx.getFavouriteVogzters()
    const params = new URLSearchParams(window.location.search)
    const card = params.get('card')
    if (card === 'ok' && userMobx.hasPaymentMethod()) {
      setShowPayementCredits(false)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const card = params.get('card')
    if (card === 'ok' && userMobx.hasPaymentMethod()) {
      setShowPayementCredits(false)
    }
  }, [userMobx.me.data?._company.credits])

  // translation
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vogz - Summary</title>
      </Helmet>
      {!briefR.brief.error &&
      !briefR.brief.loading &&
      !userMobx.favouriteVogzters.error &&
      !userMobx.favouriteVogzters.loading ? (
        <div
          className={`${
            viewInApp || showPayementCredits ? styles['no-scroll'] : ''
          }  flex flex-col items-center relative h-full`}
          ref={mainRef}
        >
          {!showPayementCredits ? (
            <Fragment>
              <section className={`py-4 ${styles['card-end']}`}>
                <div className={styles['head-card-end']}>
                  <BtnBack btnName={'Retour'} />

                  <h3 className={styles['title']}>{briefData?.name}</h3>
                  <div className="flex justify-center my-4">
                    <Tags
                      type={briefData?.type?.name as TypeBrief}
                      className={`mx-4 ${styles['tag']}`}
                    />
                    {briefData?.exclusive!.is_exclusive && (
                      <Tags type={'vip'} />
                    )}
                  </div>
                </div>
              </section>
              {(briefData?.media.length ?? 0) > 0 && (
                <section
                  className={`${styles['update']} ${styles['card-image']}`}
                >
                  <BtnBasic
                    btnName={t('summary:edit')}
                    typeBtn="button"
                    onclick={() =>
                      navigate(
                        `/dashboard/castings/${id}/edit?currentStep=2#${t(
                          'form-casting:casting.cards-casting-illustration.title'
                        )}`
                      )
                    }
                    type={'inverted-sky-violet'}
                  />
                  <div className={styles['content']}>
                    {(briefData?.media as MediaBrief[]).map((m) => (
                      <div className={styles['div-card-image']}>
                        {m.type === 'image' ? (
                          <img
                            height={'180px'}
                            width={'304px'}
                            src={m.source}
                            alt="media"
                          />
                        ) : (
                          <ReactPlayer
                            height={'180px'}
                            width={'304px'}
                            url={m.source}
                            controls
                            playsinline
                            loop
                            muted
                            id={m._id}
                            x5-playsinline
                            webkit-playsinline
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </section>
              )}
              {briefData?.ratio ||
                (Object.keys(briefData?.length ?? {}).length > 0 && (
                  <section className={styles['card-settings']}>
                    <div className={styles['div-settings']}>
                      <div
                        className={`${styles['div-ratio']} ${
                          styles['update']
                        } ${styles[`div-ratio-${briefData?.ratio}`]}`}
                      >
                        <BtnBasic
                          btnName={t('summary:edit')}
                          typeBtn="button"
                          onclick={() =>
                            navigate(
                              `/dashboard/castings/${id}/edit#Ratio pour votre VOGZ`
                            )
                          }
                          type={'inverted-sky-violet'}
                        />
                        {briefData?.ratio === 'portrait' ? (
                          <img
                            src="/assets/svgs/phone-vertical.svg"
                            alt="decorative"
                          />
                        ) : (
                          <img
                            src="/assets/svgs/phone-landscape.svg"
                            alt="decorative"
                          />
                        )}
                      </div>
                      <div
                        className={`${styles['div-time']} ${styles['update']}`}
                      >
                        <BtnBasic
                          btnName={t('summary:edit')}
                          typeBtn="button"
                          onclick={() =>
                            navigate(
                              `/dashboard/castings/${id}/edit#${t(
                                'form-casting:casting.cards-casting-time.title'
                              )}`
                            )
                          }
                          type={'inverted-sky-violet'}
                        />
                        <p>
                          <div>
                            {t('summary:from')}{' '}
                            <strong>{briefData?.length?.min}</strong>
                            <span> Sec</span>
                          </div>
                          <div>
                            {t('summary:to')}{' '}
                            <strong>{briefData?.length?.max}</strong>
                            <span> Sec</span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </section>
                ))}
              {((briefData?.favourites?.length ?? 0) > 0 &&
                favouriteVogzters.length > 0) ||
                (briefData?.private?.is_private && (
                  <section
                    className={`${styles['update']} ${
                      styles['section-favourite']
                    } ${
                      briefData?.private?.is_private &&
                      (briefData?.favourites?.length ?? 0) <= 0
                        ? styles['div-favourite-error']
                        : ''
                    }`}
                  >
                    <BtnBasic
                      btnName={t('summary:edit')}
                      typeBtn="button"
                      onclick={() =>
                        navigate(
                          `/dashboard/castings/${id}/edit?currentStep=0#${t(
                            'form-casting:casting.cards-casting-fav.title'
                          )}`
                        )
                      }
                      type={'inverted-sky-violet'}
                    />
                    <div className={styles['div-favourite']}>
                      <Flickity
                        elementType={'div'}
                        options={flickityOptions2}
                        disableImagesLoaded={false}
                        reloadOnUpdate
                        static
                      >
                        {favouriteVogzters
                          .filter((vogzter) => {
                            if (briefData?.private?.is_private === true)
                              return briefData?.favourites?.includes(
                                vogzter._id
                              )
                            else return briefData?.favourites
                          })
                          .map((v, i) => (
                            <div
                              key={i}
                              className={`mt-6 mr-6 ${styles['card-add-fav']}`}
                            >
                              <img
                                alt=""
                                src={getAvatar(
                                  v?.profile.gender,
                                  v.profile.picture?.source
                                )}
                                className={'cursor-none'}
                              />
                              <p className={styles['name-vogzter']}>
                                {formatVogzterName(v.first_name, v.last_name)}
                              </p>
                            </div>
                          ))}
                        {briefData?.private?.is_private &&
                          (briefData?.favourites?.length ?? 0) <= 0 && (
                            <div
                              className={`mt-6 mr-6 cursor-pointer ${styles['card-add-fav']}`}
                            >
                              <img
                                alt=""
                                src="/assets/svgs/add-favourite.svg"
                              />
                              <p>{t('summary:add-fav')}</p>
                            </div>
                          )}
                      </Flickity>
                      {briefData?.private?.is_private &&
                        (briefData?.favourites?.length ?? 0) <= 0 && (
                          <p className="error text-right mr-12">
                            {t('summary:add-favourite')}
                          </p>
                        )}
                    </div>
                  </section>
                ))}
              {briefData?.on_spot &&
                briefData.on_spot.is_on_spot &&
                (briefData.on_spot.addresses?.length ?? 0) > 0 &&
                userMobx.me.data?.addresses && (
                  <section
                    className={`${styles['update']} flex w-full justify-center`}
                  >
                    <BtnBasic
                      btnName={t('summary:edit')}
                      typeBtn="button"
                      onclick={() =>
                        navigate(`/dashboard/castings/${id}/edit?currentStep=0`)
                      }
                      type={'inverted-sky-violet'}
                    />
                    <AddressPicker
                      className="w-min m-auto"
                      preventSelection
                      displayOnly={briefData.on_spot?.addresses?.map(
                        (address) =>
                          userMobx.me.data?.addresses?.findIndex(
                            (a) => (address as Address)._id === a._id
                          ) ?? -1
                      )}
                    />
                  </section>
                )}
              <section className={styles['card-brief']}>
                {briefData?.description && briefData?.description !== '' && (
                  <div
                    className={`${styles['update']} justify-center flex-col items-center flex`}
                  >
                    <BtnBasic
                      btnName={t('summary:edit')}
                      typeBtn="button"
                      onclick={() =>
                        navigate(
                          `/dashboard/castings/${id}/edit?currentStep=1#${t(
                            'form-casting:casting.cards-casting-descbrief.title'
                          )}`
                        )
                      }
                      type={'inverted-sky-violet'}
                    />
                    <h4>{t('summary:desc')}</h4>
                    <p>{briefData?.description}</p>
                  </div>
                )}
                {(briefData?.creative_guidelines.length ?? 0) > 0 && (
                  <div
                    className={`${styles['update']} justify-center flex-col items-center flex`}
                  >
                    <BtnBasic
                      btnName="modifier"
                      typeBtn="button"
                      onclick={() =>
                        navigate(
                          `/dashboard/castings/${id}/edit?currentStep=1#${t(
                            'form-casting:casting.cards-casting-prerequisite.title'
                          )}`
                        )
                      }
                      type={'inverted-sky-violet'}
                    />
                    <h4>{t('summary:prerequisites')}</h4>
                    {briefData?.creative_guidelines.map(
                      (v, k) => v !== '' && <li key={k}>{v}</li>
                    )}
                  </div>
                )}
                {(briefData?.scenario?.length ?? 0) > 0 && (
                  <div
                    className={`${styles['update']} ${styles['div-scenario']}`}
                  >
                    <BtnBasic
                      btnName="modifier"
                      typeBtn="button"
                      onclick={() =>
                        navigate(
                          `/dashboard/castings/${id}/edit?currentStep=1#${t(
                            'form-casting:casting.cards-casting-scenario.title'
                          )}`
                        )
                      }
                      type={'inverted-sky-violet'}
                    />
                    <h4>{t('summary:scenario')}</h4>
                    <div className="flex flex-row justify-center items-center flex-wrap ">
                      {briefData?.scenario?.map(
                        (v, i) =>
                          v !== '' && (
                            <div
                              className={`max-w[400] m-2 w-full flex ${styles['step-scenario']}`}
                              key={i}
                            >
                              <span>
                                <strong className="flex flex-col items-center">
                                  {i + 1}
                                </strong>{' '}
                                {t('summary:step')}
                              </span>
                              <p>{truncate(v, 80)}</p>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )}
                {briefData?.added_info.some((i) => i && i !== '') &&
                  (briefData?.added_info.length ?? 0) > 0 && (
                    <div
                      className={`${styles['update']} justify-center flex-col items-center flex`}
                    >
                      <BtnBasic
                        btnName="modifier"
                        typeBtn="button"
                        onclick={() =>
                          navigate(
                            `/dashboard/castings/${id}/edit?currentStep=1#${t(
                              'form-casting:casting.cards-casting-remarks.title'
                            )}`
                          )
                        }
                        type={'inverted-sky-violet'}
                      />
                      <h4>{t('summary:remarks')}</h4>
                      {briefData?.added_info.map(
                        (v, k) => v !== ' ' && <li key={k}>{v}</li>
                      )}
                    </div>
                  )}
              </section>
              <section className={styles['section-media']}>
                {(briefData?.inspiration.media.length ?? 0) > 0 && (
                  <div
                    className={`${styles['update']} ${styles['div-inspiration']}`}
                  >
                    <BtnBasic
                      btnName="modifier"
                      typeBtn="button"
                      onclick={() =>
                        navigate(
                          `/dashboard/castings/${id}/edit?currentStep=2#${t(
                            'form-casting:casting.cards-casting-inspirations.title'
                          )}`
                        )
                      }
                      type={'inverted-sky-violet'}
                    />
                    <h4>{t('summary:inspi')}</h4>
                    <div className={styles['card-inspiration']}>
                      <Flickity
                        elementType={'div'}
                        options={flickityOptions2}
                        disableImagesLoaded={false}
                        reloadOnUpdate
                        static
                      >
                        {briefData?.inspiration.media.map((v, k) => {
                          return (
                            <div key={k} className={styles['card-body']}>
                              <ReactPlayer
                                height={'200px'}
                                width={'350px'}
                                url={v.source}
                                controls
                                playsinline
                                muted
                              />
                              <p>
                                {
                                  split(v.source, '/')[
                                    split(v.source, '/')?.length - 1
                                  ]
                                }
                              </p>
                            </div>
                          )
                        })}
                      </Flickity>
                    </div>
                  </div>
                )}
                <div className={styles['card-music-script']}>
                  {briefData?.music && (
                    <div className={styles['update']}>
                      <BtnBasic
                        btnName="modifier"
                        typeBtn="button"
                        onclick={() =>
                          navigate(
                            `/dashboard/castings/${id}/edit?currentStep=2#${t(
                              'form-casting:casting.cards-casting-inspirations.title'
                            )}`
                          )
                        }
                        type={'inverted-sky-violet'}
                      />
                      <h4>{t('summary:music')}</h4>
                      <div
                        className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                      >
                        <i className="fa-solid fa-music" />
                        <p>
                          {
                            split(briefData?.music as string, '/')[
                              split(briefData?.music as string, '/').length - 1
                            ]
                          }
                        </p>
                      </div>
                    </div>
                  )}
                  {briefData?.script && (
                    <div className={styles['update']}>
                      <BtnBasic
                        btnName="modifier"
                        typeBtn="button"
                        onclick={() =>
                          navigate(
                            `/dashboard/castings/${id}/edit?currentStep=2#${t(
                              'form-casting:casting.cards-casting-script.title'
                            )}`
                          )
                        }
                        type={'inverted-sky-violet'}
                      />
                      <h4>{t('summary:script')}</h4>
                      <div
                        className={`${styles['div-media']} px-2 my-1 flex flex-row`}
                      >
                        <i className="fa-solid fa-file-pdf" />
                        <p>
                          {
                            split(briefData?.script as string, '/')[
                              split(briefData?.script as string, '/').length - 1
                            ]
                          }
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {briefData?.to_checkout.links.length !==
                  briefData?.to_checkout.links.filter((link) => link.url === '')
                    .length && (
                  <div className={`${styles['update']} ${styles['div-link']}`}>
                    <BtnBasic
                      btnName="modifier"
                      typeBtn="button"
                      onclick={() =>
                        navigate(
                          `/dashboard/castings/${id}/edit?currentStep=1#${t(
                            'form-casting:casting.cards-casting-links.title'
                          )}`
                        )
                      }
                      type={'inverted-sky-violet'}
                    />
                    <h4>{t('summary:link')}</h4>
                    {briefData?.to_checkout.links.map(
                      (v, k) =>
                        v.url !== '' && (
                          <a key={k} href={v.url}>
                            {v.url}
                          </a>
                        )
                    )}
                  </div>
                )}
              </section>
              {viewInApp && (
                <section className={styles['section-in-app']}>
                  <div className={styles['in-app']}>
                    <div className={styles['modal']}>
                      <div className={styles['content']}>
                        <AnimatePresence>
                          {viewFeed ? (
                            <motion.div
                              custom={{ direction: 'backward', delay: 4 }}
                              initial="initial"
                              animate="in"
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.2 }}
                              key={`feed-inApp`}
                              variants={MOTION_VARIANTS}
                            >
                              <div className={styles['feed']}>
                                <div
                                  className={`flex flex-col items-start ${styles['info-brief']}`}
                                >
                                  <div className={`${styles['header']} flex `}>
                                    <img
                                      src="/assets/imgs/lockMobile.png"
                                      alt="decorative"
                                    />
                                    <img
                                      src="/assets/imgs/vogzLogo.png"
                                      alt="decorative"
                                    />
                                  </div>
                                  <div className={'flex p-4 mt-4 items-center'}>
                                    <img
                                      className="mr-2 rounded-full"
                                      width={'30px'}
                                      src={(briefData?.brand as BrandI).logo}
                                      alt="logo company"
                                    />
                                    <Title1
                                      style={{ fontSize: '12px' }}
                                      text={
                                        (briefData?.brand as BrandI)
                                          .name as string
                                      }
                                    />
                                  </div>
                                  <div
                                    className={
                                      'flex flex-col items-center w-full'
                                    }
                                  >
                                    <SubTitle1 text={briefData?.name} />
                                    <div className="flex justify-center my-1">
                                      <Tags
                                        type={
                                          briefData?.type?.name as TypeBrief
                                        }
                                        className={`mx-1 ${styles['tag']}`}
                                      />
                                      {briefData?.exclusive?.is_exclusive && (
                                        <Tags type={'vip'} />
                                      )}
                                    </div>
                                  </div>
                                  {(briefData?.media.length ?? 0) > 0 && (
                                    // <div className="flex flex-col justify-start p-4">
                                    <div className={styles['card-inspiration']}>
                                      <Flickity
                                        elementType={'div'}
                                        options={flickityOptions2}
                                        disableImagesLoaded={false}
                                        reloadOnUpdate
                                        static
                                      >
                                        {briefData?.media.map((v, k) => {
                                          return (
                                            <div
                                              key={k}
                                              className={styles['card-body']}
                                            >
                                              {(v as MediaBrief).type ===
                                              'image' ? (
                                                <div
                                                  className={
                                                    styles['slide-img']
                                                  }
                                                >
                                                  <img
                                                    height={'250px'}
                                                    width={'275px'}
                                                    src={v.source}
                                                    alt="media"
                                                  />
                                                </div>
                                              ) : (
                                                <ReactPlayer
                                                  height={'250px'}
                                                  width={'275px'}
                                                  url={v.source}
                                                  controls
                                                  playsinline
                                                  muted
                                                />
                                              )}
                                            </div>
                                          )
                                        })}
                                      </Flickity>
                                    </div>
                                    // </div>
                                  )}
                                  {briefData?.ratio &&
                                    Object.keys(briefData.length ?? {}).length >
                                      0 && (
                                      <div className={styles['param-brief']}>
                                        <div className="flex justify-center flex-col">
                                          <p>{t('summary:length')}</p>
                                          <span>
                                            {briefData?.length.min}sec&nbsp;-
                                            &nbsp;
                                            {briefData?.length.max}sec
                                          </span>
                                        </div>
                                        {briefData?.remuneration && (
                                          <div className="flex justify-center flex-col">
                                            <p>{t('summary:remu')}</p>
                                            <span>
                                              {briefData?.remuneration}
                                            </span>
                                          </div>
                                        )}
                                        <div className="flex justify-center flex-col">
                                          <p>Vogz Coins</p>
                                          <div className="flex items-center justify-center">
                                            <span>
                                              {
                                                briefData?.remuneration_vogz_coin
                                              }{' '}
                                            </span>
                                            <img
                                              src="/assets/imgs/coin.png"
                                              alt="coins"
                                              width={'15px'}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  <div className={styles['btnFeed']}>
                                    <BtnBasic
                                      btnName={'Découvrir le casting'}
                                      type={'inverted-sky-violet'}
                                      typeBtn="button"
                                      onclick={() => {
                                        setViewFeed(false)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </motion.div>
                          ) : (
                            <motion.div
                              custom={{ direction: 'backward', delay: 4 }}
                              initial="initial"
                              animate="in"
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.2 }}
                              key={`inApp`}
                              variants={MOTION_VARIANTS}
                            >
                              <div className={styles['head']}>
                                <BtnBack
                                  btnName={''}
                                  customAction={() => {
                                    setViewFeed(true)
                                  }}
                                />
                                <div
                                  className={`flex flex-col items-center ${styles['info-brief']}`}
                                >
                                  <img
                                    src={(briefData?.brand as BrandI).logo}
                                    alt="logo company"
                                  />
                                  <Title1
                                    text={
                                      (briefData?.brand as BrandI)
                                        .name as string
                                    }
                                  />
                                  <SubTitle1 text={briefData?.name} />
                                  <div className="flex justify-center my-1">
                                    <Tags
                                      type={briefData?.type?.name as TypeBrief}
                                      className={`mx-1 ${styles['tag']}`}
                                    />
                                    {briefData?.exclusive?.is_exclusive && (
                                      <Tags type={'vip'} />
                                    )}
                                  </div>
                                </div>
                                <div className={styles['param-brief']}>
                                  {Object.keys(briefData?.length ?? {}).length >
                                    0 && (
                                    <div className="flex justify-center flex-col">
                                      <p>{t('summary:length')}</p>
                                      <span>
                                        {briefData?.length.min}sec&nbsp;- &nbsp;
                                        {briefData?.length.max}sec
                                      </span>
                                    </div>
                                  )}
                                  {briefData?.remuneration && (
                                    <div className="flex justify-center flex-col">
                                      <p>{t('summary:remu')}</p>
                                      <span>{briefData?.remuneration}</span>
                                    </div>
                                  )}
                                  <div className="flex justify-center flex-col">
                                    <p>Vogz Coins</p>
                                    <div className="flex items-center justify-center">
                                      <span>
                                        {briefData?.remuneration_vogz_coin}{' '}
                                      </span>
                                      <img
                                        src="/assets/imgs/coin.png"
                                        alt="coins"
                                        width={'15px'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles['body']}>
                                {briefData?.description &&
                                  briefData?.description !== '' && (
                                    <>
                                      <div className="flex flex-col justify-start p-4">
                                        <h4>Brief_</h4>
                                        <p>{briefData?.description}</p>
                                      </div>
                                      <div className="p-2" />
                                    </>
                                  )}
                                {briefData?.music && (
                                  <>
                                    <div
                                      className={`${styles['bg-card']} flex flex-col justify-start p-4 relative`}
                                    >
                                      <h4>Audio_</h4>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: t('summary:audio'),
                                        }}
                                      />
                                      <div className="flex justify-center">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            window.open(briefData?.music)
                                          }}
                                        >
                                          {
                                            split(
                                              briefData?.music as string,
                                              '/'
                                            )[
                                              split(
                                                briefData?.music as string,
                                                '/'
                                              ).length - 1
                                            ]
                                          }
                                        </button>
                                      </div>
                                      <img
                                        src="/assets/imgs/audio.png"
                                        alt="decorative"
                                        width={'50px'}
                                      />
                                    </div>
                                    <div className="p-2" />
                                  </>
                                )}
                                {briefData?.script && (
                                  <div
                                    className={`${styles['bg-card']} flex flex-col justify-start p-4 relative`}
                                  >
                                    <h4>Script_</h4>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: t('summary:scriptM'),
                                      }}
                                    />
                                    <div className="flex justify-center">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          window.open(briefData?.script)
                                        }}
                                      >
                                        {
                                          split(
                                            briefData?.script as string,
                                            '/'
                                          )[
                                            split(
                                              briefData?.script as string,
                                              '/'
                                            ).length - 1
                                          ]
                                        }
                                      </button>
                                    </div>
                                    <img
                                      src="/assets/imgs/script.png"
                                      alt="decorative"
                                      width={'50px'}
                                    />
                                  </div>
                                )}
                                {(briefData?.creative_guidelines.length ?? 0) >
                                  0 && (
                                  <div className="flex flex-col justify-start p-4">
                                    <h4>{t('summary:prerequisites')}_</h4>
                                    {briefData?.creative_guidelines.map(
                                      (v, k) => v !== '' && <li key={k}>{v}</li>
                                    )}
                                  </div>
                                )}
                                {(briefData?.scenario?.length ?? 0) > 0 && (
                                  <div className="flex flex-col justify-start">
                                    <h4 className="p-4">
                                      {t('summary:scenario')}_
                                    </h4>
                                    <div className="flex flex-row justify-center items-center flex-wrap ">
                                      {briefData?.scenario?.map(
                                        (v, i) =>
                                          v !== '' && (
                                            <div
                                              className={`w-full flex items-center ${styles['step-scenario']}`}
                                              key={i}
                                            >
                                              <span>
                                                <strong className="flex flex-col items-center">
                                                  {i + 1}
                                                </strong>
                                                {t('summary:step')}
                                              </span>
                                              <p>{truncate(v, 80)}</p>
                                            </div>
                                          )
                                      )}
                                    </div>
                                  </div>
                                )}
                                <div className="flex flex-col justify-start">
                                  <h4 className="p-4">{t('summary:ratio')}_</h4>
                                  <div className={`justify-center flex p-4`}>
                                    {briefData?.ratio === 'portrait' ? (
                                      <img
                                        src="/assets/svgs/phone-vertical.svg"
                                        alt="decorative"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/svgs/phone-landscape.svg"
                                        alt="decorative"
                                      />
                                    )}
                                  </div>
                                </div>
                                {briefData?.added_info.some(
                                  (i) => i && i !== ''
                                ) &&
                                  (briefData?.added_info.length ?? 0) > 0 && (
                                    <div
                                      className={`${styles['bg-card']} flex flex-col justify-start p-4`}
                                    >
                                      <h4>{t('summary:remarks')}_</h4>
                                      {briefData?.added_info.map(
                                        (v, k) =>
                                          v !== '' && <li key={k}>{v}</li>
                                      )}
                                    </div>
                                  )}
                                {(briefData?.inspiration.media.length ?? 0) >
                                  0 && (
                                  <div className="flex flex-col justify-start ">
                                    <h4 className="p-4">
                                      {t('summary:inspi')}_
                                    </h4>
                                    <div className={styles['card-inspiration']}>
                                      <Flickity
                                        elementType={'div'}
                                        options={flickityOptions2}
                                        disableImagesLoaded={false}
                                        reloadOnUpdate
                                        static
                                      >
                                        {briefData?.inspiration.media.map(
                                          (v, k) => {
                                            return (
                                              <div
                                                key={k}
                                                className={styles['card-body']}
                                              >
                                                <ReactPlayer
                                                  height={'250px'}
                                                  width={'275px'}
                                                  url={v.source}
                                                  controls
                                                  playsinline
                                                  muted
                                                />
                                              </div>
                                            )
                                          }
                                        )}
                                      </Flickity>
                                    </div>
                                  </div>
                                )}
                                {briefData?.to_checkout.links.length !==
                                  briefData?.to_checkout.links.filter(
                                    (link) => link.url === ''
                                  ).length && (
                                  <div className="flex flex-col justify-start p-4">
                                    <h4>{t('summary:seeAlso')}</h4>
                                    <div className="flex flex-wrap items-end justify-start p-4">
                                      {briefData?.to_checkout.links.map(
                                        (v, k) =>
                                          v.url !== '' && (
                                            <a
                                              className="flex flex-col items-center mr-4"
                                              key={k}
                                              rel="noreferrer"
                                              target="_blank"
                                              href={v.url}
                                            >
                                              <div className="w-full flex items-center h-10">
                                                <img
                                                  className="rounded-full"
                                                  width={'40px'}
                                                  src={v.social?.img_small}
                                                  alt={v.social?.name}
                                                />
                                              </div>
                                              {v.social?.name}
                                            </a>
                                          )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                      <div className="pointer-events-none relative z-10	">
                        <img
                          src="/assets/imgs/mockup-iphone.png"
                          alt="mockup iphone"
                          width={320}
                          height={514}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col max-w-xs w-72 my-8">
                      <BtnBasic
                        onclick={() => {
                          setViewInApp(false)
                          setViewFeed(true)
                        }}
                        btnName={'Revenir au récapitulatif'}
                        type="sky-violet-bordered"
                      />
                      <div className="h-3" />
                    </div>
                  </div>
                </section>
              )}
              <section>
                <div className="flex flex-col max-w-xs w-72 my-8">
                  <BtnBasic
                    onclick={() => {
                      setViewInApp(true)
                    }}
                    btnName={'Afficher la vue in-app'}
                    type="sky-violet-bordered"
                  />
                  <div className="h-3" />
                  <BtnBasic
                    btnName={'Valider mon casting'}
                    type={'inverted-sky-violet'}
                    typeBtn="button"
                    onclick={() => {
                      if (briefData && company) {
                        if (company.credits < briefData.remuneration) {
                          if (company.premium) {
                            setShowPayementCredits(true)
                          } else {
                            window.location.href = `/dashboard/premium`
                          }
                        } else {
                          briefR.validateBrief(briefData._id, () => {
                            window.location.href = `/dashboard/castings/${briefData._id}/validate`
                          })
                        }
                      }
                    }}
                  />
                </div>
              </section>
            </Fragment>
          ) : (
            <CreditVogz paymentRedirect={`/dashboard/castings/${id}/summary`} />
          )}
        </div>
      ) : (
        <div className="flex w-full h-full justify-center items-center">
          <Spinner />
        </div>
      )}
    </>
  )
}

export default observer(Summary)
