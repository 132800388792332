import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import Button from '../../../../utils/components/Ui/v2/buttons/Button'
import { ReturnButton } from '../../../../utils/components/Ui/v2/buttons/ReturnButton'

interface Step4Props {
  onBack?: () => void
  onNext?: () => void
}

const Step4:React.FC<Step4Props> = ({onBack,onNext}) => {
  const [activeSlide, setActiveSlide] = useState(1)
  const { t } = useTranslation()

  return (
    <div className="max-w-[1180px] mx-auto py-8">
      <div className="mb-8">
        <ReturnButton onClick={onBack} />
      </div>
      <div>
        <h1 className="font-bold text-3xl mb-4 text-center text-main-blue-dark">
          Et nunc velit sit lorem mi.
        </h1>
        <div className="flex items-center justify-center gap-2 mb-12">
          <div className="font-bold px-4 py-1 rounded bg-[#B3FF85]">
            Test Produit
          </div>
          <div className="font-bold px-4 py-1 rounded text-white bg-main-blue-dark">
            Casting Plus
          </div>
        </div>
        <div className="flex items-center justify-center gap-3 mb-12 mx-auto max-w-[980]">
          {Array.from({ length: 3 }).map((_, index) => (
            <div onClick={() => setActiveSlide(index)} className={`cursor-pointer transition rounded ${activeSlide === index ? 'transform scale-100 border-[2px] border-[#fff]' : 'transform scale-90'}`}>
            <img
              src="/assets/imgs/v2/recap-slide/frame-1.png"
              className="rounded-md"
              alt="vog-logo"
            />
          </div>
          ))}

        </div>
        <div className='border border-[#e5eaff] max-w-[700px] mx-auto flex py-2 mb-12'>
          <div className='flex justify-center items-center w-1/2 border-r border-[#e5eaff] '>
          <img
              src="/assets/imgs/v2/iphone.png"
              className="rounded-md"
              alt="vog-logo"
            />
          </div>
          <div className='w-1/2 flex justify-center items-center'>
            <div className='flex gap-4 items-baseline'>
                <span className='text-[#7c7c7c]'>De</span>
                <span className='text-[#e5eaff] font-semibold text-[30px]'>30</span>
                <span className='text-[#e5eaff] font-semibold text-[14px]'>Sec</span>
                <span className='text-[#7c7c7c]'>à</span>
                <span className='text-[#e5eaff] font-semibold text-[30px]'>60</span>
                <span className='text-[#e5eaff] font-semibold text-[14px]'>Sec</span>
            </div>
          </div>
        </div>
        <div className='max-w-[550px] mx-auto mb-8'>
          <div className='text-center font-bold text-xl'>Description de votre brief</div>
          <div className='text-[#7c7c7c] text-center text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor ipsum risus condimentum et. Vestibulum orci scelerisque venenatis quisque consectetur id. Velit consequat risus quis auctor rutrum id rutrum adipiscing. Amet nibh ornare accumsan dolor. Tempor, est eget pulvinar velit risus dignissim. Massa enim amet, aenean pellentesque nisi, sit in amet, nulla. Adipiscing ac faucibus pellentesque donec egestas mauris sit.Gravida vestibulum condimentum hendrerit elit. Hac vitae vestibulum, justo feugiat et. Massa enim amet, aenean pellentesque nisi, sit in amet, nulla.Massa enim amet, aenean pellentesque nisi, sit in amet, nulla.</div>
        </div>
        <div className='max-w-[550px] mx-auto mb-8'>
          <div className='text-center font-bold text-xl'>Prérequis</div>
          <div className='text-[#7c7c7c] text-center text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor ipsum risus condimentum et. Vestibulum orci scelerisque venenatis quisque consectetur id. Velit consequat risus quis auctor rutrum id rutrum adipiscing. Amet nibh ornare accumsan dolor. Tempor, est eget pulvinar velit risus dignissim. Massa enim amet, aenean pellentesque nisi, sit in amet, nulla. Adipiscing ac faucibus pellentesque donec egestas mauris sit.Gravida vestibulum condimentum hendrerit elit. Hac vitae vestibulum, justo feugiat et. Massa enim amet, aenean pellentesque nisi, sit in amet, nulla.Massa enim amet, aenean pellentesque nisi, sit in amet, nulla.</div>
        </div>
        <div className='max-w-[815px] mx-auto mb-8'>
          <div className='text-center font-bold text-xl mb-4'>Scénario</div>
            <div className='flex justify-center gap-4 flex-wrap w-full'>
                <div className='rounded border border-[#67acfe flex items-center w-[calc(50%-8px)]'>
                  <div className='rounded-l text-white font-bold flex justify-center items-center flex-col bg-[#67acfe] px-3 py-2'>
                    <div className='leading-none'>1</div>
                    <div className='text-[10px] leading-none'>Étape</div>
                  </div>
                  <div className='text-center flex justify-center items-center text-[#7c7c7c] text-sm w-full'>Enim senectus vestibulum consectetur magnis.</div>
                </div>
                <div className='rounded border border-[#67acfe flex items-center w-[calc(50%-8px)]'>
                  <div className='rounded-l text-white font-bold flex justify-center items-center flex-col bg-[#67acfe] px-3 py-2'>
                    <div className='leading-none'>2</div>
                    <div className='text-[10px] leading-none'>Étape</div>
                  </div>
                  <div className='text-center flex justify-center items-center text-[#7c7c7c] text-sm w-full'>Enim senectus vestibulum consectetur magnis.</div>
                </div>
                <div className='rounded border border-[#67acfe flex items-center w-[calc(50%-8px)]'>
                  <div className='rounded-l text-white font-bold flex justify-center items-center flex-col bg-[#67acfe] px-3 py-2'>
                    <div className='leading-none'>3</div>
                    <div className='text-[10px] leading-none'>Étape</div>
                  </div>
                  <div className='text-center flex justify-center items-center text-[#7c7c7c] text-sm w-full'>Enim senectus vestibulum consectetur magnis.</div>
                </div>
                <div className='rounded border border-[#67acfe flex items-center w-[calc(50%-8px)]'>
                  <div className='rounded-l text-white font-bold flex justify-center items-center flex-col bg-[#67acfe] px-3 py-2'>
                    <div className='leading-none'>4</div>
                    <div className='text-[10px] leading-none'>Étape</div>
                  </div>
                  <div className='text-center flex justify-center items-center text-[#7c7c7c] text-sm w-full'>Enim senectus vestibulum consectetur magnis.</div>
                </div>
            </div>
        </div>
        <div className='max-w-[550px] mx-auto mb-8'>
          <div className='text-center font-bold text-xl'>Remarques</div>
          <div className='text-[#7c7c7c] text-center text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor ipsum risus condimentum et. Vestibulum orci scelerisque venenatis quisque consectetur id. Velit consequat risus quis auctor rutrum id rutrum adipiscing. Amet nibh ornare accumsan dolor. Tempor, est eget pulvinar velit risus dignissim. Massa enim amet, aenean pellentesque nisi, sit in amet, nulla. Adipiscing ac faucibus pellentesque donec egestas mauris sit.Gravida vestibulum condimentum hendrerit elit. Hac vitae vestibulum, justo feugiat et. Massa enim amet, aenean pellentesque nisi, sit in amet, nulla.Massa enim amet, aenean pellentesque nisi, sit in amet, nulla.</div>
        </div>
        <div className='mx-auto mb-8 p-6 shadow-lg bg-white'>
          <div className='text-center font-bold text-xl mb-2'>Vidéos inspirantes</div>
          <div className='flex gap-6 w-full'>
              <div>
              <img
              src="/assets/imgs/v2/recap-slide/frame-1.png"
              className="rounded-md"
              alt="vog-logo"
            />
            <div className='font-bold text-[#8480ff] text-center mt-2 text-xs'>VIDÉO INSPIRATION 1.mp4</div>
            </div>
            <div>
              <img
              src="/assets/imgs/v2/recap-slide/frame-1.png"
              className="rounded-md"
              alt="vog-logo"
            />
            <div className='font-bold text-[#8480ff] text-center mt-2 text-xs'>VIDÉO INSPIRATION 1.mp4</div>
            </div>
            <div>
              <img
              src="/assets/imgs/v2/recap-slide/frame-1.png"
              className="rounded-md"
              alt="vog-logo"
            />
            <div className='font-bold text-[#8480ff] text-center mt-2 text-xs'>VIDÉO INSPIRATION 1.mp4</div>
            </div>
            <div>
              <img
              src="/assets/imgs/v2/recap-slide/frame-1.png"
              className="rounded-md"
              alt="vog-logo"
            />
            <div className='font-bold text-[#8480ff] text-center mt-2 text-xs'>VIDÉO INSPIRATION 1.mp4</div>
            </div>
          </div>
        </div>
        <div className='max-w-[800px] mx-auto mb-8 flex justify-between'>
        <div>
        <div className='text-center font-bold text-xl mb-4'>Musique</div>
        <div className='rounded shadow-md flex gap-6 py-2 w-[365px] px-4'>
            <div>
            <img
              src="/assets/imgs/v2/music.svg"
              className="rounded-md"
              alt="vog-logo"
            />
            </div>
            <div className='text-xs font-bold main-blue-dark'>MUSIQUE 1.mp3</div>
        </div>
        </div>
        <div>
        <div className='text-center font-bold text-xl mb-4'>Musique</div>
        <div className='rounded shadow-md flex gap-6 py-2 w-[365px] px-4'>
            <div>
            <img
              src="/assets/imgs/v2/music.svg"
              className="rounded-md"
              alt="vog-logo"
            />
            </div>
            <div className='text-xs font-bold main-blue-dark'>MUSIQUE 1.mp3</div>
        </div>
        </div>
        </div>
        <div className='mb-12'>
        <div className='text-center font-bold text-xl mb-4'>Liens</div>
        <div className='text-xs rounded w-[500px] mx-auto text-center shadow-md mb-2 text-[#0182ca] py-2 font-semibold'><Link to="https://www.youtube.com/watch?v=OWl9p3oFKgg">https://www.youtube.com/watch?v=OWl9p3oFKgg</Link></div>
        <div className='text-xs rounded w-[500px] mx-auto text-center shadow-md mb-2 text-[#0182ca] py-2 font-semibold'><Link to="https://www.youtube.com/watch?v=OWl9p3oFKgg">https://www.youtube.com/watch?v=OWl9p3oFKgg</Link></div>
        </div>
          <div className='mx-auto max-w-[400px]'>
          <Button className='mb-4' variant='outLineBlue'>
        Afficher la vue in-app
        </Button>
        <Button>
        Valider mon casting 
        </Button>
          </div>
      </div>
    </div>
  )
}

Step4.defaultProps = {
  onBack: () => {},
  onNext: () => {}
}

Step4.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
}

export default Step4
