import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { BasicModalRefProps } from '../../../types-project/modalProps'
import BtnCustom from '../../../pages/Premium/components/BtnCustom'

type GalerieVogzterModalLoadMoreProps = {}

const GalerieVogzterModalLoadMore: ForwardRefRenderFunction<
  BasicModalRefProps,
  GalerieVogzterModalLoadMoreProps
> = (_, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const redirectionLink = `https://app.vogz.io/signup`

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }))

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="relative bg-white rounded-2xl shadow-2xl p-6 max-w-lg w-full"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
          >
            {/* Close Button */}
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
            >
              <i className="fas fa-times-circle text-2xl" />
            </button>

            {/* Icon at the Top */}
            <div className="text-center mb-4">
            <i className="fas fa-times-circle text-3xl" />
            </div>

            <h2 className="text-xl font-bold mb-2 text-center">
              <span role="img" aria-label="">
                📢
              </span>{' '}
              Débloquez l’accès aux meilleurs créateurs !
            </h2>
            <p className="text-gray-600 text-left">
              Ne passez pas à côté des talents qui feront briller votre marque.
              Avec Vogz, trouvez et collaborez avec des créateurs en quelques
              clics.{' '}
              <span role="img" aria-label="">
                ✅
              </span>{' '}
              Accédez à des milliers de créateurs qualifiés. 🚀 Lancez des
              castings UGC et influences ! 🎥 Découvrez des milliers
              d’inspirations 👉 Inscrivez-vous maintenant
            </p>

            <div className="flex justify-center mt-5">
              <BtnCustom
                className=" right-0 w-fit text-lg"
                mainColor="btn-white"
                title="Créer mon compte"
                onClick={() => {
                  window.open(redirectionLink, '_blank')
                }}
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

GalerieVogzterModalLoadMore.displayName = 'GalerieVogzterMediaModalLoad'

export default forwardRef(GalerieVogzterModalLoadMore)
