import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'grommet'



const Button:React.FC<ButtonProps> = ({children,onclick,className,variant='primary',size="md",isLoading,disabled,...props}) => {
  const sizeButton = {
    sm: 'h-[37px]',
    md: 'h-[44px]',
  }
  const variantClasses = {
    primary: 'bg-main-blue-dark text-white',
    white: 'bg-white text-[#7885ED]',
    outLine: 'bg-transparent border border-[#f1f1f1]',
    outLineBlue: 'bg-transparent border border-[#7885ed] text-[#7885ed]',
    secondary: 'bg-main-blue-light text-main-blue-dark',
    tertiary: 'bg-transparent text-main-blue-dark',
    gradient: 'bg-gradient-to-r from-[#fae742] from-35% via-[#f56856] via-68% to-[#e43f7c] to-[#4f30d2] via text-white',
    dark : 'bg-[#1f1f1f] text-white'
  }

  return (
    <button onClick={onclick} type="button" disabled={disabled || isLoading} {...props} className={`w-full rounded-[100px] ${sizeButton[size]} text-sm font-semibold flex justify-center items-center gap-1 hover:opacity-80 disabled:opacity-60 disabled:cursor-not-allowed ${variantClasses[variant]} ${className ?? '' }`}>{isLoading ? <Spinner /> : children}</button>
  )
}


interface ButtonProps {
  onclick?: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  className?: string
  children: React.ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outLine' | 'gradient' | 'dark' | 'outLineBlue' | 'white'
  size?: 'sm' | 'md'
  isLoading?:boolean
}

Button.defaultProps = {
  disabled: false,
  className: undefined,
  children: '',
  variant: 'primary',
  size: 'md',
  isLoading: false
}

Button.propTypes = {
  onclick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'outLine', 'gradient', 'dark', 'outLineBlue']),
  isLoading: PropTypes.bool
}


export default Button;